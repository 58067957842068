@charset "UTF-8";
/** フォント関連 **/
/* 日本語のみ */
/* 英語のみ */
/* 使用ウェイト
Roboto-Bold,
Roboto Condensed,
Noto -regular,bold,
*/
/** 色設定 **/
/** 透明度の設定 **/
/** トランジションに関しての設定 **/
/* 秒、イージングの設定 */
/* ※ イージング早見表 https://easings.net/ja */
/* ================================================================================ */
/*                                                                                  */
/*      スタイル                                                                    */
/*                                                                                  */
/* ================================================================================ */
/* reset
----------------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
li,
ol,
li,
h1,
h2,
h3,
h4,
h5,
form,
p,
table,
th,
td,
address {
  margin: 0;
  padding: 0;
}

a img,
img {
  border: 0;
}

ul,
li,
ol,
li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

a {
  text-decoration: underline;
  color: #2382C7;
}

a:hover {
  text-decoration: none;
}

input[type="text"],
input[type="password"],
select,
textarea {
  padding: 10px 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
}

select::-ms-expand {
  display: none;
}

select,
select[name=birth_date_year],
select[name=birth_date_month],
select[name=birth_date_day] {
  display: inline-block;
  height: 40px;
  appearance: none;
  background-color: #ffffff;
  background-image: url(../img/icon/arrow/bottom.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  color: #828282;
  padding: 0 24px 0 10px;
  font-size: 1.6rem;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
}

#contents select option {
  color: #3D270F;
}

input[name=add_ha] {
  display: inline-block;
  width: 240px;
  height: 40px;
  margin-left: 16px;
  padding: 6px 16px 6px 24px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/map02.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

input[name=add_ha]:hover {
  background-color: #FFF0DB;
}

/* clearfix
----------------------------------------------- */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* ベース
----------------------------------------------- */
html {
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  line-height: 1.48;
  color: #3D270F;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "Roboto", sans-serif;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "Roboto", sans-serif;
  }
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSans/NotoSans_Regular.woff2") format("woff2"), url("../fonts/NotoSans/NotoSans_regular.woff") format("woff"), url("../fonts/NotoSans/NotoSans_regular.ttf") format("truetype"), url("../fonts/NotoSans/NotoSans_regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: medium;
  font-weight: 500;
  src: url("../fonts/NotoSans/NotoSans_Medium.woff2") format("woff2"), url("../fonts/NotoSans/NotoSans_medium.woff") format("woff"), url("../fonts/NotoSans/NotoSans_medium.ttf") format("truetype"), url("../fonts/NotoSans/NotoSans_medium.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: bold;
  font-weight: 700;
  src: url("../fonts/NotoSans/NotoSans_Bold.woff2") format("woff2"), url("../fonts/NotoSans/NotoSans_bold.woff") format("woff"), url("../fonts/NotoSans/NotoSans_bold.ttf") format("truetype"), url("../fonts/NotoSans/NotoSans_bold.eot") format("embedded-opentype");
}

/* 汎用
----------------------------------------------- */
.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.cancel {
  text-decoration: line-through;
}

.att {
  color: #FB5555;
  font-size: 1.2rem;
}

textarea {
  font-family: sans-serif;
  padding: 8px;
  line-height: 1.4;
}

label {
  position: relative;
  display: inline-block;
  padding-left: 24px;
}

input[type=radio] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1px;
  width: 16px;
  height: 16px;
  margin: 0;
  background-color: #F2F2F2;
  border-radius: 50%;
  transform: translateY(-50%);
}

input[type=radio]:checked {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

input[type=radio]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #3D270F;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

input[type=checkbox] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #F2F2F2;
  border-radius: 4px;
}

input[type=checkbox]:checked {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

input[type=checkbox]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  width: 7px;
  height: 14px;
  border-radius: 4px;
  transform: rotate(40deg);
  border-bottom: 3px solid #3D270F;
  border-right: 3px solid #3D270F;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.d-inline_block {
  display: inline-block;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 4px;
}

.pt-4 {
  padding-top: 4px;
}

@media screen and (min-width: 768px) {
  .pc-mt-4 {
    margin-top: 4px;
  }
  .pc-pt-4 {
    padding-top: 4px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-4 {
    margin-top: 4px;
  }
  .sp-pt-4 {
    padding-top: 4px;
  }
}

.mr-4 {
  margin-right: 4px;
}

.pr-4 {
  padding-right: 4px;
}

@media screen and (min-width: 768px) {
  .pc-mr-4 {
    margin-right: 4px;
  }
  .pc-pr-4 {
    padding-right: 4px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-4 {
    margin-right: 4px;
  }
  .sp-pr-4 {
    padding-right: 4px;
  }
}

.mb-4 {
  margin-bottom: 4px;
}

.pb-4 {
  padding-bottom: 4px;
}

@media screen and (min-width: 768px) {
  .pc-mb-4 {
    margin-bottom: 4px;
  }
  .pc-pb-4 {
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb4 {
    margin-bottom: 4px;
  }
  .sp-pb4 {
    padding-bottom: 4px;
  }
}

.ml-4 {
  margin-left: 4px;
}

.pl-4 {
  padding-left: 4px;
}

@media screen and (min-width: 768px) {
  .pc-ml-4 {
    margin-left: 4px;
  }
  .pc-pl-4 {
    padding-left: 4px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml4 {
    margin-left: 4px;
  }
  .sp-pl4 {
    padding-left: 4px;
  }
}

.mt-8 {
  margin-top: 8px;
}

.pt-8 {
  padding-top: 8px;
}

@media screen and (min-width: 768px) {
  .pc-mt-8 {
    margin-top: 8px;
  }
  .pc-pt-8 {
    padding-top: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-8 {
    margin-top: 8px;
  }
  .sp-pt-8 {
    padding-top: 8px;
  }
}

.mr-8 {
  margin-right: 8px;
}

.pr-8 {
  padding-right: 8px;
}

@media screen and (min-width: 768px) {
  .pc-mr-8 {
    margin-right: 8px;
  }
  .pc-pr-8 {
    padding-right: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-8 {
    margin-right: 8px;
  }
  .sp-pr-8 {
    padding-right: 8px;
  }
}

.mb-8 {
  margin-bottom: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .pc-mb-8 {
    margin-bottom: 8px;
  }
  .pc-pb-8 {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb8 {
    margin-bottom: 8px;
  }
  .sp-pb8 {
    padding-bottom: 8px;
  }
}

.ml-8 {
  margin-left: 8px;
}

.pl-8 {
  padding-left: 8px;
}

@media screen and (min-width: 768px) {
  .pc-ml-8 {
    margin-left: 8px;
  }
  .pc-pl-8 {
    padding-left: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml8 {
    margin-left: 8px;
  }
  .sp-pl8 {
    padding-left: 8px;
  }
}

.mt-12 {
  margin-top: 12px;
}

.pt-12 {
  padding-top: 12px;
}

@media screen and (min-width: 768px) {
  .pc-mt-12 {
    margin-top: 12px;
  }
  .pc-pt-12 {
    padding-top: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-12 {
    margin-top: 12px;
  }
  .sp-pt-12 {
    padding-top: 12px;
  }
}

.mr-12 {
  margin-right: 12px;
}

.pr-12 {
  padding-right: 12px;
}

@media screen and (min-width: 768px) {
  .pc-mr-12 {
    margin-right: 12px;
  }
  .pc-pr-12 {
    padding-right: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-12 {
    margin-right: 12px;
  }
  .sp-pr-12 {
    padding-right: 12px;
  }
}

.mb-12 {
  margin-bottom: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

@media screen and (min-width: 768px) {
  .pc-mb-12 {
    margin-bottom: 12px;
  }
  .pc-pb-12 {
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb12 {
    margin-bottom: 12px;
  }
  .sp-pb12 {
    padding-bottom: 12px;
  }
}

.ml-12 {
  margin-left: 12px;
}

.pl-12 {
  padding-left: 12px;
}

@media screen and (min-width: 768px) {
  .pc-ml-12 {
    margin-left: 12px;
  }
  .pc-pl-12 {
    padding-left: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml12 {
    margin-left: 12px;
  }
  .sp-pl12 {
    padding-left: 12px;
  }
}

.mt-16 {
  margin-top: 16px;
}

.pt-16 {
  padding-top: 16px;
}

@media screen and (min-width: 768px) {
  .pc-mt-16 {
    margin-top: 16px;
  }
  .pc-pt-16 {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-16 {
    margin-top: 16px;
  }
  .sp-pt-16 {
    padding-top: 16px;
  }
}

.mr-16 {
  margin-right: 16px;
}

.pr-16 {
  padding-right: 16px;
}

@media screen and (min-width: 768px) {
  .pc-mr-16 {
    margin-right: 16px;
  }
  .pc-pr-16 {
    padding-right: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-16 {
    margin-right: 16px;
  }
  .sp-pr-16 {
    padding-right: 16px;
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .pc-mb-16 {
    margin-bottom: 16px;
  }
  .pc-pb-16 {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb16 {
    margin-bottom: 16px;
  }
  .sp-pb16 {
    padding-bottom: 16px;
  }
}

.ml-16 {
  margin-left: 16px;
}

.pl-16 {
  padding-left: 16px;
}

@media screen and (min-width: 768px) {
  .pc-ml-16 {
    margin-left: 16px;
  }
  .pc-pl-16 {
    padding-left: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml16 {
    margin-left: 16px;
  }
  .sp-pl16 {
    padding-left: 16px;
  }
}

.mt-20 {
  margin-top: 20px;
}

.pt-20 {
  padding-top: 20px;
}

@media screen and (min-width: 768px) {
  .pc-mt-20 {
    margin-top: 20px;
  }
  .pc-pt-20 {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-20 {
    margin-top: 20px;
  }
  .sp-pt-20 {
    padding-top: 20px;
  }
}

.mr-20 {
  margin-right: 20px;
}

.pr-20 {
  padding-right: 20px;
}

@media screen and (min-width: 768px) {
  .pc-mr-20 {
    margin-right: 20px;
  }
  .pc-pr-20 {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-20 {
    margin-right: 20px;
  }
  .sp-pr-20 {
    padding-right: 20px;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .pc-mb-20 {
    margin-bottom: 20px;
  }
  .pc-pb-20 {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb20 {
    margin-bottom: 20px;
  }
  .sp-pb20 {
    padding-bottom: 20px;
  }
}

.ml-20 {
  margin-left: 20px;
}

.pl-20 {
  padding-left: 20px;
}

@media screen and (min-width: 768px) {
  .pc-ml-20 {
    margin-left: 20px;
  }
  .pc-pl-20 {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml20 {
    margin-left: 20px;
  }
  .sp-pl20 {
    padding-left: 20px;
  }
}

.mt-24 {
  margin-top: 24px;
}

.pt-24 {
  padding-top: 24px;
}

@media screen and (min-width: 768px) {
  .pc-mt-24 {
    margin-top: 24px;
  }
  .pc-pt-24 {
    padding-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-24 {
    margin-top: 24px;
  }
  .sp-pt-24 {
    padding-top: 24px;
  }
}

.mr-24 {
  margin-right: 24px;
}

.pr-24 {
  padding-right: 24px;
}

@media screen and (min-width: 768px) {
  .pc-mr-24 {
    margin-right: 24px;
  }
  .pc-pr-24 {
    padding-right: 24px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-24 {
    margin-right: 24px;
  }
  .sp-pr-24 {
    padding-right: 24px;
  }
}

.mb-24 {
  margin-bottom: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .pc-mb-24 {
    margin-bottom: 24px;
  }
  .pc-pb-24 {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb24 {
    margin-bottom: 24px;
  }
  .sp-pb24 {
    padding-bottom: 24px;
  }
}

.ml-24 {
  margin-left: 24px;
}

.pl-24 {
  padding-left: 24px;
}

@media screen and (min-width: 768px) {
  .pc-ml-24 {
    margin-left: 24px;
  }
  .pc-pl-24 {
    padding-left: 24px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml24 {
    margin-left: 24px;
  }
  .sp-pl24 {
    padding-left: 24px;
  }
}

.mt-28 {
  margin-top: 28px;
}

.pt-28 {
  padding-top: 28px;
}

@media screen and (min-width: 768px) {
  .pc-mt-28 {
    margin-top: 28px;
  }
  .pc-pt-28 {
    padding-top: 28px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-28 {
    margin-top: 28px;
  }
  .sp-pt-28 {
    padding-top: 28px;
  }
}

.mr-28 {
  margin-right: 28px;
}

.pr-28 {
  padding-right: 28px;
}

@media screen and (min-width: 768px) {
  .pc-mr-28 {
    margin-right: 28px;
  }
  .pc-pr-28 {
    padding-right: 28px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-28 {
    margin-right: 28px;
  }
  .sp-pr-28 {
    padding-right: 28px;
  }
}

.mb-28 {
  margin-bottom: 28px;
}

.pb-28 {
  padding-bottom: 28px;
}

@media screen and (min-width: 768px) {
  .pc-mb-28 {
    margin-bottom: 28px;
  }
  .pc-pb-28 {
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb28 {
    margin-bottom: 28px;
  }
  .sp-pb28 {
    padding-bottom: 28px;
  }
}

.ml-28 {
  margin-left: 28px;
}

.pl-28 {
  padding-left: 28px;
}

@media screen and (min-width: 768px) {
  .pc-ml-28 {
    margin-left: 28px;
  }
  .pc-pl-28 {
    padding-left: 28px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml28 {
    margin-left: 28px;
  }
  .sp-pl28 {
    padding-left: 28px;
  }
}

.mt-32 {
  margin-top: 32px;
}

.pt-32 {
  padding-top: 32px;
}

@media screen and (min-width: 768px) {
  .pc-mt-32 {
    margin-top: 32px;
  }
  .pc-pt-32 {
    padding-top: 32px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-32 {
    margin-top: 32px;
  }
  .sp-pt-32 {
    padding-top: 32px;
  }
}

.mr-32 {
  margin-right: 32px;
}

.pr-32 {
  padding-right: 32px;
}

@media screen and (min-width: 768px) {
  .pc-mr-32 {
    margin-right: 32px;
  }
  .pc-pr-32 {
    padding-right: 32px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-32 {
    margin-right: 32px;
  }
  .sp-pr-32 {
    padding-right: 32px;
  }
}

.mb-32 {
  margin-bottom: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .pc-mb-32 {
    margin-bottom: 32px;
  }
  .pc-pb-32 {
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb32 {
    margin-bottom: 32px;
  }
  .sp-pb32 {
    padding-bottom: 32px;
  }
}

.ml-32 {
  margin-left: 32px;
}

.pl-32 {
  padding-left: 32px;
}

@media screen and (min-width: 768px) {
  .pc-ml-32 {
    margin-left: 32px;
  }
  .pc-pl-32 {
    padding-left: 32px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml32 {
    margin-left: 32px;
  }
  .sp-pl32 {
    padding-left: 32px;
  }
}

.mt-36 {
  margin-top: 36px;
}

.pt-36 {
  padding-top: 36px;
}

@media screen and (min-width: 768px) {
  .pc-mt-36 {
    margin-top: 36px;
  }
  .pc-pt-36 {
    padding-top: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-36 {
    margin-top: 36px;
  }
  .sp-pt-36 {
    padding-top: 36px;
  }
}

.mr-36 {
  margin-right: 36px;
}

.pr-36 {
  padding-right: 36px;
}

@media screen and (min-width: 768px) {
  .pc-mr-36 {
    margin-right: 36px;
  }
  .pc-pr-36 {
    padding-right: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-36 {
    margin-right: 36px;
  }
  .sp-pr-36 {
    padding-right: 36px;
  }
}

.mb-36 {
  margin-bottom: 36px;
}

.pb-36 {
  padding-bottom: 36px;
}

@media screen and (min-width: 768px) {
  .pc-mb-36 {
    margin-bottom: 36px;
  }
  .pc-pb-36 {
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb36 {
    margin-bottom: 36px;
  }
  .sp-pb36 {
    padding-bottom: 36px;
  }
}

.ml-36 {
  margin-left: 36px;
}

.pl-36 {
  padding-left: 36px;
}

@media screen and (min-width: 768px) {
  .pc-ml-36 {
    margin-left: 36px;
  }
  .pc-pl-36 {
    padding-left: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml36 {
    margin-left: 36px;
  }
  .sp-pl36 {
    padding-left: 36px;
  }
}

.mt-40 {
  margin-top: 40px;
}

.pt-40 {
  padding-top: 40px;
}

@media screen and (min-width: 768px) {
  .pc-mt-40 {
    margin-top: 40px;
  }
  .pc-pt-40 {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-40 {
    margin-top: 40px;
  }
  .sp-pt-40 {
    padding-top: 40px;
  }
}

.mr-40 {
  margin-right: 40px;
}

.pr-40 {
  padding-right: 40px;
}

@media screen and (min-width: 768px) {
  .pc-mr-40 {
    margin-right: 40px;
  }
  .pc-pr-40 {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-40 {
    margin-right: 40px;
  }
  .sp-pr-40 {
    padding-right: 40px;
  }
}

.mb-40 {
  margin-bottom: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .pc-mb-40 {
    margin-bottom: 40px;
  }
  .pc-pb-40 {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb40 {
    margin-bottom: 40px;
  }
  .sp-pb40 {
    padding-bottom: 40px;
  }
}

.ml-40 {
  margin-left: 40px;
}

.pl-40 {
  padding-left: 40px;
}

@media screen and (min-width: 768px) {
  .pc-ml-40 {
    margin-left: 40px;
  }
  .pc-pl-40 {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml40 {
    margin-left: 40px;
  }
  .sp-pl40 {
    padding-left: 40px;
  }
}

.mt-44 {
  margin-top: 44px;
}

.pt-44 {
  padding-top: 44px;
}

@media screen and (min-width: 768px) {
  .pc-mt-44 {
    margin-top: 44px;
  }
  .pc-pt-44 {
    padding-top: 44px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-44 {
    margin-top: 44px;
  }
  .sp-pt-44 {
    padding-top: 44px;
  }
}

.mr-44 {
  margin-right: 44px;
}

.pr-44 {
  padding-right: 44px;
}

@media screen and (min-width: 768px) {
  .pc-mr-44 {
    margin-right: 44px;
  }
  .pc-pr-44 {
    padding-right: 44px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-44 {
    margin-right: 44px;
  }
  .sp-pr-44 {
    padding-right: 44px;
  }
}

.mb-44 {
  margin-bottom: 44px;
}

.pb-44 {
  padding-bottom: 44px;
}

@media screen and (min-width: 768px) {
  .pc-mb-44 {
    margin-bottom: 44px;
  }
  .pc-pb-44 {
    padding-bottom: 44px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb44 {
    margin-bottom: 44px;
  }
  .sp-pb44 {
    padding-bottom: 44px;
  }
}

.ml-44 {
  margin-left: 44px;
}

.pl-44 {
  padding-left: 44px;
}

@media screen and (min-width: 768px) {
  .pc-ml-44 {
    margin-left: 44px;
  }
  .pc-pl-44 {
    padding-left: 44px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml44 {
    margin-left: 44px;
  }
  .sp-pl44 {
    padding-left: 44px;
  }
}

.mt-48 {
  margin-top: 48px;
}

.pt-48 {
  padding-top: 48px;
}

@media screen and (min-width: 768px) {
  .pc-mt-48 {
    margin-top: 48px;
  }
  .pc-pt-48 {
    padding-top: 48px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-48 {
    margin-top: 48px;
  }
  .sp-pt-48 {
    padding-top: 48px;
  }
}

.mr-48 {
  margin-right: 48px;
}

.pr-48 {
  padding-right: 48px;
}

@media screen and (min-width: 768px) {
  .pc-mr-48 {
    margin-right: 48px;
  }
  .pc-pr-48 {
    padding-right: 48px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-48 {
    margin-right: 48px;
  }
  .sp-pr-48 {
    padding-right: 48px;
  }
}

.mb-48 {
  margin-bottom: 48px;
}

.pb-48 {
  padding-bottom: 48px;
}

@media screen and (min-width: 768px) {
  .pc-mb-48 {
    margin-bottom: 48px;
  }
  .pc-pb-48 {
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb48 {
    margin-bottom: 48px;
  }
  .sp-pb48 {
    padding-bottom: 48px;
  }
}

.ml-48 {
  margin-left: 48px;
}

.pl-48 {
  padding-left: 48px;
}

@media screen and (min-width: 768px) {
  .pc-ml-48 {
    margin-left: 48px;
  }
  .pc-pl-48 {
    padding-left: 48px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml48 {
    margin-left: 48px;
  }
  .sp-pl48 {
    padding-left: 48px;
  }
}

.mt-52 {
  margin-top: 52px;
}

.pt-52 {
  padding-top: 52px;
}

@media screen and (min-width: 768px) {
  .pc-mt-52 {
    margin-top: 52px;
  }
  .pc-pt-52 {
    padding-top: 52px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-52 {
    margin-top: 52px;
  }
  .sp-pt-52 {
    padding-top: 52px;
  }
}

.mr-52 {
  margin-right: 52px;
}

.pr-52 {
  padding-right: 52px;
}

@media screen and (min-width: 768px) {
  .pc-mr-52 {
    margin-right: 52px;
  }
  .pc-pr-52 {
    padding-right: 52px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-52 {
    margin-right: 52px;
  }
  .sp-pr-52 {
    padding-right: 52px;
  }
}

.mb-52 {
  margin-bottom: 52px;
}

.pb-52 {
  padding-bottom: 52px;
}

@media screen and (min-width: 768px) {
  .pc-mb-52 {
    margin-bottom: 52px;
  }
  .pc-pb-52 {
    padding-bottom: 52px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb52 {
    margin-bottom: 52px;
  }
  .sp-pb52 {
    padding-bottom: 52px;
  }
}

.ml-52 {
  margin-left: 52px;
}

.pl-52 {
  padding-left: 52px;
}

@media screen and (min-width: 768px) {
  .pc-ml-52 {
    margin-left: 52px;
  }
  .pc-pl-52 {
    padding-left: 52px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml52 {
    margin-left: 52px;
  }
  .sp-pl52 {
    padding-left: 52px;
  }
}

.mt-56 {
  margin-top: 56px;
}

.pt-56 {
  padding-top: 56px;
}

@media screen and (min-width: 768px) {
  .pc-mt-56 {
    margin-top: 56px;
  }
  .pc-pt-56 {
    padding-top: 56px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-56 {
    margin-top: 56px;
  }
  .sp-pt-56 {
    padding-top: 56px;
  }
}

.mr-56 {
  margin-right: 56px;
}

.pr-56 {
  padding-right: 56px;
}

@media screen and (min-width: 768px) {
  .pc-mr-56 {
    margin-right: 56px;
  }
  .pc-pr-56 {
    padding-right: 56px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-56 {
    margin-right: 56px;
  }
  .sp-pr-56 {
    padding-right: 56px;
  }
}

.mb-56 {
  margin-bottom: 56px;
}

.pb-56 {
  padding-bottom: 56px;
}

@media screen and (min-width: 768px) {
  .pc-mb-56 {
    margin-bottom: 56px;
  }
  .pc-pb-56 {
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb56 {
    margin-bottom: 56px;
  }
  .sp-pb56 {
    padding-bottom: 56px;
  }
}

.ml-56 {
  margin-left: 56px;
}

.pl-56 {
  padding-left: 56px;
}

@media screen and (min-width: 768px) {
  .pc-ml-56 {
    margin-left: 56px;
  }
  .pc-pl-56 {
    padding-left: 56px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml56 {
    margin-left: 56px;
  }
  .sp-pl56 {
    padding-left: 56px;
  }
}

.mt-60 {
  margin-top: 60px;
}

.pt-60 {
  padding-top: 60px;
}

@media screen and (min-width: 768px) {
  .pc-mt-60 {
    margin-top: 60px;
  }
  .pc-pt-60 {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-60 {
    margin-top: 60px;
  }
  .sp-pt-60 {
    padding-top: 60px;
  }
}

.mr-60 {
  margin-right: 60px;
}

.pr-60 {
  padding-right: 60px;
}

@media screen and (min-width: 768px) {
  .pc-mr-60 {
    margin-right: 60px;
  }
  .pc-pr-60 {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-60 {
    margin-right: 60px;
  }
  .sp-pr-60 {
    padding-right: 60px;
  }
}

.mb-60 {
  margin-bottom: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

@media screen and (min-width: 768px) {
  .pc-mb-60 {
    margin-bottom: 60px;
  }
  .pc-pb-60 {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb60 {
    margin-bottom: 60px;
  }
  .sp-pb60 {
    padding-bottom: 60px;
  }
}

.ml-60 {
  margin-left: 60px;
}

.pl-60 {
  padding-left: 60px;
}

@media screen and (min-width: 768px) {
  .pc-ml-60 {
    margin-left: 60px;
  }
  .pc-pl-60 {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml60 {
    margin-left: 60px;
  }
  .sp-pl60 {
    padding-left: 60px;
  }
}

.mt-64 {
  margin-top: 64px;
}

.pt-64 {
  padding-top: 64px;
}

@media screen and (min-width: 768px) {
  .pc-mt-64 {
    margin-top: 64px;
  }
  .pc-pt-64 {
    padding-top: 64px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-64 {
    margin-top: 64px;
  }
  .sp-pt-64 {
    padding-top: 64px;
  }
}

.mr-64 {
  margin-right: 64px;
}

.pr-64 {
  padding-right: 64px;
}

@media screen and (min-width: 768px) {
  .pc-mr-64 {
    margin-right: 64px;
  }
  .pc-pr-64 {
    padding-right: 64px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-64 {
    margin-right: 64px;
  }
  .sp-pr-64 {
    padding-right: 64px;
  }
}

.mb-64 {
  margin-bottom: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

@media screen and (min-width: 768px) {
  .pc-mb-64 {
    margin-bottom: 64px;
  }
  .pc-pb-64 {
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb64 {
    margin-bottom: 64px;
  }
  .sp-pb64 {
    padding-bottom: 64px;
  }
}

.ml-64 {
  margin-left: 64px;
}

.pl-64 {
  padding-left: 64px;
}

@media screen and (min-width: 768px) {
  .pc-ml-64 {
    margin-left: 64px;
  }
  .pc-pl-64 {
    padding-left: 64px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml64 {
    margin-left: 64px;
  }
  .sp-pl64 {
    padding-left: 64px;
  }
}

.mt-68 {
  margin-top: 68px;
}

.pt-68 {
  padding-top: 68px;
}

@media screen and (min-width: 768px) {
  .pc-mt-68 {
    margin-top: 68px;
  }
  .pc-pt-68 {
    padding-top: 68px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-68 {
    margin-top: 68px;
  }
  .sp-pt-68 {
    padding-top: 68px;
  }
}

.mr-68 {
  margin-right: 68px;
}

.pr-68 {
  padding-right: 68px;
}

@media screen and (min-width: 768px) {
  .pc-mr-68 {
    margin-right: 68px;
  }
  .pc-pr-68 {
    padding-right: 68px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-68 {
    margin-right: 68px;
  }
  .sp-pr-68 {
    padding-right: 68px;
  }
}

.mb-68 {
  margin-bottom: 68px;
}

.pb-68 {
  padding-bottom: 68px;
}

@media screen and (min-width: 768px) {
  .pc-mb-68 {
    margin-bottom: 68px;
  }
  .pc-pb-68 {
    padding-bottom: 68px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb68 {
    margin-bottom: 68px;
  }
  .sp-pb68 {
    padding-bottom: 68px;
  }
}

.ml-68 {
  margin-left: 68px;
}

.pl-68 {
  padding-left: 68px;
}

@media screen and (min-width: 768px) {
  .pc-ml-68 {
    margin-left: 68px;
  }
  .pc-pl-68 {
    padding-left: 68px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml68 {
    margin-left: 68px;
  }
  .sp-pl68 {
    padding-left: 68px;
  }
}

.mt-72 {
  margin-top: 72px;
}

.pt-72 {
  padding-top: 72px;
}

@media screen and (min-width: 768px) {
  .pc-mt-72 {
    margin-top: 72px;
  }
  .pc-pt-72 {
    padding-top: 72px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-72 {
    margin-top: 72px;
  }
  .sp-pt-72 {
    padding-top: 72px;
  }
}

.mr-72 {
  margin-right: 72px;
}

.pr-72 {
  padding-right: 72px;
}

@media screen and (min-width: 768px) {
  .pc-mr-72 {
    margin-right: 72px;
  }
  .pc-pr-72 {
    padding-right: 72px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-72 {
    margin-right: 72px;
  }
  .sp-pr-72 {
    padding-right: 72px;
  }
}

.mb-72 {
  margin-bottom: 72px;
}

.pb-72 {
  padding-bottom: 72px;
}

@media screen and (min-width: 768px) {
  .pc-mb-72 {
    margin-bottom: 72px;
  }
  .pc-pb-72 {
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb72 {
    margin-bottom: 72px;
  }
  .sp-pb72 {
    padding-bottom: 72px;
  }
}

.ml-72 {
  margin-left: 72px;
}

.pl-72 {
  padding-left: 72px;
}

@media screen and (min-width: 768px) {
  .pc-ml-72 {
    margin-left: 72px;
  }
  .pc-pl-72 {
    padding-left: 72px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml72 {
    margin-left: 72px;
  }
  .sp-pl72 {
    padding-left: 72px;
  }
}

.mt-76 {
  margin-top: 76px;
}

.pt-76 {
  padding-top: 76px;
}

@media screen and (min-width: 768px) {
  .pc-mt-76 {
    margin-top: 76px;
  }
  .pc-pt-76 {
    padding-top: 76px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-76 {
    margin-top: 76px;
  }
  .sp-pt-76 {
    padding-top: 76px;
  }
}

.mr-76 {
  margin-right: 76px;
}

.pr-76 {
  padding-right: 76px;
}

@media screen and (min-width: 768px) {
  .pc-mr-76 {
    margin-right: 76px;
  }
  .pc-pr-76 {
    padding-right: 76px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-76 {
    margin-right: 76px;
  }
  .sp-pr-76 {
    padding-right: 76px;
  }
}

.mb-76 {
  margin-bottom: 76px;
}

.pb-76 {
  padding-bottom: 76px;
}

@media screen and (min-width: 768px) {
  .pc-mb-76 {
    margin-bottom: 76px;
  }
  .pc-pb-76 {
    padding-bottom: 76px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb76 {
    margin-bottom: 76px;
  }
  .sp-pb76 {
    padding-bottom: 76px;
  }
}

.ml-76 {
  margin-left: 76px;
}

.pl-76 {
  padding-left: 76px;
}

@media screen and (min-width: 768px) {
  .pc-ml-76 {
    margin-left: 76px;
  }
  .pc-pl-76 {
    padding-left: 76px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml76 {
    margin-left: 76px;
  }
  .sp-pl76 {
    padding-left: 76px;
  }
}

.mt-80 {
  margin-top: 80px;
}

.pt-80 {
  padding-top: 80px;
}

@media screen and (min-width: 768px) {
  .pc-mt-80 {
    margin-top: 80px;
  }
  .pc-pt-80 {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-80 {
    margin-top: 80px;
  }
  .sp-pt-80 {
    padding-top: 80px;
  }
}

.mr-80 {
  margin-right: 80px;
}

.pr-80 {
  padding-right: 80px;
}

@media screen and (min-width: 768px) {
  .pc-mr-80 {
    margin-right: 80px;
  }
  .pc-pr-80 {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-80 {
    margin-right: 80px;
  }
  .sp-pr-80 {
    padding-right: 80px;
  }
}

.mb-80 {
  margin-bottom: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

@media screen and (min-width: 768px) {
  .pc-mb-80 {
    margin-bottom: 80px;
  }
  .pc-pb-80 {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb80 {
    margin-bottom: 80px;
  }
  .sp-pb80 {
    padding-bottom: 80px;
  }
}

.ml-80 {
  margin-left: 80px;
}

.pl-80 {
  padding-left: 80px;
}

@media screen and (min-width: 768px) {
  .pc-ml-80 {
    margin-left: 80px;
  }
  .pc-pl-80 {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml80 {
    margin-left: 80px;
  }
  .sp-pl80 {
    padding-left: 80px;
  }
}

.mt-84 {
  margin-top: 84px;
}

.pt-84 {
  padding-top: 84px;
}

@media screen and (min-width: 768px) {
  .pc-mt-84 {
    margin-top: 84px;
  }
  .pc-pt-84 {
    padding-top: 84px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-84 {
    margin-top: 84px;
  }
  .sp-pt-84 {
    padding-top: 84px;
  }
}

.mr-84 {
  margin-right: 84px;
}

.pr-84 {
  padding-right: 84px;
}

@media screen and (min-width: 768px) {
  .pc-mr-84 {
    margin-right: 84px;
  }
  .pc-pr-84 {
    padding-right: 84px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-84 {
    margin-right: 84px;
  }
  .sp-pr-84 {
    padding-right: 84px;
  }
}

.mb-84 {
  margin-bottom: 84px;
}

.pb-84 {
  padding-bottom: 84px;
}

@media screen and (min-width: 768px) {
  .pc-mb-84 {
    margin-bottom: 84px;
  }
  .pc-pb-84 {
    padding-bottom: 84px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb84 {
    margin-bottom: 84px;
  }
  .sp-pb84 {
    padding-bottom: 84px;
  }
}

.ml-84 {
  margin-left: 84px;
}

.pl-84 {
  padding-left: 84px;
}

@media screen and (min-width: 768px) {
  .pc-ml-84 {
    margin-left: 84px;
  }
  .pc-pl-84 {
    padding-left: 84px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml84 {
    margin-left: 84px;
  }
  .sp-pl84 {
    padding-left: 84px;
  }
}

.mt-88 {
  margin-top: 88px;
}

.pt-88 {
  padding-top: 88px;
}

@media screen and (min-width: 768px) {
  .pc-mt-88 {
    margin-top: 88px;
  }
  .pc-pt-88 {
    padding-top: 88px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-88 {
    margin-top: 88px;
  }
  .sp-pt-88 {
    padding-top: 88px;
  }
}

.mr-88 {
  margin-right: 88px;
}

.pr-88 {
  padding-right: 88px;
}

@media screen and (min-width: 768px) {
  .pc-mr-88 {
    margin-right: 88px;
  }
  .pc-pr-88 {
    padding-right: 88px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-88 {
    margin-right: 88px;
  }
  .sp-pr-88 {
    padding-right: 88px;
  }
}

.mb-88 {
  margin-bottom: 88px;
}

.pb-88 {
  padding-bottom: 88px;
}

@media screen and (min-width: 768px) {
  .pc-mb-88 {
    margin-bottom: 88px;
  }
  .pc-pb-88 {
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb88 {
    margin-bottom: 88px;
  }
  .sp-pb88 {
    padding-bottom: 88px;
  }
}

.ml-88 {
  margin-left: 88px;
}

.pl-88 {
  padding-left: 88px;
}

@media screen and (min-width: 768px) {
  .pc-ml-88 {
    margin-left: 88px;
  }
  .pc-pl-88 {
    padding-left: 88px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml88 {
    margin-left: 88px;
  }
  .sp-pl88 {
    padding-left: 88px;
  }
}

.mt-92 {
  margin-top: 92px;
}

.pt-92 {
  padding-top: 92px;
}

@media screen and (min-width: 768px) {
  .pc-mt-92 {
    margin-top: 92px;
  }
  .pc-pt-92 {
    padding-top: 92px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-92 {
    margin-top: 92px;
  }
  .sp-pt-92 {
    padding-top: 92px;
  }
}

.mr-92 {
  margin-right: 92px;
}

.pr-92 {
  padding-right: 92px;
}

@media screen and (min-width: 768px) {
  .pc-mr-92 {
    margin-right: 92px;
  }
  .pc-pr-92 {
    padding-right: 92px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-92 {
    margin-right: 92px;
  }
  .sp-pr-92 {
    padding-right: 92px;
  }
}

.mb-92 {
  margin-bottom: 92px;
}

.pb-92 {
  padding-bottom: 92px;
}

@media screen and (min-width: 768px) {
  .pc-mb-92 {
    margin-bottom: 92px;
  }
  .pc-pb-92 {
    padding-bottom: 92px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb92 {
    margin-bottom: 92px;
  }
  .sp-pb92 {
    padding-bottom: 92px;
  }
}

.ml-92 {
  margin-left: 92px;
}

.pl-92 {
  padding-left: 92px;
}

@media screen and (min-width: 768px) {
  .pc-ml-92 {
    margin-left: 92px;
  }
  .pc-pl-92 {
    padding-left: 92px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml92 {
    margin-left: 92px;
  }
  .sp-pl92 {
    padding-left: 92px;
  }
}

.mt-96 {
  margin-top: 96px;
}

.pt-96 {
  padding-top: 96px;
}

@media screen and (min-width: 768px) {
  .pc-mt-96 {
    margin-top: 96px;
  }
  .pc-pt-96 {
    padding-top: 96px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-96 {
    margin-top: 96px;
  }
  .sp-pt-96 {
    padding-top: 96px;
  }
}

.mr-96 {
  margin-right: 96px;
}

.pr-96 {
  padding-right: 96px;
}

@media screen and (min-width: 768px) {
  .pc-mr-96 {
    margin-right: 96px;
  }
  .pc-pr-96 {
    padding-right: 96px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-96 {
    margin-right: 96px;
  }
  .sp-pr-96 {
    padding-right: 96px;
  }
}

.mb-96 {
  margin-bottom: 96px;
}

.pb-96 {
  padding-bottom: 96px;
}

@media screen and (min-width: 768px) {
  .pc-mb-96 {
    margin-bottom: 96px;
  }
  .pc-pb-96 {
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb96 {
    margin-bottom: 96px;
  }
  .sp-pb96 {
    padding-bottom: 96px;
  }
}

.ml-96 {
  margin-left: 96px;
}

.pl-96 {
  padding-left: 96px;
}

@media screen and (min-width: 768px) {
  .pc-ml-96 {
    margin-left: 96px;
  }
  .pc-pl-96 {
    padding-left: 96px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml96 {
    margin-left: 96px;
  }
  .sp-pl96 {
    padding-left: 96px;
  }
}

.mt-100 {
  margin-top: 100px;
}

.pt-100 {
  padding-top: 100px;
}

@media screen and (min-width: 768px) {
  .pc-mt-100 {
    margin-top: 100px;
  }
  .pc-pt-100 {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-100 {
    margin-top: 100px;
  }
  .sp-pt-100 {
    padding-top: 100px;
  }
}

.mr-100 {
  margin-right: 100px;
}

.pr-100 {
  padding-right: 100px;
}

@media screen and (min-width: 768px) {
  .pc-mr-100 {
    margin-right: 100px;
  }
  .pc-pr-100 {
    padding-right: 100px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-100 {
    margin-right: 100px;
  }
  .sp-pr-100 {
    padding-right: 100px;
  }
}

.mb-100 {
  margin-bottom: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

@media screen and (min-width: 768px) {
  .pc-mb-100 {
    margin-bottom: 100px;
  }
  .pc-pb-100 {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb100 {
    margin-bottom: 100px;
  }
  .sp-pb100 {
    padding-bottom: 100px;
  }
}

.ml-100 {
  margin-left: 100px;
}

.pl-100 {
  padding-left: 100px;
}

@media screen and (min-width: 768px) {
  .pc-ml-100 {
    margin-left: 100px;
  }
  .pc-pl-100 {
    padding-left: 100px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml100 {
    margin-left: 100px;
  }
  .sp-pl100 {
    padding-left: 100px;
  }
}

/* ================================================================================ */
/*                                                                                  */
/*      レイアウト                                                                  */
/*                                                                                  */
/* ================================================================================ */
.head_inline {
  width: 1032px;
}

#menu ul {
  width: 1032px;
}

#contents {
  width: 100%;
  background: #FFFDF4;
  border-top: 1px solid #F2EFEB;
  margin: 0;
}

.contents_inline {
  width: 1032px;
  min-height: calc(100vh - 124px - 92px);
  margin: 0 auto;
  padding: 40px 0 84px;
}

/* 1カラム
----------------------------------------------- */
/* 2カラム
----------------------------------------------- */
.c2 .contents_m {
  float: left;
  width: 768px;
}

.c2 .contents_s {
  float: right;
  width: 240px;
  padding-top: 71px;
}

.tp-wrap .c2 .contents_s {
  padding-top: 0;
}

/* 3カラム
----------------------------------------------- */
.c3 {
  position: relative;
}

.c3 .contents_s2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 234px;
}

.c3 .contents_s {
  position: absolute;
  right: 0;
  top: 0;
  width: 234px;
}

.c3 .contents_m {
  margin: 0 250px;
}

/*----------------------------------------------------------------【汎用デザイン】*/
/* コンテンツ部：メイン
----------------------------------------------- */
.contents_m > .main > h2,
.contents_m > h2 {
  color: #3D270F;
  font-size: 3.2rem;
}

.contents_m > .main > h2:first-child,
.contents_m > h2:first-child {
  margin: 0;
}

.contents_m h2 span.id {
  float: right;
  font-weight: normal;
  font-size: 10px;
  padding-top: 3px;
}

.contents_m h3 {
  color: #3d270f;
  font-size: 2.2rem;
  font-weight: 700;
}

.contents_m h3 span.more {
  padding-left: 10px;
  font-size: 12px;
}

.contents_m p {
  line-height: 150%;
}

.contents_m .annotation {
  margin: 0 0 10px 10px;
  font-size: 12px;
}

.contents_m .contents_box {
  background: #fff;
  border: 1px solid #ddd;
  margin: 0 0 20px;
}

.contents_m .contents_box h2 {
  position: relative;
  border: none;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.3;
  border-bottom: 3px solid #eee;
}

.contents_m .contents_box h2::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 50%;
  height: 3px;
  background: #666;
}

.contents_m .contents_box > h3 {
  position: relative;
  border: none;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.3;
  border-bottom: 3px solid #eee;
}

.contents_m .contents_box > h3::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 50%;
  height: 3px;
  background: #666;
}

.contents_m .contents_box .body .message_result_none {
  margin-top: 16px;
  background: transparent;
  text-align: center;
  color: #999;
}

.contents_m .contents_box .body .message_result_none + .more {
  display: none;
}

.th_notice {
  background: #ee3344;
  color: #fff;
}

/* ボタンセット */
.contents_m .button a.button,
.contents_m .button button,
.contents_m .button input[type="button"],
.contents_m .button input[type="submit"] {
  position: relative;
  display: inline-block;
  width: 240px;
  margin: 0;
  padding: 12px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
}

.contents_m .button input[type="button"] {
  padding: 12px 0;
}

.contents_m .button input[type="submit"].btn_send {
  padding: 12px 0;
}

.contents_m .button input[type="submit"].btn_search {
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/search02.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 16px;
  background-size: 16px;
}

.contents_m .button a.button._w320,
.contents_m .button .btn-main._w320,
.contents_m .button .btn_search._w320 {
  width: 320px;
}

.button-svgIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.contents_m .button a.button:hover,
.contents_m .button button:hover,
.contents_m .button input[type="button"]:hover,
.contents_m .button input[type="submit"]:hover {
  opacity: .8;
}

/* ページ送り */
.contents_m .search_page {
  margin: 20px 0;
}

.contents_m .search_page ul li a {
  background: #fff;
  border-radius: 2px;
}

.contents_m .search_page ul li.focus {
  background: #555;
  border: 1px solid #555;
  border-radius: 2px;
}

/* 汎用メッセージ */
.contents_m .message {
  background: #fff;
  border: 1px solid rgba(54, 59, 64, 0.4);
  border-radius: 4px;
  padding: 24px;
  margin: 24px 0 0;
}

.contents_m ul.registComp-links {
  margin-top: 40px;
  text-align: center;
}

.contents_m ul.registComp-links li {
  margin: auto;
  padding-left: 0;
  background-image: none;
}

.contents_m ul.registComp-links li + li {
  margin-top: 16px;
}

.contents_m ul.registComp-links a:hover {
  opacity: .8;
}

.contents_m ul.registComp-links .mainBtn {
  position: relative;
  display: block;
  width: 240px;
  margin: 0 auto;
  padding: 10px 10px 10px 24px;
  background-color: #FF7E41;
  border-radius: 6px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1.6rem;
  text-align: center;
  text-decoration: none;
}

.contents_m ul.registComp-links .subBtn {
  position: relative;
  display: block;
  width: 240px;
  margin: 0 auto;
  padding: 10px 24px 10px 10px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  text-align: center;
  text-decoration: none;
}

.contents_m ul.registComp-links .mainBtn svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.contents_m ul.registComp-links .subBtn svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.contents_m .description {
  margin: 24px 0;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, 0.4);
  border-radius: 4px;
}

.contents_m .error_message {
  border: 1px solid #ee3344;
  color: #ee3344;
}

.contents_m .main > .error {
  background: #fff;
  border: 1px solid #ee3344;
  border-radius: 2px;
  padding: 20px;
  margin: 10px 0;
  font-weight: normal;
}

.contents_m .attention {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 20px;
  margin: 10px 0;
}

/* 汎用メニュー */
/* サイドカラム
----------------------------------------------- */
.contents_s .contents_box {
  margin: 0 0 24px;
  padding-bottom: 16px;
  background: #FFF0DB;
  border-radius: 4px;
}

.contents_s .contents_box h2 {
  position: relative;
  padding: 16px 0 0 0;
  border: none;
  color: #3D270F;
  font-size: 1.8rem;
  text-align: center;
}

.contents_s .contents_box h2 .more {
  float: right;
  font-size: 12px;
}

.contents_s .contents_box .body {
  padding: 15px;
}

.contents_s .contents_box .body dl dd {
  margin-bottom: 8px;
}

.contents_s .contents_box .body dl dd input[type="text"] {
  width: 200px;
}

.contents_s .contents_box .body p.message,
.contents_s .contents_box .body p.more {
  text-align: center;
}

.contents_s .contents_box .body p.message a {
  display: block;
  padding: 12px 34px 12px 8px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/right-circle.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 16px;
  background-size: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.4);
  color: #FB8080;
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
  text-align: left;
}

.contents_s .contents_box .body p.message a:hover {
  background-color: #FFF2F2;
}

.contents_s .contents_box .body > p.more {
  text-align: right;
}

.contents_s .contents_box ul {
  margin: 0 0 10px;
}

.contents_s .contents_box ul li {
  padding-left: 10px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
  line-height: 150%;
}

.contents_s .contents_box ul li + li {
  margin-top: 5px;
}

.contents_s .contents_box p.button {
  text-align: center;
}

/* サイドカラム2
----------------------------------------------- */
.contents_s2 .contents_box {
  margin-bottom: 15px;
}

.contents_s2 .contents_box h2 {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  background: url(../img/style/h2_bk.gif) repeat-x center;
}

.contents_s2 .contents_box .body {
  padding: 10px;
  border: 1px solid #ccc;
  border-top: none;
}

.contents_s2 .contents_box .body dl dt {
  margin-bottom: 3px;
}

.contents_s2 .contents_box .body dl dd {
  margin-bottom: 8px;
}

.contents_s2 .contents_box .body dl dd input[type="text"] {
  width: 200px;
}

.contents_s2 .contents_box .body p {
  text-align: center;
  margin-top: 10px;
}

.contents_s2 .contents_box ul li {
  padding-left: 10px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
  line-height: 150%;
}

.contents_s2 .contents_box ul li + li {
  margin-top: 5px;
}

/* ================================================================================ */
/*                                                                                  */
/*      モジュール＆ステート                                                        */
/*                                                                                  */
/* ================================================================================ */
#super_user {
  width: 980px;
  margin: 0 auto;
}

#super_user a {
  display: block;
  padding: 5px;
  margin: 0 auto;
  text-align: center;
  width: 100px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  background-color: #555;
}

#super_usera:hover {
  background-color: #333;
}

.breadcrumb {
  margin-bottom: 10px;
}

/* ヘッダー(共通)
----------------------------------------------- */
#head {
  width: 100%;
  background: #fff;
}

.head_inline {
  min-height: 70px;
  margin: 0 auto;
  position: relative;
}

#head #logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#head #logo > a {
  display: block;
}

#head #logo > a > img {
  display: block;
  max-height: 70px;
  width: auto;
}

#head .switch {
  /* サイトのモード切替 */
  position: absolute;
  right: 0;
  top: -10px;
  margin: 0;
  overflow: hidden;
}

#head .switch li {
  float: left;
}

#head .switch li a {
  display: block;
  font-size: 12px;
  border: none;
  background: #eee;
  color: #000;
  margin: 0;
  padding: 6px 48px;
  text-decoration: none;
}

#head .switch li.active a {
  background: #666;
  color: #fff;
}

#head .switch li.hello {
  margin: 10px 10px 0 0;
}

#head .switch li.hello a {
  border: none;
  display: inline;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  background: transparent;
}

#head.switchli.helloa:hover {
  text-decoration: none;
}

#head .head_menu {
  position: absolute;
  right: 0;
  bottom: 5px;
}

#head .head_menu li {
  display: inline-block;
  margin: 0 0 0 15px;
}

#head.head_menulia:hover {
  opacity: .7;
}

#head .head_menu--nobody {
  /* 未ログイン時に追加で付与されるクラス */
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
}

#head .head_menu--nobody li {
  display: inline-block;
  margin-left: 5px;
}

#head .head_menu--nobody li a {
  display: block;
  padding: 7px 16px;
  border: 1px solid #666;
  border-radius: 2px;
  font-size: 12px;
  text-decoration: none;
}

#head .head_menu--nobody li.login a {
  background: #333;
  color: #fff;
  border: 1px solid #333;
}

#head .switch + .head_menu--nobody {
  /* サイトのモード切替、の直後にある、未ログイン時のメニュー */
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(0%);
}

p.hello {
  text-align: right;
}

/* カスタム共通ヘッダー
----------------------------------------------- */
.st-logo {
  max-width: 200px;
}

.st-logo img {
  max-width: 200px;
}

.st-hdItem_Entry a,
.st-hdItem_Login a {
  display: block;
  width: 100px;
  height: 56px;
  color: #3D270F;
  text-decoration: none;
}

.st-hdItem_Entry a {
  background-color: #FF7E41;
}

.st-hdItem_Entry a:hover {
  opacity: .8;
}

.st-hdItem_Login a:hover {
  background-color: #FFF0DB;
}

.st-hdItem_Entry {
  border-left: 1px solid #6C6154;
  border-right: 1px solid #6C6154;
  color: #fff;
}

.st-hdItem_Login {
  border-right: 1px solid #6C6154;
  color: #3D270F;
}

.st-hdItem_TxtEntry {
  padding-bottom: 2px;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
}

.st-hdItem_TxtEntry .ic {
  display: inline;
  margin-left: 6px;
  padding: 2px 6px;
  background-color: #fff;
  border-radius: 4px;
  color: #FF7E41;
  font-size: 10px;
  line-height: 1;
}

.st-hdItem_TxtLogin {
  color: #3D270F;
  font-size: 1.2rem;
  line-height: 1.8;
  font-weight: 700;
  text-align: center;
}

.st-hdIcon {
  position: relative;
  margin-bottom: -4px;
  padding-top: 8px;
  text-align: center;
}

/* グローバルナビゲーション(共通)
----------------------------------------------- */
#menu {
  background: #fff;
}

#menu > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1032px;
  margin: 0 auto;
}

#menu > ul > li > a {
  display: block;
  padding: 0;
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4;
  text-decoration: none;
}

#menu > ul > li > a:hover {
  color: #FF7E41;
}

#menu .addMainMenu {
  position: absolute;
  z-index: 80;
  background: #333;
  text-align: left;
  width: 170px;
}

#menu .addMainMenu ul {
  width: auto;
}

#menu .addMainMenu li {
  width: 100%;
}

#menu .addMainMenu ul li a {
  display: block;
  padding: 15px 25px;
  color: #fff;
  text-decoration: none;
}

#menu.addMainMenuullia:hover {
  background: #666;
}

#menu .parent_menu div {
  display: none;
}

/* フッター
----------------------------------------------- */
#foot {
  margin: 0;
  padding: 45px 0 9px;
  background-color: #fff;
}

#foot {
  position: relative;
  background-image: url("../img/common/footer_bg.png");
  background-repeat: repeat-x;
  background-size: 57px;
}

#foot .foot_inline .foot_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 798px;
  margin: auto;
  padding-top: 15px;
}

#foot #pagetop {
  position: absolute;
  right: 216px;
  bottom: 54px;
}

/* カスタム共通フッター
----------------------------------------------- */
#foot .st-ftMenu a {
  color: #3D270F;
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center;
  text-decoration: none;
}

#foot .st-ftMenu a:hover {
  color: #ff7e41;
}

/* テーブル
----------------------------------------------- */
.contents_m table {
  width: 100%;
  line-height: 150%;
  margin-top: 42px;
}

.contents_m table .interview-table {
  table-layout: fixed;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .contents_m table {
    table-layout: fixed;
  }
}

.contents_m table caption {
  padding-bottom: 16px;
  color: #3D270F;
  font-size: 2.2rem;
  font-weight: 700;
}

.contents_m table caption .sub-caption {
  display: block;
  margin-top: 10px;
  color: #3D270F;
  font-size: 1.2rem;
  font-weight: 400;
}

.contents_m table caption .sub-caption .closed {
  margin-right: 6px;
}

.contents_m table th {
  position: relative;
  width: 233px;
  padding: 16px;
  background-color: #FFF0DB;
  border: 1px solid #E0E0E0;
  color: #3D270F;
  font-size: 16px;
  font-weight: 700;
}

.contents_m table td {
  padding: 16px;
  background: #fff;
  border: 1px solid #e0e0e0;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .cuser .contents_m table th {
    min-width: auto;
    width: auto;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .cuser .contents_m table td {
    min-width: auto;
    width: auto;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .cuser .contents_m table.job-info th {
    max-width: 200px;
    width: 200px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .cuser .contents_m table.job-info td {
    min-width: auto;
    width: auto;
  }
}

.cuser .contents_m table.job-info td {
  padding: 16px;
  background: #fff;
  border: 1px solid #e0e0e0;
}

table.inquiry_form td {
  padding: 16px;
  background: #fff;
  border: 1px solid #e0e0e0;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .contents_m table td {
    max-width: calc(100% - 233px);
  }
}

.contents_m table td.ta_center {
  text-align: center;
}

.contents_m table td.ta_right {
  text-align: right;
}

.contents_m table td div[class^="label"] {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0 0;
}

.contents_m table td div[class^="label"] label {
  margin: 0 0 5px;
}

.contents_m table td .label2 label {
  /*label 2列*/
  width: 50%;
}

.contents_m table td .label3 label {
  /*label 3列*/
  width: 33%;
}

.contents_m table td .label4 label {
  /*label 4列*/
  width: 25%;
}

.contents_m table td .label5 label {
  /*label 5列*/
  width: 20%;
}

.contents_m table td .label6 label {
  /*label 6列*/
  width: 16%;
}

.contents_m table td .label7 label {
  /*label 7列*/
  width: 14%;
}

input[type="checkbox"] {
  margin-right: 5px;
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 2px;
}

/* 検索テーブル（search_table）
----------------------------------------------- */
.contents_m .search_table input[type="text"] {
  max-width: 200px;
}

.contents_m .search_table div[class^="label"] {
  display: flex;
  flex-wrap: wrap;
}

.contents_m .search_table .label3 label {
  width: 50%;
  margin: 0 0 5px;
}

.contents_m .search_table .label4 label {
  width: 50%;
  margin: 0 0 5px;
}

.contents_m .search_table .label5 label {
  width: 33.33%;
  margin: 0 0 5px;
}

/* 検索結果一覧テーブル（list_table）
----------------------------------------------- */
.contents_m .list_table th {
  padding: 8px;
  border-left: 1px dotted #ddd;
  border-right: 1px dotted #ddd;
}

.contents_m .list_table td {
  padding: 16px 8px;
  border-left: 1px dotted #ddd;
  border-right: 1px dotted #ddd;
}

.contents_m .list_table td > label {
  display: block;
  width: fit-content;
  margin: auto;
}

.contents_m .list_table tr.odd td {
  background-color: #fafafa;
}

/* 操作アイコン */
.contents_m .list_table .operation {
  text-align: center;
  line-height: 1;
}

.contents_m .list_table .operation li {
  display: inline-block;
  line-height: 1;
}

.contents_m .list_table .operation li + li {
  margin-left: 8px;
}

.contents_m .list_table .operation li a {
  display: inline-block;
  width: 80px;
  height: 40px;
  padding: 10px 12px 10px 16px;
  background-color: #FFFFFF;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  text-align: right;
}

/*編集*/
.contents_m .list_table .operation li.edit a {
  border: 2px solid #FF7E41;
  color: #FF7E41;
  background-image: url(../img/icon/entry02.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: top 50% left 12px;
}

.contents_m .list_table .operation li.edit a:hover {
  background-color: #FFF0DB;
}

/*詳細*/
.contents_m .list_table .operation li.more a {
  border: 2px solid #FF7E41;
  color: #FF7E41;
  background-image: url(../img/icon/note02.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: top 50% left 12px;
}

.contents_m .list_table .operation li.more a:hover {
  background-color: #FFF0DB;
}

.contents_m .list_table .operation li.more a.ic-mail {
  background-image: url(../img/icon/mail02.svg);
}

/*削除*/
.contents_m .list_table .operation li.delete a {
  background-image: url(../img/icon/close02.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 16px 16px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
}

.contents_m .list_table .operation li.delete a:hover {
  background-color: #F2F2F2;
}

/*メッセージ応募*/
.btn-msgEntry {
  min-width: 80px;
  height: 40px;
  padding: 8px 12px 8px 28px;
  background-color: #FF7E41;
  background-image: url(../img/icon/entry.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 16px 16px;
  border-radius: 6px;
  border: none;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
}

.btn-msgEntry:hover {
  opacity: .8;
}

/*メッセージスカウト辞退*/
.btn-msgScout {
  min-width: 80px;
  height: 40px;
  padding: 6px 12px 6px 28px;
  font-size: 1.6rem;
  font-weight: 700;
  background-image: url(../img/icon/close02.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 16px 16px;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
}

.btn-msgScout:hover {
  background-color: #F2F2F2;
}

.contents_m .all_operation {
  margin: 16px 0;
}

/* 詳細ページテーブル（info_table）
----------------------------------------------- */
.info_table {
  margin-top: 24px;
  padding: 40px 34px;
}

.info_table table {
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 400;
}

.info_table table > div {
  margin-top: 8px;
}

.info_table table th,
.info_table table td {
  border: 1px solid #E0E0E0;
}

.info_table table th {
  position: relative;
  width: 150px;
  padding: 8px 8px 8px 38px;
  background-color: #FFF0DB;
  font-weight: 700;
}

.info_table td {
  padding: 8px 8px 8px 26px;
}

.info_table .cuser_container table th {
  padding: 16px;
}

/* 入力フォームテーブル（form_table）
----------------------------------------------- */
.contents_m .form_table th .requisite {
  position: absolute;
  top: 50%;
  right: 16px;
  padding: 4px 6px;
  background-color: #FB5555;
  border-radius: 4px;
  color: #ffffff;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1;
  transform: translateY(-50%);
}

.contents_m .form_table td .hint {
  color: #3D270F;
  display: block;
  margin-bottom: 16px;
  font-size: 1.2rem;
  font-weight: 400;
}

.contents_m .form_table .closed {
  margin-left: 8px;
  padding: 4px 6px;
  background: rgba(54, 59, 64, 0.4);
  border-radius: 4px;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 400;
}

.contents_m .form_table td select + select {
  margin-left: 16px;
}

.contents_m .form_table td.validate input[type=text]:required,
.contents_m .form_table td.validate input[type=text]:invalid,
.contents_m .form_table td.validate input[type=mail]:invalid,
.contents_m .form_table td.validate input[type=password]:invalid,
.contents_m .form_table td.validate select:invalid {
  background-color: #FFF2F2;
  box-shadow: 0px 0px 6px #FB5555;
}

.contents_m .form_table td.validate .error {
  /*個別*/
  color: #FB5555;
  margin-top: 5px;
  font-size: 1.2rem;
}

.contents_m .main > .error {
  /*総括*/
  color: #FB5555;
  font-weight: bold;
  margin-bottom: 10px;
}

.contents_m .form_table input[type="text"]:focus,
.contents_m .form_table textarea:focus,
.contents_m .form_table select:focus {
  border: 1px solid #0099ff;
}

.contents_m .form_table input[type="text"],
.contents_m .form_table input[type="text"]:valid,
.contents_m .form_table textarea,
.contents_m .form_table select,
.contents_m .form_table select[type="text"]:valid {
  padding: 10px 8px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  border: 1px solid #fff;
  font-size: 1.6rem;
}

.contents_m .login_table select,
.contents_m .form_table select {
  min-width: 180px;
  height: 40px;
  margin-top: 24px;
  padding: 0 24px 0 10px;
  appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
  color: #828282;
  font-size: 1.6rem;
}

.contents_m .login_table .select-wrap,
.contents_m .form_table .select-wrap {
  position: relative;
}

.contents_m .login_table input[name=zip1],
.contents_m .form_table input[name=zip1] {
  margin-right: 6px;
}

.contents_m .login_table .zip-mark,
.contents_m .form_table .zip-mark {
  margin-right: 8px;
  font-size: 1.6rem;
}

.contents_m .login_table .addressInput01 input,
.contents_m .form_table .addressInput01 input {
  max-width: 180px;
}

.contents_m .login_table .addressInput02 input,
.contents_m .form_table .addressInput02 input {
  max-width: 300px;
}

.contents_m .login_table .addressLabel,
.contents_m .form_table .addressLabel {
  display: block;
  margin-top: 16px;
  color: #3D270F;
  font-size: 1.2rem;
}

.contents_m .login_table input[type=password],
.contents_m .login_table input[type=password]:valid,
.contents_m .form_table input[type=password],
.contents_m .form_table input[type=password]:valid {
  height: 40px;
  max-width: 180px;
  padding: 6px 8px;
  background-color: #fff;
  border: #fff;
  border-radius: 4px;
  font-size: 1.6rem;
}

.contents_m .login_table input[type=password]::placeholder,
.contents_m .form_table input[type=password]::placeholder {
  color: #828282;
}

.contents_m .login_table .zip-inputBtn,
.contents_m .form_table .zip-inputBtn {
  position: relative;
}

.contents_m .login_table .zip-inputBtn input,
.contents_m .form_table .zip-inputBtn input {
  position: relative;
  display: inline-block;
  margin-left: 16px;
  padding: 6px 16px 6px 36px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
}

.contents_m .login_table .zip-inputBtn input:hover,
.contents_m .form_table .zip-inputBtn input:hover {
  background-color: #FFF0DB;
}

.contents_m .login_table .zip-inputBtn::before,
.contents_m .form_table .zip-inputBtn::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 31px;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/search.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
  z-index: 1;
}

.contents_m .login_table label + label,
.contents_m .form_table label + label {
  margin-left: 16px;
}

.contents_m .login_table input[type="submit"].btn_check,
.contents_m .form_table input[type="submit"].btn_check {
  width: 240px;
  padding: 12px 24px 12px 16px;
  background-color: #FF7E41;
  background-image: url(../img/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 16px;
  background-size: 16px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
}

.contents_m .login_table input[type="submit"].btn_check._w400,
.contents_m .form_table input[type="submit"].btn_check._w400 {
  width: 400px;
}

.contents_m .login_table input[type="submit"].btn_back,
.contents_m .form_table input[type="submit"].btn_back {
  margin-bottom: 16px;
  padding: 12px 16px 12px 24px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/left-circle-back.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 16px;
  background-size: 16px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  border-radius: 6px;
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
}

.contents_m .login_table input[type="submit"].btn_back:hover,
.contents_m .form_table input[type="submit"].btn_back:hover {
  background-color: #F2F2F2;
}

.contents_m .login_table .button,
.contents_m .form_table .button {
  position: relative;
  margin: 40px auto 0;
}

.contents_m .login_table .button > input + input,
.contents_m .form_table .button > input + input {
  margin-left: 16px;
}

.contents_m .login_table .button svg,
.contents_m .form_table .button svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 1;
}

.check_table .button {
  position: relative;
  margin: 40px auto 0;
}

.check_table input[type="submit"].btn_back {
  width: 120px;
  margin-right: 24px;
  background: #FFFFFF;
  border: 2px solid rgba(54, 59, 64, 0.4);
  box-shadow: none;
  color: rgba(54, 59, 64, 0.4);
}

.check_table input[type="submit"].btn_back:hover {
  background-color: #F2F2F2;
}

.check_table .btn_back-wrap,
.check_table .btn_regist-wrap {
  position: relative;
}

.check_table .btn_back-wrap svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  z-index: 1;
}

.check_table .btn_regist-wrap svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 1;
}

.contents_m .form_table textarea {
  font-family: sans-serif;
  font-size: 1.6rem;
}

.contents_m .form_table td .hint-noneWrap .hint {
  display: none;
}

.contents_m .form_table td .hint-noneWrap a {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 40px;
  margin-left: 16px;
  padding: 10px 0 10px 20px;
  background-color: #fff;
  border: 2px solid #ff7e41;
  border-radius: 6px;
  color: #ff7e41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1;
}

.contents_m .form_table td .hint-noneWrap a:hover {
  background-color: #FFF0DB;
}

.contents_m .form_table td .hint-noneWrap a::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icon/mail02.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
  z-index: 1;
}

.contents_m .form_table td .hint-noneWrap.wide a {
  width: 200px;
}

input[name=mail] + a,
input[name=notice_mail] + a,
input[name=sub_mail] + a,
input[name=sub_mail2] + a {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 40px;
  margin-left: 16px;
  padding: 10px 0 10px 34px;
  background-color: #fff;
  border: 2px solid #ff7e41;
  border-radius: 6px;
  color: #ff7e41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1;
}

input[name=mail] + a:hover,
input[name=notice_mail] + a:hover,
input[name=sub_mail] + a:hover,
input[name=sub_mail2] + a:hover {
  background-color: #FFF0DB;
}

input[name=mail] + a::before,
input[name=notice_mail] + a::before,
input[name=sub_mail] + a::before,
input[name=sub_mail2] + a::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icon/mail02.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
  z-index: 1;
}

.hint-wrap .hint {
  margin-top: 16px;
}

.contents_m .form_table .select-wrap_m-16 select,
.contents_m .form_table .select-wrap_m-16 input {
  margin: 0 16px;
}

.contents_m .form_table .select-wrap_m-16 select {
  width: 128px;
  min-width: initial;
}

/* 確認フォームテーブル（check_table）
----------------------------------------------- */
/* ログインフォームテーブル（login_table）
----------------------------------------------- */
.login_table input[name=mail],
.login_table input[name=passwd] {
  max-width: 240px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
}

.login_table input[name=passwd] + label {
  margin-left: 16px;
}

.login_table .hint {
  display: block;
  margin-top: 8px;
  font-size: 1.2rem;
}

/* ボタン
----------------------------------------------- */
.contents_m .button {
  margin: 40px auto 0;
  text-align: center;
}

.contents_m .button input {
  margin: 0 10px 0 0;
  padding: 3px 10px;
}

.btn-main {
  position: relative;
  display: inline-block;
  width: 240px;
  margin: auto;
  padding: 12px 24px 12px 16px;
  background-color: #FF7E41;
  background-image: url(../img/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.btn-main:hover {
  opacity: .8;
}

.btn-main._note {
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/note03.svg);
  background-repeat: no-repeat;
  background-position: center left 16px;
}

.btn-main.ml-16 {
  margin-left: 16px;
}

.contents_m .button .btn-main._mail {
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/mail03.svg);
  background-position: center left 16px;
}

.contents_m .button .btn-main._mail + .btn-main._mail {
  margin-left: 16px;
}

.contents_m .button .btn-main._note {
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/note03.svg);
  background-position: center left 16px;
}

.btn-mainBack {
  position: relative;
  display: inline-block;
  width: 240px;
  margin: auto;
  padding: 12px 16px 12px 24px;
  background-color: #FF7E41;
  background-image: url(../img/icon/arrow/left-circle-white.svg);
  background-repeat: no-repeat;
  background-position: center left 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.btn-mainBack:hover {
  opacity: .8;
}

.contents_m .button input[type="button"].btn-sub,
.btn-sub {
  position: relative;
  display: block;
  width: 240px;
  margin: auto;
  padding: 12px 24px 12px 16px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/right-circle-main.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.contents_m .button input[type="button"].btn-sub:hover,
.btn-sub:hover {
  background-color: #FFF0DB;
}

.contents_m .button input[type="button"].btn-sub._w320,
.btn-sub._w320 {
  width: 320px;
  padding: 12px 16px;
  background-image: none;
}

.sch-from_contents .button._wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 320px;
  height: auto;
  margin: 24px auto 0;
  padding: 0;
}

.sch-from_contents .button._wrap:hover {
  opacity: 1;
}

.btn-main + .btn-sub,
.btn-main + .btn-main,
.btn-sub + .btn-sub {
  margin-top: 16px;
}

#contents .contents_m .form_table input[type="submit"].btn_back {
  box-shadow: none;
}

.button._right {
  margin: 16px 0 0 auto;
}

textarea[name=hope_work_place_label] + button,
.contents_m .button input[type="button"].btn-border {
  position: relative;
  display: block;
  width: 240px;
  margin: 16px auto 0;
  padding: 10px 16px 10px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
}

textarea[name=hope_work_place_label] + button._inline,
.contents_m .button input[type="button"].btn-border._inline {
  display: inline-block;
  margin-left: 16px;
}

textarea[name=hope_work_place_label] + button._w320,
.contents_m .button input[type="button"].btn-border._w320 {
  width: 320px;
}

textarea[name=hope_work_place_label] + button._right,
.contents_m .button input[type="button"].btn-border._right {
  margin: 16px 0 0 auto;
}

textarea[name=hope_work_place_label] + button:hover,
.contents_m .button input[type="button"].btn-border:hover {
  background-color: #FFF0DB;
}

input[type=button],
button.btn-border {
  position: relative;
  display: block;
  margin: 16px 0 0;
  padding: 8px 16px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
}

input[type=button]._inline,
button.btn-border._inline {
  display: inline-block;
  margin-left: 16px;
}

input[type=button]._w320,
button.btn-border._w320 {
  width: 320px;
}

input[type=button]:hover,
button.btn-border:hover {
  background-color: #FFF0DB;
}

.btn-unsubscribe,
.contents_m .button input[type="submit"].btn-unsubscribe {
  position: relative;
  display: block;
  width: 240px;
  margin: auto;
  padding: 10px 24px 10px 16px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/right-circle-gray.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  box-shadow: none;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.btn-unsubscribe:hover,
.contents_m .button input[type="submit"].btn-unsubscribe:hover {
  background-color: #F2F2F2;
}

/* 画面メッセージ
----------------------------------------------- */
.contents_m .message {
  padding: 24px;
}

.contents_m .message ul {
  margin-top: 20px;
}

.contents_m .message ul li {
  padding-left: 10px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
}

.contents_m .message p {
  margin-bottom: 10px;
}

/* エラーメッセージ
----------------------------------------------- */
.contents_m .error_message {
  margin-top: 28px;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid #FB5555;
  border-radius: 4px;
  color: #FB5555;
  font-size: 1.6rem;
}

.contents_m .error_message ul {
  margin-top: 20px;
}

.contents_m .error_message ul li {
  padding-left: 10px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
}

.contents_m .error_message p {
  margin-bottom: 10px;
}

/* ページャー
----------------------------------------------- */
.contents_m .search_page {
  margin: 16px 0 0;
}

.contents_m .search_page p {
  color: #3D270F;
  font-size: 1.2rem;
}

.contents_m .search_page p span {
  font-family: Roboto;
  font-size: 2rem;
  font-weight: 700;
}

.contents_m .search_page ul {
  margin: 24px auto 0;
  text-align: center;
}

.contents_m .search_page ul li {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  line-height: 100%;
}

.contents_m .search_page ul li.page {
  font-family: Roboto;
}

.contents_m .search_page ul li.back {
  margin-right: 16px;
}

.contents_m .search_page ul li.next {
  margin-right: 0;
  margin-left: 8px;
}

.contents_m .search_page ul li.back a,
.contents_m .search_page ul li.next a {
  padding: 8px 24px 8px 16px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.contents_m .search_page ul li.back a:hover,
.contents_m .search_page ul li.next a:hover {
  background-color: #FFF0DB;
}

.contents_m .search_page ul li.back a {
  padding: 8px 16px 8px 24px;
}

.contents_m .search_page ul li.back a svg {
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translateY(-50%);
}

.contents_m .search_page ul li.next a svg {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .contents_m .search_page ul li {
    margin-right: 4px;
  }
  /* IE11 */
}

.contents_m .search_page ul li.focus {
  padding: 8px 11.42px;
  background: #FF7E41;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-family: Roboto;
  font-weight: 700;
}

.contents_m .search_page ul li a {
  display: inline-block;
  padding: 8px 11.42px;
  background: #F2F2F2;
  border-radius: 4px;
  color: #828282;
  font-weight: 700;
  text-decoration: none;
}

.contents_m.search_pageullia:hover {
  background-color: #eee;
}

/* sub_menu
----------------------------------------------- */
.contents_m ul.sub_menu {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 0;
}

.contents_m ul.sub_menu.center {
  text-align: center;
}

.contents_m ul.sub_menu li {
  display: inline-block;
  background: none;
}

.contents_m ul.sub_menu li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 176px;
  height: 40px;
  padding: 2px 0 2px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.contents_m ul.sub_menu li a:hover {
  background-color: #FFF0DB;
}

.contents_m ul.sub_menu li a.ic-job {
  padding: 2px 0 2px 24px;
  background-image: url(../img/icon/job02.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px 16px;
}

.contents_m ul.sub_menu li a.ic-map {
  padding: 2px 0 2px 24px;
  background-image: url(../img/icon/map02.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px 16px;
}

.contents_m ul.sub_menu li a.ic-edit {
  width: 128px;
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/entry02.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px 16px;
  font-size: 1.6rem;
}

.contents_m ul.sub_menu li a.ic-remove {
  width: 128px;
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/close.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px 16px;
  font-size: 1.6rem;
}

.contents_m ul.sub_menu li a.font-large {
  font-size: 1.6rem;
}

.contents_mul.sub_menuli a:hover {
  background-color: #111;
}

.contents_m ul.sub_menu li.back a {
  padding: 12px 16px 12px 24px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/left-circle-back.svg);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 12px 12px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  border-radius: 6px;
  color: rgba(54, 59, 64, 0.4);
}

.contents_m ul.sub_menu li.back a:hover {
  background-color: #F2F2F2;
}

/* ソーシャルボタン
----------------------------------------------- */
#social {
  margin: 45px 0 20px;
  text-align: right;
}

#social a:hover {
  opacity: .8;
}

#social > div {
  display: inline-block;
}

#social > div + div {
  margin-left: 16px;
}

.sns-hatena {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../img/common/sns_b.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.sns-twitter {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../img/common/sns_twi.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.sns-facebook {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../img/common/sns_fb.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.sns-line {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../img/common/sns_line.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

/* サイトからのお知らせ
----------------------------------------------- */
.contents_m .system_news .date {
  text-align: right;
}

.contents_m .system_news ul {
  padding: 0 10px;
}

.contents_m .system_news ul li {
  margin-bottom: 5px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
  padding-left: 10px;
}

/* ニュース
----------------------------------------------- */
.contents_box.news {
  margin-top: 40px;
  padding: 24px 24px 27px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border: none;
  border-radius: 4px;
}

.contents_box.news h2 {
  position: relative;
  padding: 0;
  border-bottom: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.contents_box.news h2::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 40%;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/news.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_box.news h2::after {
  content: none;
}

.contents_box.news .body {
  padding: 0;
}

.contents_box.news ul {
  margin-top: 16px;
}

.contents_box.news ul li {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding: 12px 48px 12px 16px;
  color: #2382C7;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.1);
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none;
}

.contents_box.news ul li:hover {
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.2), rgba(150, 198, 232, 0.2)), #FFFFFF;
}

.contents_box.news ul li::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icon/arrow/right-circle.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_box.news ul li > span {
  float: none;
}

.contents_box.news ul li > p {
  margin-left: 24px;
}

.contents_box.news ul li a {
  color: #2382C7;
  font-weight: 700;
  text-decoration: none;
}

.contents_box.news .more {
  display: none;
}

.contents_m .news .body ul {
  padding: 0 10px;
}

.contents_m .news .body ul li {
  margin-bottom: 8px;
  zoom: 1;
}

.contents_m.news.bodyulli:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
}

.contents_m .news .body ul li span {
  float: left;
  width: 80px;
}

.contents_m .news .body ul li p {
  margin-left: 36px;
}

/* ニュース本文
----------------------------------------------- */
.contents_m .news_text {
  margin-top: 28px;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, 0.4);
  border-radius: 4px;
}

.contents_m .news_text p {
  margin-bottom: 16px;
}

/* ページ本文
----------------------------------------------- */
.contents_m .page_text {
  margin-top: 24px;
  padding: 40px;
  background: #fff;
  font-size: 13px;
  line-height: 1.4em;
}

.contents_m .page_text h3 {
  font-size: 16px;
  font-weight: bold;
  border: none;
  padding: .5em 1em;
  margin: 0 -1em 1em;
  background: #555;
  color: #fff;
}

.contents_m .page_text h4 {
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding: .5em 1em;
  margin: 0 0 1em;
  background: #eee;
  color: #333;
}

.contents_m .page_text ul {
  margin: 1em 0 2em 2em;
}

.contents_m .page_text ul li {
  list-style: disc outside;
  margin: 0 0 .5em;
}

.contents_m .page_text ol {
  margin: 1em 0 2em 2em;
}

.contents_m .page_text ol li {
  list-style: desimal outside;
  margin: 0 0 .5em;
}

.contents_m .page_text p {
  margin: 0 0 2em;
}

.contents_m .page_text .info_table {
  padding: 0 20px;
}

.qj-qanda {
  margin: 20px 20px 30px;
}

.qj-qanda dl {
  margin: 0 0 20px;
}

.qj-qanda dl dt {
  position: relative;
  padding: 5px 0 0 40px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.3em;
}

.qj-qanda dl dd {
  position: relative;
  padding: 7px 0 20px 40px;
  margin: 0 0 20px;
  border-bottom: 1px dashed #bbb;
}

.qj-qanda dl dd::after,
.qj-qanda dl dt::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 2px;
}

.qj-qanda dl dt::after {
  content: "Q";
  background-color: #2382c7;
}

.qj-qanda dl dd::after {
  content: "A";
  background-color: #ff7e41;
}

.qj-pageBtn {
  text-align: center;
  margin: 0 0 20px;
}

.qj-pageBtn a {
  display: inline-block;
  padding: 1em 4em;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
  text-decoration: none;
  line-height: 1.3em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.qj-pageBtn a + a {
  margin: 0 0 0 .8em;
}

.qj-pagebtna:hover {
  opacity: .7;
  color: #fff;
}

/* 求人情報(embed)
----------------------------------------------- */
.contents_m .embed_job .body ul {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.contents_m .embed_job .body li {
  margin: 0;
  padding: 5px 10px 10px;
  width: 20%;
  box-sizing: border-box;
}

.contents_m .embed_job .body .img {
  margin: 0 0 5px;
}

.contents_m .embed_job .body .img img {
  object-fit: contain;
  width: 192px;
  height: 96px;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.contents_m .embed_job .body .cate {
  font-size: 10px;
  margin: 0 0 5px;
  display: block;
}

.contents_m .embed_job .body .cate .new {
  color: red;
  text-transform: uppercase;
}

.contents_m .embed_job .body .cate .limits {
  color: red;
}

.contents_m .embed_job .body h4,
.contents_m .embed_job .body h3 {
  font-weight: normal;
  font-size: 12px;
  border: none;
  padding: 0;
  margin: 0 0 5px;
}

.contents_m .embed_job .body h4 span,
.contents_m .embed_job .body h3 span {
  display: block;
  font-size: 10px;
}

.contents_m .embed_job .body h4 span br,
.contents_m .embed_job .body h3 span br {
  display: none;
}

/* おすすめ求人一覧
----------------------------------------------- */
/* 新着求人一覧
----------------------------------------------- */
.contents_box.embed_user {
  margin-top: 40px;
  padding: 24px 28px;
  background-color: #FFF0DB;
  border-radius: 4px;
  border: none;
}

.contents_box.embed_user h2 {
  position: relative;
  padding: 0;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.contents_box.embed_user h2.ic-company::before {
  content: " ";
  position: absolute;
  top: 16%;
  left: 40%;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/company.svg);
  background-repeat: no-repeat;
}

.contents_box.embed_user h2::after {
  content: none;
}

.contents_box.embed_user .body ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}

.contents_box.embed_user .body li {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% / 4 - 8px);
  margin: 16px 0 0;
  padding: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(255, 126, 65, 0.6);
}

.contents_box.embed_user .body li:hover {
  box-shadow: 0 0 12px #FF7E41;
}

.contents_box.embed_user .body li a {
  display: block;
  padding: 16px;
  text-decoration: none;
}

.contents_box.embed_user .body .img img {
  border: none;
}

.contents_box.embed_user .cate {
  display: none;
}

.contents_box.embed_user .title {
  color: #2382C7;
  font-size: 1.2rem;
  line-height: 1.48;
}

.contents_box.embed_user .title a {
  color: #2382C7;
  text-decoration: none;
}

.contents_box.embed_user .body li a:hover .title {
  text-decoration: underline;
}

.contents_box.embed_user .text {
  margin-top: 8px;
  line-height: 1.48;
}

/* 締め切り間近の求人
----------------------------------------------- */
/* この企業の求人
----------------------------------------------- */
/* この求人と似た求人
----------------------------------------------- */
/* この求人に応募した人は
----------------------------------------------- */
/* 県内のおすすめ求人(企業詳細ページ下部)
----------------------------------------------- */
/* 新着の求人企業
----------------------------------------------- */
/* この企業を見た人はこんな企業も見ています
----------------------------------------------- */
.contents_m .embed_user .body .img img {
  width: 100%;
  height: auto;
}

.contents_m .embed_user .body h3 span {
  display: block;
}

/* 新着の求職者情報
----------------------------------------------- */
.contents_m .new_nuser .body ul {
  margin: 0 0 15px;
}

.contents_m .new_nuser .body ul li + li {
  margin: 10px 0 0;
}

/* メイン：◯◯から探す
----------------------------------------------- */
.contents_m .items_type .body li {
  display: inline-block;
  margin: 5px;
}

/* メイン：勤務形態から探す
----------------------------------------------- */
.contents_m .workstyleType .body ul {
  display: flex;
  flex-wrap: wrap;
}

.contents_m .workstyleType .body ul li {
  width: 20%;
  margin: 5px 0;
}

/* メイン：職種から探す
----------------------------------------------- */
.contents_m .jobType .body ul {
  display: flex;
  flex-wrap: wrap;
}

.contents_m .jobType .body ul li {
  width: 50%;
  margin: 5px 0;
}

/* メイン：特徴から探す
----------------------------------------------- */
.contents_m .featuresType .body ul {
  display: flex;
  flex-wrap: wrap;
}

.contents_m .featuresType .body ul li {
  width: 25%;
  margin: 5px 0;
}

/* メイン：地域から探す
----------------------------------------------- */
.areaType .body .foreign > ul > li,
.areaType .body .prefectures > ul > li {
  display: block;
  margin: 0;
  overflow: hidden;
}

.areaType .body .foreign > ul > li > .area_name,
.areaType .body .prefectures > ul > li > .area_name {
  border: none;
  margin: 0;
  float: left;
  padding: 5px 0;
  width: 110px;
  font-weight: bold;
}

/* メイン：フリーワード検索
----------------------------------------------- */
.contents_m .free .body {
  text-align: center;
}

.contents_m .free .body input[type="text"] {
  padding: 0 10px;
  margin: 0 0 0 10px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
}

.contents_m .free .body .search_button,
.contents_m .free .body input[type="submit"] {
  position: relative;
  width: 240px;
  padding: 10px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.contents_m .free .body .search_button:hover,
.contents_m .free .body input[type="submit"]:hover {
  opacity: .8;
}

.contents_m .free .body label > span {
  display: inline-block;
  height: 35px;
  line-height: 35px;
}

/* 並び替え・表示件数
----------------------------------------------- */
.contents_m .sort {
  margin: 15px 0;
}

.contents_m .sort dt {
  display: inline-block;
  margin: 0;
  padding: 0 5px 0 0;
  color: #999;
}

.contents_m .sort dd {
  display: inline-block;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 0 10px 0 8px;
}

.contents_m .sort dd + dd {
  display: inline-block;
  border-left: 0;
}

.contents_m .sort dl {
  float: left;
}

.contents_m .sort dl + dl {
  float: right;
}

/*----------------------------------------------------------------【求人検索結果】*/
/* 検索フォーム部
----------------------------------------------- */
.contents_m .top_search .ttl {
  position: relative;
}

.contents_m .top_search .ttl h3 {
  position: relative;
  margin: 0 auto;
  padding: 0;
  border: none;
  font-size: 2.2rem;
  text-align: center;
}

.contents_m .top_search .ttl h3 svg {
  position: relative;
  top: 2px;
  margin-right: 8px;
}

.contents_m .top_search .ttl .sub_menu {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

.contents_m .top_search .button {
  background: transparent;
}

.contents_m .top_search .search_table table {
  margin: 0;
}

.contents_m .search_table select {
  display: inline-block;
  height: 40px;
  appearance: none;
  background-color: #ffffff;
  background-image: url(../img/icon/arrow/bottom.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 0 24px 0 10px;
  font-size: 1.6rem;
  color: #828282;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
}

.contents_m .search_table select + select {
  margin-left: 16px;
}

/* 求人情報
----------------------------------------------- */
.job_container {
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 0 0 32px;
}

.job_container .stop {
  color: red;
  padding: 10px 7px;
  border: 1px solid red;
  margin: 0 0 10px;
  line-height: 1.5;
}

.job_container .date {
  text-align: right;
  margin: 0 0 10px;
  color: #999;
  font-size: 12px;
}

.job_container .photo {
  border: 1px solid #ccc;
  padding: 2px;
  float: left;
}

.job_container .photo .frame {
  padding: 5px;
  margin: 0 0 10px 0;
  border: 1px solid #eee;
  background: url(../../img/admin/grad_top_white_50.gif) repeat-x top;
}

.job_container .photo .catchcopy {
  padding: 0 0 5px 0;
  text-align: left;
  line-height: 1.6;
}

.job_container .jobtext .icon {
  overflow: hidden;
  margin: 0 0 15px;
  padding: 0;
}

.job_container .jobtext .icon li {
  float: left;
  margin: 0 5px 5px 0;
  white-space: nowrap;
}

.job_container .jobtext .icon li a {
  display: block;
  font-size: 12px;
  padding: 5px 10px;
  border: 1px solid #999;
  border-radius: 2px;
  text-decoration: none;
}

.job_container .jobtext p {
  line-height: 1.48;
  margin: 24px 0 0;
}

.job_container .button {
  margin: 16px 0 0;
}

.job_container .button a {
  display: inline-block;
  padding: 8px 0;
  width: 180px;
  margin: 0 2px;
  background-color: #666;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border-radius: 2px;
}

.job_container .button a.not {
  background-color: #aaa;
}

.job_container.buttona:hover {
  background-color: #333;
}

.job_container .item_img {
  margin: 15px 0;
  display: flex;
}

.job_container .item_img li {
  width: 33.33%;
  padding: 10px;
}

.job_container .item_img li + li {
  margin: 0;
}

#contents .job_container .item_img li p.img {
  height: auto;
  margin: 0 0 -24px;
  text-align: center;
}

.job_container .item_img li p.img img {
  width: 100%;
  height: auto;
}

.job_container .item_img li p.img span {
  display: inline-block;
  line-height: 165px;
  color: #999;
}

.job_container .item_img li p.text {
  padding: 5px;
  font-size: 12px;
}

/* 求人リスト
----------------------------------------------- */
.contents_m .list {
  position: relative;
  background: #fff;
  margin: 0 0 15px 0;
  border: 1px solid #ccc;
  padding: 28px 16px;
}

.contents_m .list.list_pickup {
  position: relative;
}

.contents_m .list.list_pickup::before {
  content: "PICKUP";
  position: absolute;
  top: 0;
  left: 0;
  background: #2382C7;
  border-radius: 4px 0px 0px 0px;
  color: #fff;
  margin-right: 4px;
  padding: 2px 10px;
  font-family: Roboto;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  z-index: 1;
}

.contents_m .list .title {
  line-height: 1.5;
  margin-bottom: 10px;
}

.contents_m .list .title h3 {
  font-size: 14px;
  font-weight: bold;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.contents_m .list .title h3 span.cate {
  color: #666;
  font-weight: normal;
  font-size: 12px;
  display: block;
}

.contents_m .list .title h3 .pickup {
  position: absolute;
  top: 0;
  left: 82px;
  background: #FF7E41;
  color: #fff;
  padding: 2px 10px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.contents_m .list .title h3 .pickup._right-0 {
  left: 0;
  border-radius: 4px 0 0 0;
}

.contents_m .list .title h3 .pickup.history {
  left: 0;
}

.contents_m .list .title h3 .limitation {
  background-color: #333;
  color: #fff;
  font-size: 10px;
  margin: 3px 0 0 3px;
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  padding: 0 3px;
}

.contents_m .list .title h3 a {
  color: #333;
}

.contents_m .list .title p.gift {
  position: absolute;
  top: 0;
  right: 24px;
  min-width: 96px;
  padding: 20px 16px;
  background-color: #FB5555;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}

.contents_m .list .title p.gift::before {
  position: absolute;
  top: 100%;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 49px 0 0;
  border-color: #FB5555 transparent transparent transparent;
}

.contents_m .list .title p.gift::after {
  position: absolute;
  top: 100%;
  right: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 49px 22px 0;
  border-color: transparent #FB5555 transparent transparent;
}

.contents_m .list .title p.gift span {
  font-family: Roboto;
  font-size: 1.8rem;
}

.contents_m .list .title p.gift .small,
.contents_m .sch-detail p.gift span.small {
  font-size: 1.2rem;
}

.contents_m .list .job_container {
  border: none;
  padding: 0;
}

.contents_m .list .job_container p.limits {
  text-align: center;
  font-weight: bold;
  color: #ff3333;
  margin-bottom: 5px;
}

.contents_m .list .job_container .jobtext .icon li a {
  font-size: 10px;
  padding: 2px 5px;
}

.contents_m .list .job_container .photo + .jobtext {
  margin: 0 0 0 150px;
}

.contents_m .list .job_container .button {
  margin: 24px 0 0;
}

.contents_m .list .job_container table {
  margin: 0 0 10px;
}

.contents_m .list .job_container .jobtext p {
  margin: 0 0 10px;
}

.contents_m .list .job_container .jobtext .icon {
  margin: 0;
}

/* カスタム求人リスト
----------------------------------------------- */
.contents_m .sch-resList .att {
  padding: 0 24px;
}

.contents_m .sch-resList .list {
  margin: 40px 0 0;
  padding: 0;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.contents_m .sch-resList .title {
  position: relative;
  padding: 32px 143px 17px 24px;
  border-radius: 4px 4px 0px 0px;
  display: flex;
}

.contents_m .sch-resList .list.list_pickup .title {
  padding: 32px 143px 17px 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px 4px 0px 0px;
}

.contents_m .sch-resList .title h3 a {
  color: #2382C7;
  font-size: 2.2rem;
  text-decoration: none;
}

.contents_m .sch-resList .title h3 a:hover {
  text-decoration: underline;
}

.contents_m .sch-resList .title h3 span.cate {
  color: #3D270F;
  font-size: 1.6rem;
}

.contents_m .sch-resList .job_container {
  margin: 0;
  padding: 0 0 24px;
}

.contents_m .sch-resList .job_container .photo {
  float: none;
  width: 600px;
  margin: 16px auto 0;
  border: none;
  text-align: center;
}

.contents_m .sch-resList .job_container .photo img {
  object-fit: contain;
  width: 600px;
  height: 300px;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.contents_m .sch-resList .job_container .photo + .jobtext {
  margin: 24px 0 0;
  padding: 0 24px 0;
  color: #3D270F;
  font-size: 1.6rem;
}

.contents_m .sch-resList .job_container .jobtext .icon li a {
  position: relative;
  padding: 3px 8px 3px 26px;
  color: #2382C7;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 16px 0px 0px 16px;
  border: none;
  font-size: 1.2rem;
}

.contents_m .sch-resList .job_container .jobtext .icon li a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: translateY(-50%);
}

.contents_m .sch-resList .info_table {
  margin-top: 24px;
  padding: 0 24px 0;
}

.contents_m .sch-resList .info_table + .info_table {
  margin-top: 0;
  border-top: none;
}

.contents_m .sch-resList table {
  border-collapse: separate;
  border-spacing: 0 7px;
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 400;
}

.contents_m .sch-resList table > div {
  margin-top: 8px;
}

.contents_m .sch-resList table th,
.contents_m .sch-resList table td {
  border: none;
}

.contents_m .sch-resList table th {
  position: relative;
  width: 150px;
  padding: 8px 8px 8px 38px;
  background-color: #FFF0DB;
  font-weight: 400;
}

.contents_m .sch-resList th::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 12px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_m .sch-resList th.ic-job::before {
  background-image: url(../img/icon/job02.svg);
}

.contents_m .sch-resList th.ic-wifi::before {
  background-image: url(../img/icon/wifi02.svg);
}

.contents_m .sch-resList th.ic-company::before {
  background-image: url(../img/icon/company.svg);
}

.contents_m .sch-resList th.ic-map::before {
  background-image: url(../img/icon/map02.svg);
}

.contents_m .sch-resList td {
  padding-left: 26px;
}

.contents_m .sch-resList .job_container .button {
  background-color: #FFFFFF;
  border-top: 1px solid #E0E0E0;
  padding: 16px 24px 0;
}

.contents_m .sch-resList .btn_clip {
  position: relative;
  width: 240px;
  padding: 10px 16px 10px 24px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 16px;
  text-align: center;
}

.contents_m .sch-resList .btn_clip:hover {
  background-color: #FFF0DB;
}

.contents_m .sch-resList .btn_clip::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icon/add.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_m .sch-resList .btn_clip.not {
  position: relative;
  width: 240px;
  padding: 10px 16px 10px 24px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/close02.svg);
  background-repeat: no-repeat;
  background-position: center left 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.contents_m .sch-resList .btn_clip.not:hover {
  background-color: #F2F2F2;
}

.contents_m .sch-resList .btn_clip.not::before {
  content: none;
}

.contents_m .sch-resList .btn_more {
  position: relative;
  width: 240px;
  padding: 12px 24px 12px 16px;
  background-color: #FF7E41;
  background-image: url(../img/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 16px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
}

.contents_m .sch-resList .btn_more:hover {
  opacity: .8;
}

.contents_m .sch-resList .btn_more svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

/* 求人の詳細
----------------------------------------------- */
.contents_m .job_detail {
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px;
}

.contents_m .job_detail .job_container {
  border: none;
  padding: 0;
}

.contents_m .job_detail h2.job_h2 span.cate {
  display: block;
  font-weight: normal;
  font-size: 12px;
  margin: 0 0 5px;
}

.contents_m .job_detail h2.job_h2 span.pickup {
  position: absolute;
  top: 0;
  left: 0;
  background: #FF7E41;
  border-radius: 4px 0 0 0;
  color: #fff;
  padding: 2px 10px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.contents_m .job_detail h2.job_h2 span.gift {
  background: #eee;
  border-radius: 2px;
  color: #333;
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
}

.contents_m .job_detail h2.job_h2 span.gift span {
  font-weight: bold;
  padding-left: 3px;
}

/* カスタム求人の詳細
----------------------------------------------- */
.contents_m .sch-detail {
  position: relative;
  margin: 40px 0 0;
  padding: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.contents_m .sch-detail .job_h2 {
  position: relative;
  padding: 28px 132px 0 24px;
  border-radius: 4px 4px 0px 0px;
  color: #3D270F;
  font-size: 2.2rem;
}

.contents_m .sch-detail p.gift {
  position: absolute;
  top: 0;
  right: 24px;
  min-width: 96px;
  padding: 20px 14px;
  background-color: #FB5555;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}

.contents_m .sch-detail p.gift::before {
  position: absolute;
  top: 100%;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 49px 0 0;
  border-color: #FB5555 transparent transparent transparent;
}

.contents_m .sch-detail p.gift::after {
  position: absolute;
  top: 100%;
  right: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 49px 22px 0;
  border-color: transparent #FB5555 transparent transparent;
}

.contents_m .sch-detail p.gift span {
  font-family: Roboto;
  font-size: 1.8rem;
}

.contents_m .sch-detail .job_container .date {
  margin-top: 16px;
  padding: 0 24px;
  color: #3D270F;
}

.contents_m .sch-detail h2.job_h2 span.cate {
  color: #3D270F;
  font-size: 1.6rem;
}

.contents_m .sch-detail .jobtext {
  width: 600px;
  margin: 24px auto 0;
  padding: 0;
}

.contents_m .sch-detail .jobtext .photo {
  float: none;
  width: 600px;
  margin: 16px auto 0;
  padding: 0;
  border: none;
  text-align: center;
}

.contents_m .sch-detail .jobtext .photo .text {
  text-align: left;
}

.contents_m .sch-detail .jobtext .photo img {
  object-fit: contain;
  width: 600px;
  height: 300px;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.contents_m .sch-detail .job_container .item_img {
  justify-content: space-between;
  margin: 24px 0 0;
}

.contents_m .sch-detail .job_container .item_img li {
  width: calc(100% /2 - 13px);
  padding: 0;
  border: none;
}

.contents_m .sch-detail .job_container .jobtext p {
  margin: 24px 0 0;
  color: #3D270F;
  font-size: 1.6rem;
}

.contents_m .sch-detail .job_container .jobtext .icon {
  margin-top: 16px;
}

.contents_m .sch-detail .job_container .jobtext .icon li a {
  position: relative;
  padding: 3px 8px 3px 26px;
  color: #2382C7;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 16px 0px 0px 16px;
  border: none;
  font-size: 1.2rem;
}

.contents_m .sch-detail .job_container .jobtext .icon li a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: translateY(-50%);
}

.contents_m .sch-detail .info_table {
  margin-top: 24px;
  padding: 0 34px 40px;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}

.contents_m .sch-detail .info_table.bb-none {
  border-bottom: none;
}

.contents_m .sch-detail .info_table + .info_table {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

.contents_m .sch-detail table {
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 400;
}

.contents_m .sch-detail table caption {
  position: relative;
  padding: 0 0 16px 28px;
  background-color: #ffffff;
  color: #3D270F;
  font-size: 2.2rem;
  font-weight: 700;
}

.contents_m .sch-detail table caption svg {
  position: absolute;
  top: 0;
  left: 0;
}

.contents_m .sch-detail table > div {
  margin-top: 8px;
}

.contents_m .sch-detail table th,
.contents_m .sch-detail table td {
  border: 1px solid #E0E0E0;
}

.contents_m .sch-detail table th {
  position: relative;
  width: 150px;
  padding: 16px;
  background-color: #FFF0DB;
  font-weight: 700;
}

.contents_m .sch-detail th::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 12px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_m .sch-detail th.ic-job::before {
  background-image: url(../img/icon/job02.svg);
}

.contents_m .sch-detail th.ic-wifi::before {
  background-image: url(../img/icon/wifi02.svg);
}

.contents_m .sch-detail th.ic-company::before {
  background-image: url(../img/icon/company.svg);
}

.contents_m .sch-detail th.ic-map::before {
  background-image: url(../img/icon/map02.svg);
}

.contents_m .sch-detail td {
  padding-left: 26px;
}

.contents_m .sch-detail td.ic-phone {
  font-family: Roboto;
  font-size: 2.2rem;
  font-weight: 700;
}

.contents_m .sch-detail td.ic-phone svg {
  margin-right: 4px;
}

.contents_m .sch-detail .job_container .button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 600px;
  margin: 24px auto 0;
  padding: 0;
  background-color: #FFFFFF;
}

.contents_m .sch-detail .btn_clip,
.contents_m .sch-detail .btn_inquiry,
.contents_m .sch-detail .btn_inquiry.not {
  position: relative;
  width: calc(100% / 2 - 8px);
  padding: 10px 0 10px 10px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.contents_m .sch-detail .btn_clip:hover,
.contents_m .sch-detail .btn_inquiry:hover,
.contents_m .sch-detail .btn_inquiry.not:hover {
  background-color: #FFF0DB;
}

.contents_m .sch-detail .btn_clip::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icon/add.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_m .sch-detail .btn_clip.not {
  position: relative;
  width: calc(100% / 2 - 8px);
  padding: 10px 0 10px 10px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/close02.svg);
  background-repeat: no-repeat;
  background-position: center left 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.contents_m .sch-detail .btn_clip.not:hover {
  background-color: #F2F2F2;
}

.contents_m .sch-detail .btn_clip.not::before {
  content: none;
}

.contents_m .sch-detail .btn_inquiry::before,
.contents_m .sch-detail .btn_inquiry.not::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icon/mail02.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_m .sch-detail .button .btn_entry {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 16px;
  padding: 10px 10px 10px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
}

.contents_m .sch-detail .button .btn_entry:hover {
  opacity: .8;
}

.contents_m .sch-detail .button .btn_entry::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 16px;
  background-image: url(../img/sp/icon/arrow/right-circle-sub.svg);
  transform: translateY(-50%);
}

.contents_m .sch-detail._pb32 {
  padding-bottom: 32px;
}

.contents_m .contents_box.sch-detail_interview {
  margin: 40px 0 0;
  padding: 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
  border: none;
}

.contents_m .contents_box.sch-detail_interview h3 {
  padding-left: 28px;
}

.contents_m .contents_box.sch-detail_interview .ic-mic {
  position: relative;
  padding: 0;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.contents_m .contents_box.sch-detail_interview .ic-mic::before {
  content: " ";
  position: absolute;
  top: 16%;
  left: 29%;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/mic.svg);
  background-repeat: no-repeat;
}

.contents_m .contents_box.sch-detail_interview .ic-mic::after {
  content: none;
}

.contents_m .contents_box.sch-detail_interview .body {
  margin-top: 16px;
}

.contents_m .contents_box.sch-detail_interview .body ul li {
  overflow: initial;
  min-height: 212px;
  margin: 0;
}

.contents_m .contents_box.sch-detail_interview .body ul li + li {
  margin-top: 8px;
}

.contents_m .contents_box.sch-detail_interview .body ul li .img a img {
  object-fit: contain;
  width: 200px;
  border: none;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.contents_m .contents_box.sch-detail_interview .body ul li .title {
  margin: 8px 0 0;
  padding: 0;
  color: #2382C7;
  font-size: 1.6rem;
  border-left: none;
}

.contents_m .contents_box.sch-detail_interview .body ul li .title a {
  color: #2382C7;
  font-size: 1.6rem;
  text-decoration: underline;
}

.contents_m .contents_box.sch-detail_interview .body ul li .title a:hover {
  text-decoration: underline;
}

.contents_m .contents_box.sch-detail_interview .body ul li .cate {
  margin: 0;
  color: #000000;
  font-size: 1.2rem;
}

.contents_m .contents_box.sch-detail_interview .body ul li .text,
.contents_m .contents_box.sch-detail_interview .body ul li .cate,
.contents_m .contents_box.sch-detail_interview .body ul li .title {
  margin-left: 230px;
}

/* 求職者の検索
----------------------------------------------- */
.resume_more {
  text-align: right;
  word-break: keep-all;
}

.resume_more li a {
  padding: 8px 16px;
  background: #FF7E41;
  border-radius: 4px;
  color: #FFFFFF;
  text-decoration: none;
}

.resume_more li a:hover {
  opacity: .8;
}

/* 求人情報の管理
----------------------------------------------- */
.contents_m .list ul.operation {
  text-align: right;
  margin: 0 0 10px;
  padding: 10px 0 0;
}

.contents_m .list ul.operation li {
  display: inline-block;
  background: none;
  margin-left: 7px;
}

.contents_m .list ul.operation li a {
  display: inline-block;
  background-color: #333;
  border-radius: 2px;
  color: #fff;
  text-decoration: none;
  font-weight: normal;
  padding: 8px 16px;
}

.contents_m.listul.operationlia:hover {
  background-color: #111;
}

.contents_m .list ul.operation li.back a {
  background: url(../img/style/back.gif) no-repeat 10px 13px #fff;
  color: #333;
  padding: 7px 16px 7px 25px;
  border: 1px solid #999;
}

.contents_m.listul.operationli.back a:hover {
  background-color: #fafafa;
}

/*----------------------------------------------------------------【企業詳細】*/
.cuser_container {
  margin-bottom: 30px;
}

.cuser_container .box {
  margin-bottom: 10px;
}

.cuser_container .box .photo {
  border: 1px solid #ccc;
  padding: 2px;
  float: left;
}

.cuser_container .box .photo span {
  line-height: 100px;
  width: 133px;
  text-align: center;
  display: block;
  background-color: #fafafa;
}

.cuser_container .box .jobtext {
  line-height: 1.7;
  margin-left: 150px;
}

/*----------------------------------------------------------------【メッセージボックス】*/
/* メッセージ履歴
----------------------------------------------- */
#contents #itemsMessageList {
  margin-bottom: 10px;
}

#contents #itemsMessageList li {
  margin-top: -1px;
  word-break: break-all;
  word-wrap: break-word;
}

#contents #itemsMessageList li p.sub {
  padding: 7px 10px;
  font-weight: bold;
  background-color: #f5f5f5;
}

#contents #itemsMessageList li span.date {
  float: right;
  color: #666;
}

#contents span.mailtype,
#contents #user_info span.mailtype {
  position: relative;
  padding: 3px 8px 3px 26px;
  color: #2382C7;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 16px 0px 0px 16px;
  border: none;
  font-size: 1.2rem;
}

#contents span.mailtype::before,
#contents #user_info span.mailtype::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: translateY(-50%);
}

#contents #itemsMessageList li p.title {
  border-bottom: 1px dotted #ccc;
  padding: 7px 10px;
  font-weight: bold;
}

#contents #itemsMessageList li p.description {
  border: none;
  padding: 10px;
}

#contents #itemsMessageList tr.message td {
  padding: 15px 20px;
  line-height: 1.8;
}

/*----------------------------------------------------------------【サイドカラム】*/
/* サイド：お知らせ
----------------------------------------------- */
.contents_s .contents_box.system_news {
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
}

.contents_s .contents_box.system_news .ic-news::before {
  content: " ";
  position: absolute;
  top: 18px;
  left: 23px;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/news.svg);
  background-repeat: no-repeat;
}

/* サイド：ログインフォーム
----------------------------------------------- */
.contents_s .login {
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #fff;
  border-radius: 4px;
}

.contents_s .login .body dl + p {
  text-align: center;
}

.contents_s .login .body dl .pass input[type="password"],
.contents_s .login .body dl .mail input[type="text"] {
  width: 100%;
  padding: 8px 7px;
  background-color: #fff;
  border: #fff;
  border-radius: 4px;
  font-size: 1.6rem;
}

.contents_s .login .body dl .pass input[type="password"]::placeholder,
.contents_s .login .body dl .mail input[type="text"]::placeholder {
  color: #828282;
}

.contents_s .login .body dl .pass > label {
  display: block;
  margin: 18px 0 10px;
  padding-left: 24px;
}

.contents_s .login .body input[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 40px;
  margin: 0 auto 10px;
  background: #2382C7;
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(35, 130, 199, 0.6);
  cursor: pointer;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
}

.contents_s .login .body input[type="submit"]:hover {
  opacity: .8;
}

/* サイド：ログインフォーム
----------------------------------------------- */
.contents_s .side-Box01 h2 {
  padding: 16px 0 16px 20px;
  border: none;
  color: #3D270F;
  font-size: 1.8rem;
  text-align: center;
}

.contents_s .side-Box01 h2.ic-login::before {
  content: " ";
  position: absolute;
  top: 20px;
  left: 74px;
  width: 16px;
  height: 20px;
  background-image: url(../img/icon/login02.svg);
  background-repeat: no-repeat;
}

.contents_s .side-Box01 h2::after {
  content: none;
}

.contents_s .side-Box01 .body {
  padding: 0 16px 0;
}

.contents_s .side-Box01 .body label {
  padding-left: 0;
  color: #3D270F;
  font-size: 1.2rem;
  line-height: 2;
}

.contents_s .side-Box01 .body a {
  color: #2382C7;
  font-size: 1.2rem;
}

.contents_s .side-Box02 h2 {
  padding: 16px 0 0 20px;
  border: none;
  color: #3D270F;
  font-size: 1.8rem;
  text-align: center;
}

.contents_s .side-Box02 h2.ic-note::before {
  content: " ";
  position: absolute;
  top: 18px;
  left: 48px;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/note.svg);
  background-repeat: no-repeat;
}

.contents_s .side-Box02 h2.ic-rank::before {
  content: " ";
  position: absolute;
  top: 20px;
  left: 22px;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/rank.svg);
  background-repeat: no-repeat;
}

.contents_s .side-Box02 h2::after {
  content: none;
}

.contents_s .side-Box02 .body {
  padding: 16px 16px 19px;
}

.contents_s .side-Box02 .body label {
  color: #3D270F;
  font-size: 1.2rem;
}

.contents_s .side-Box02 .body a {
  color: #2382C7;
  font-size: 1.2rem;
}

.side-bnrArea a {
  display: inline-flex;
  margin-bottom: 24px;
}

.side-bnrArea a:hover {
  opacity: .8;
}

/* サイド：ページリスト
----------------------------------------------- */
.contents_s .page {
  padding: 16px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
}

.contents_s .page h2 {
  padding: 0 0 16px 23px;
}

.contents_s .page h2::before {
  content: " ";
  position: absolute;
  top: 3px;
  left: 40px;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/news.svg);
  background-repeat: no-repeat;
}

.contents_s .page .body {
  padding: 0;
}

.contents_s .page ul {
  margin: 0;
}

.contents_s .page ul li {
  padding-left: 0;
  background: none;
  line-height: 1;
}

.contents_s .page li a {
  position: relative;
  display: block;
  padding: 12px 32px 12px 8px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.4);
  font-size: 1.2rem;
  line-height: 1.4;
  text-decoration: none;
}

.contents_s .page li a:hover {
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.2), rgba(150, 198, 232, 0.2)), #FFFFFF;
}

.contents_s .page li a::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icon/arrow/right-circle.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_s .page li a + li a {
  margin-top: 4px;
}

/* サイド：求人情報の埋め込み
----------------------------------------------- */
.contents_s .embed_job h2 {
  position: relative;
  height: 40px;
}

.contents_s .embed_job h2 a {
  color: #3D270F;
  text-decoration: none;
}

.contents_s .embed_job .body {
  padding: 10px 15px;
}

.contents_s .embed_job .body li {
  line-height: 1;
  padding: 0;
  background: transparent;
}

.contents_s .embed_job .body li a {
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
}

.contents_s .embed_job .body li a .img {
  width: 30%;
  padding: 0;
  position: relative;
}

.contents_s .embed_job .body li a .img > img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px;
}

.contents_s .embed_job .body li a .cate {
  width: 100%;
  padding: 0;
  margin: 0 0 8px;
  font-size: 10px;
  color: #3D270F;
}

.contents_s .embed_job .body li a .cate span {
  margin: 0 5px 0 0;
}

.contents_s .embed_job .body li a .title span {
  display: block;
}

.contents_s .embed_job .body li a .title .title_cname {
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #3D270F;
  margin: 0 0 5px;
}

.contents_s .embed_job .body li a .title .title_name {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.8;
}

.rank_number {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 10px;
  color: #fff;
  background: rgba(255, 155, 0, 0.8);
  border: 1px solid #fff;
  padding: 5px;
}

/* サイド：最近見た求人,検討中リスト,アクセスランキング
----------------------------------------------- */
.contents_s .embed_job.history .body,
.contents_s .embed_job.clip .body,
.contents_s .embed_job.rank .body {
  margin-top: 10px;
  padding: 0 16px 0;
}

.contents_s .embed_job.history .body li,
.contents_s .embed_job.clip .body li,
.contents_s .embed_job.rank .body li {
  line-height: 1;
  padding: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.contents_s .embed_job.history .body li + li,
.contents_s .embed_job.clip .body li + li,
.contents_s .embed_job.rank .body li + li {
  margin: 16px 0 0;
}

.contents_s .embed_job.history .body li a,
.contents_s .embed_job.clip .body li a,
.contents_s .embed_job.rank .body li a {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  text-decoration: none;
}

.contents_s .embed_job.history .body li a:hover,
.contents_s .embed_job.clip .body li a:hover,
.contents_s .embed_job.rank .body li a:hover {
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.contents_s .embed_job.history .body li a .img,
.contents_s .embed_job.clip .body li a .img,
.contents_s .embed_job.rank .body li a .img {
  width: 100%;
  padding: 0;
}

.contents_s .embed_job.history .body li a .img > img,
.contents_s .embed_job.clip .body li a .img > img,
.contents_s .embed_job.rank .body li a .img > img {
  object-fit: contain;
  width: 100%;
  height: 87.5px;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.contents_s .embed_job.history .body li a .cate,
.contents_s .embed_job.clip .body li a .cate,
.contents_s .embed_job.rank .body li a .cate {
  width: 100%;
  padding: 0;
  margin: 8px 0 0;
  font-size: 1.2rem;
  color: #3D270F;
  text-decoration: none;
}

.contents_s .embed_job.history .body li a .cate span,
.contents_s .embed_job.clip .body li a .cate span,
.contents_s .embed_job.rank .body li a .cate span {
  margin: 0 8px 0 0;
}

.contents_s .embed_job.history .body li a .title span,
.contents_s .embed_job.clip .body li a .title span,
.contents_s .embed_job.rank .body li a .title span {
  display: block;
}

.contents_s .embed_job.history .body li a .title .title_cname,
.contents_s .embed_job.clip .body li a .title .title_cname,
.contents_s .embed_job.rank .body li a .title .title_cname {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  color: #3D270F;
  margin: 8px 0 0;
}

.contents_s .embed_job.history .body li a .title .title_name,
.contents_s .embed_job.clip .body li a .title .title_name,
.contents_s .embed_job.rank .body li a .title .title_name {
  margin: 8px 0 0;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.48;
}

.contents_s .embed_job.history .body li a:hover .title_name,
.contents_s .embed_job.clip .body li a:hover .title_name,
.contents_s .embed_job.rank .body li a:hover .title_name {
  text-decoration: underline;
}

/* サイド：契約中のサービス
----------------------------------------------- */
.contents_s .service p.serviceButton {
  text-align: center;
  margin-top: 10px;
}

.contents_s .service p.serviceButton a {
  background: #333;
  color: #fff;
  text-decoration: none;
  padding: 5px 15px;
  font-weight: bold;
}

.contents_s.servicep.servicebuttona:hover {
  background: #111;
}

/* サイド：契約中のサービス
----------------------------------------------- */
.contents_s .recommend ul {
  border-bottom: 1px dotted #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/* 履歴書：希望勤務地(検索用)
----------------------------------------------- */
.addHopeList {
  width: 300px;
  position: relative;
  margin: 16px 0;
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  color: #828282;
}

.ha_del {
  position: absolute;
  top: 50%;
  right: 8px;
  padding: 2px 12px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.2rem;
  font-weight: 700;
  transform: translateY(-50%);
}

.ha_del:hover {
  background-color: #F2F2F2;
}

/* 地図から探す
----------------------------------------------- */
#foundCounter {
  float: right;
  margin: 5px;
}

#foundCounter span.count {
  font-size: 1.6rem;
  font-weight: 700;
}

#map_canvas {
  height: 500px;
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.infoWnd {
  height: 120px;
  line-height: 1.5;
  font-size: 1.2rem;
}

.infoWnd a {
  font-size: 1.2rem;
}

#pointlist ul.item_list {
  justify-content: space-between;
}

#pointlist ul.item_list::after {
  content: "";
  width: 224px;
}

#pointlist ul.item_list li {
  width: 224px;
  margin-bottom: 16px;
  padding: 16px;
}

#pointlist ul.item_list li + li {
  margin-left: 0;
}

#pointlist ul.item_list li:nth-child(n + 4) {
  display: block;
}

.googlemap {
  position: relative;
  margin-left: 16px;
  padding-left: 24px;
  background-image: url(../img/icon/map.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 16px 16px;
}

#mapForm select[name=adds],
#mapForm select[name=add_sub] {
  width: 140px;
}

#mapForm .map-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  padding: 0 16px 0 24px;
  background-color: #FF7E41;
  background-image: url(../img/icon/map03.svg);
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 16px 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
}

#mapForm .map-btn:hover {
  opacity: .8;
}

#mapForm .toggleBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  margin: 0 auto;
  padding: 0 16px 0 24px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
}

#mapForm .toggleBtn:hover {
  background-color: #FFF0DB;
}

#mapForm .toggleBtn svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

/* ================================================================================ */
/*                                                                                  */
/*      デザインテーマ                                                              */
/*                                                                                  */
/* ================================================================================ */
/* グリッド
----------------------------------------------- */
.cmslGrid {
  display: flex;
  flex-wrap: wrap;
}

.cmslGrid__col {
  width: 100%;
  box-sizing: border-box;
}

.cmslGrid__col--1 {
  width: 8.33333333333%;
}

.cmslGrid__col--2 {
  width: 16.6666666667%;
}

.cmslGrid__col--3 {
  width: 25%;
}

.cmslGrid__col--4 {
  width: 33.3333333333%;
}

.cmslGrid__col--5 {
  width: 41.6666666667%;
}

.cmslGrid__col--6 {
  width: 50%;
}

.cmslGrid__col--7 {
  width: 58.3333333333%;
}

.cmslGrid__col--8 {
  width: 66.6666666667%;
}

.cmslGrid__col--9 {
  width: 75%;
}

.cmslGrid__col--10 {
  width: 83.3333333333%;
}

.cmslGrid__col--11 {
  width: 91.6666666667%;
}

.cmslGrid__col--12 {
  width: 100%;
}

.cmslGrid--separate {
  margin-left: -10px;
  margin-right: -10px;
}

.cmslGrid--separate .cmslGrid__col {
  padding: 0 10px;
}

/* ブロック
----------------------------------------------- */
.cmslBlock {
  display: flex;
  flex-wrap: wrap;
}

.cmslBlock__ {
  width: 100%;
  box-sizing: border-box;
}

.cmslBlock--2 .cmslBlock__ {
  width: 50%;
}

.cmslBlock--3 .cmslBlock__ {
  width: 33.3333333333%;
}

.cmslBlock--4 .cmslBlock__ {
  width: 25%;
}

.cmslBlock--5 .cmslBlock__ {
  width: 20%;
}

.cmslBlock--separate {
  margin-left: -10px;
  margin-right: -10px;
}

.cmslBlock--separate .cmslBlock__ {
  padding: 0 10px;
}

/* エリアリスト
----------------------------------------------- */
.areaList {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.3em;
}

.areaList__area {
  width: 20%;
  padding: 0;
  margin: 0 0 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  font-size: 6px;
}

.areaList__prefecture {
  width: 80%;
  padding: 0 5px;
  box-sizing: border-box;
}

.areaList__prefecture ul {
  list-style: none;
  font-size: 0;
}

.areaList__prefecture ul li {
  display: inline-block;
  font-size: 6px;
}

.areaList__prefecture ul li a {
  display: inline-block;
  padding: 0 6px;
}

/* リスト
----------------------------------------------- */
.commonList {
  padding: 0 0 0 20px;
  margin: 0 0 20px;
}

.commonList .commonList {
  padding: 0 0 0 20px;
  margin: 0;
}

.commonList li {
  line-height: 1.6em;
  margin: 0 0 .4em;
  padding: 0 0 0 5px;
}

.commonList--circle li {
  list-style: circle;
}

/*----------------------------------------------------------------簡単検索*/
/* 簡単検索(グリッド)
----------------------------------------------- */
.formGrid {
  display: flex;
  flex-wrap: wrap;
}

.formGrid__col {
  width: 100%;
  box-sizing: border-box;
}

.formGrid__col--05 {
  width: 4.16666666666%;
}

.formGrid__col--1 {
  width: 8.33333333333%;
}

.formGrid__col--2 {
  width: 16.6666666667%;
}

.formGrid__col--3 {
  width: 25%;
}

.formGrid__col--4 {
  width: 33.3333333333%;
}

.formGrid__col--5 {
  width: 41.6666666667%;
}

.formGrid__col--6 {
  width: 50%;
}

.formGrid__col--7 {
  width: 58.3333333333%;
}

.formGrid__col--8 {
  width: 66.6666666667%;
}

.formGrid__col--9 {
  width: 75%;
}

.formGrid__col--10 {
  width: 83.3333333333%;
}

.formGrid__col--11 {
  width: 91.6666666667%;
}

.formGrid__col--12 {
  width: 100%;
}

.formGrid__col--free {
  width: auto;
  flex-grow: 1;
}

.formGrid--separate {
  margin-left: -10px;
  margin-right: -10px;
}

.formGrid--separate .formGrid__col {
  padding: 0 10px;
}

.formGrid--mt {
  margin-top: 15px;
}

/* 簡単検索
----------------------------------------------- */
.easySearchForm {
  background-color: #e5e5e5;
  background-image: url(../img/top/kv_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 240px;
}

.easySearchForm_heading {
  padding-top: 24px;
  font-size: 2.4rem;
  line-height: 1.8;
  text-align: center;
}

.easySearchForm_heading strong {
  color: #FF7E41;
}

.easySearchForm_heading span {
  font-size: 1.8rem;
}

.tp-search_form {
  width: 768px;
}

.easySearchForm__ {
  padding: 20px 0;
  margin: 0 auto;
  width: 1032px;
  box-sizing: border-box;
}

.easySearchForm__ form {
  width: 768px;
}

.easySearchForm__crossIcon {
  text-align: center;
}

.easySearchForm__crossIcon span {
  color: #000000;
  font-size: 1.6rem;
}

.easySearchForm__submit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 29px auto 0;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.easySearchForm__submit svg {
  content: " ";
  position: absolute;
  top: 50%;
  left: 109px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}

.easySearchForm__link__list {
  font-size: 0;
}

.easySearchForm__link__list li {
  display: inline-block;
  font-size: 14px;
  margin: 0 1em 1em 0;
}

.easySearchForm__link__list li a {
  color: #333;
}

.easySearchForm__title {
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 10px;
  color: #333;
}

/* stylishSelect */
.cmn-stylishSelect {
  position: relative;
  display: inline-block;
  min-width: 100px;
}

.cmn-stylishSelect .fa {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.cmn-stylishSelect select {
  width: 100%;
  height: 40px;
  appearance: none;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 1.6rem;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
}

.cmn-stylishSelect--w100 {
  width: 100%;
}

/* stylishBtn */
.bg-wrapMain {
  background-color: #FF7E41;
}

.bg-wrapMain:hover {
  opacity: .8;
}

.cmn-stylishBtn {
  appearance: none;
  display: block;
  padding: 0 .3em;
  width: 400px;
  height: 48px;
  background-color: #FF7E41;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.formGrid-Col_01 {
  width: 240px;
}

.formGrid-Col_01 select {
  padding: 0 32px;
}

.formGrid-Col_01 .ic-before {
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

.formGrid-Col_02 {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylishText */
.cmn-stylishText {
  height: 40px;
  width: 100%;
  border: 1px solid #333;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
}

.cmn-stylishTextWithBtn {
  display: flex;
}

.cmn-stylishTextWithBtn > .cmn-stylishText {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  flex-grow: 1;
}

.cmn-stylishTextWithBtn > .cmn-stylishBtn {
  width: 80px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* qj sample */
.qj-sampleBanner {
  margin: 0 0 10px;
}

.qj-topimage {
  width: 100%;
  min-width: 1032px;
  height: 300px;
  background-image: url("../img/qj_topimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  margin: -20px auto 0;
}

.qj-topimage__ {
  width: 1032px;
  height: 300px;
  margin: 0 auto;
  position: relative;
}

.qj-topimage__text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.qj-topimage + .easySearchForm {
  margin: 0 0 15px;
}

/* ヘッダー(共通)
----------------------------------------------- */
.qj-head {
  width: 1032px;
  margin: 0 auto;
  min-height: 70px;
  display: flex;
  flex-wrap: nowrap;
}

.qj-head__set {
  display: flex;
  align-items: center;
}

.qj-head__set__logo {
  margin: 0;
  display: flex;
  align-items: center;
}

.qj-head__set__logo a img {
  display: block;
  max-height: 70px;
  width: auto;
}

.qj-head__set__logoa:hover {
  opacity: .7;
}

.qj-head__set__switch {
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #ddd;
}

.qj-head__set__switch__btn {
  float: left;
}

.qj-head__set__switch__btn + .qj-head__set__switch__btn {
  border-left: 1px solid #ddd;
}

.qj-head__set__switch__btn a {
  display: inline-block;
  padding: 6px 12px;
  font-size: 12px;
  text-decoration: none;
  background: #ddd;
  color: #333;
}

.qj-head__set__switch__btna:hover {
  background: #fff;
}

.qj-head__set__switch__btn--fresh.active a {
  background: #fff;
}

.qj-head__set__switch__btn--mid.active a {
  background: #fff;
}

.qj-head__panel {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.qj-head__panel__menu {
  padding-bottom: 10px;
}

.qj-head__panel__free {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 13px 10px 0;
}

.qj-head__panel__free__hello {
  color: #3D270F;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: right;
}

.qj-head__panel__free__hello strong {
  margin: 0 3px 0 0;
}

.qj-head__panel__free__link {
  width: 100%;
  list-style: none;
  text-align: right;
  margin: 8px 0 0;
  font-size: 0;
}

.qj-head__panel__free__link__item {
  font-size: 1.2rem;
  display: inline-block;
  margin: 0 0 0 10px;
}

/* メニューパネル
----------------------------------------------- */
.qjMyMenuList {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
}

.qjMyMenuList__item {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 60px;
  border-left: 1px solid #6C6154;
}

.qjMyMenuList__item:last-of-type {
  border-right: 1px solid #6C6154;
}

.qjMyMenuList__item > a {
  display: block;
  width: 100%;
  color: #3D270F;
  text-decoration: none;
}

.qjMyMenuList__item > a:hover {
  background-color: #FFF0DB;
}

.qjMyMenuList__item__icon {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
  margin: 0 auto;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.qjMyMenuList__item__icon .fa {
  line-height: 30px;
  font-size: 20px;
  color: #666;
}

.qjMyMenuList__item__icon__cnt {
  position: absolute;
  top: 4px;
  right: 26px;
  width: 16px;
  height: 16px;
  background-color: #FF7E41;
  color: #ffffff;
  font-family: Roboto Condensed;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50%;
  padding: 1px;
}

.qjMyMenuList__item__name > span {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2em;
}

/* add トップ search Tab
----------------------------------------------- */
.tp-search_TabBtn {
  display: flex;
  align-items: center;
}

.tp-search_TabBtn .tab-recommend,
.tp-search_TabBtn .tab-new {
  position: relative;
  width: 384px;
  height: 64px;
  padding: 18px 0;
  background-color: #F2F2F2;
  border-radius: 4px 4px 0 0;
  color: #828282;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}

.tp-search_TabBtn .tab-recommend.isActive,
.tp-search_TabBtn .tab-new.isActive {
  background-color: #FF7E41;
  color: #FFFFFF;
}

.tp-search_TabBtn .tab-recommend svg,
.tp-search_TabBtn .tab-new svg {
  position: absolute;
  top: 48%;
  transform: translateY(-48%);
  fill: #828282;
}

.tp-search_TabBtn .tab-recommend svg {
  left: 108px;
}

.tp-search_TabBtn .tab-new svg {
  left: 128px;
}

.tp-search_TabBtn .tab-recommend.isActive svg,
.tp-search_TabBtn .tab-new.isActive svg {
  fill: #FFFFFF;
}

.tp-search_TabContent {
  padding: 32px;
  background-color: #FFD0B9;
}

.tp-search_TabContent > div {
  display: none;
}

.tp-search_TabContent > div.isActive {
  display: block;
}

.tp-search_Tab-contents-recommend .item_list,
.tp-search_Tab-contents-new .item_list {
  display: flex;
}

.tp-search_Tab-contents-recommend .item_list li,
.tp-search_Tab-contents-new .item_list li {
  display: flex;
  align-items: stretch;
  width: calc(100% / 3 - 6px);
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(255, 126, 65, 0.6);
}

.tp-search_Tab-contents-recommend .item_list li:hover,
.tp-search_Tab-contents-new .item_list li:hover {
  box-shadow: 0px 0px 12px #FF7E41;
}

.tp-search_Tab-contents-recommend .item_list li a,
.tp-search_Tab-contents-new .item_list li a {
  display: block;
  padding: 16px;
  text-decoration: none;
}

.tp-search_Tab-contents-recommend .item_list li a:nth-child(n + 4),
.tp-search_Tab-contents-new .item_list li a:nth-child(n + 4) {
  display: none;
}

.tp-search_Tab-contents-recommend .item_list li + li,
.tp-search_Tab-contents-new .item_list li + li {
  margin-left: 16px;
}

.tp-search_Tab-contents-recommend .item_list li img,
.tp-search_Tab-contents-new .item_list li img {
  object-fit: contain;
  width: 192px;
  height: 96px;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.tp-search_Tab-contents-recommend .item_list .cate,
.tp-search_Tab-contents-new .item_list .cate {
  margin-top: 8px;
  color: #3D270F;
  font-size: 1.2rem;
}

.tp-search_Tab-contents-recommend .item_list .title,
.tp-search_Tab-contents-new .item_list .title {
  margin-bottom: 0;
  padding: 0;
  border-left: none;
  color: #2382C7;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

.tp-search_Tab-contents-recommend .item_list li a:hover .title,
.tp-search_Tab-contents-new .item_list li a:hover .title {
  text-decoration: underline;
}

.tp-search_Tab-contents-recommend .item_list .title span,
.tp-search_Tab-contents-new .item_list .title span {
  display: none;
}

.moreBtn {
  margin-top: 32px;
}

.moreBtn a {
  position: relative;
  display: block;
  width: 320px;
  margin: 0 auto;
  padding: 14px 0;
  background-color: #FF7E41;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #fff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.moreBtn a:hover {
  opacity: .8;
}

.moreBtn a svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

/* add トップ tp-special
----------------------------------------------- */
.tp-special {
  position: relative;
  display: flex;
  margin-top: 72px;
  padding: 24px 24px 0 0;
  height: 218px;
  background-color: #96C6E8;
}

.tp-special .tp-special_heading {
  position: absolute;
  top: -34px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 159px;
  height: 79px;
  background-image: url(../img/top/special_bg.svg);
  background-repeat: no-repeat;
}

.tp-special .tp-special_heading h2 {
  margin-top: -18px;
  color: #ffffff;
  font-size: 1.6rem;
  text-align: center;
}

.tp-special .tp-special_heading h2 span {
  font-size: 2.4rem;
  font-family: "Roboto", sans-serif;
}

.tp-special > img {
  margin-top: -24px;
  margin-right: -34px;
}

.tp-special .tp-special_item {
  position: relative;
}

.tp-special .tp-special_item + .tp-special_item {
  margin-top: 4px;
}

.tp-special .tp-special_item a {
  display: flex;
  align-items: center;
  width: 580px;
  padding: 12px 48px 12px 16px;
  color: #2382C7;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.1);
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
}

.tp-special .tp-special_item a:hover {
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.2), rgba(150, 198, 232, 0.2)), #FFFFFF;
}

.tp-special .tp-special_item svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

/* add tp-read
----------------------------------------------- */
.tp-read {
  margin-top: 49px;
}

.tp-read_heading {
  position: relative;
  display: flex;
}

.tp-read_heading > img {
  position: absolute;
  bottom: 0;
}

.tp-read_heading h2 {
  position: relative;
  width: 100%;
  margin-left: 90px;
  padding: 10px 22px;
  background-color: #FFD0B9;
  border-radius: 6px;
  color: #3D270F;
  font-size: 2.2rem;
}

.tp-read_heading h2::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14.5px 15px 14.5px 0;
  border-color: transparent #FFD0B9 transparent transparent;
  transform: translateY(-50%);
}

.tp-read_txt {
  margin-top: 24px;
}

.tp-read_txt p {
  color: #3D270F;
  font-size: 1.6rem;
}

.tp-read_txt p + p {
  margin-top: 24px;
}

/* add トップ pickup
----------------------------------------------- */
.tp-pickup {
  margin-top: 40px;
  background-color: #96C6E8;
  border-radius: 4px;
  border-top: 8px solid #FF7E41;
}

.tp-pickup h2 {
  position: relative;
  padding: 12px 0;
  background: #2382C7;
  color: #ffffff;
  text-align: center;
  line-height: 1;
}

.tp-pickup h2::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 231px;
  height: 89px;
  background-image: url(../img/top/pickup_img.png);
  background-repeat: no-repeat;
  background-size: 231px 89px;
}

.tp-pickup h2 span {
  font-size: 3rem;
}

.tp-pickup_items {
  padding: 24px;
}

.tp-pickup_items li + li {
  margin-top: 16px;
}

.tp-pickup_item {
  position: relative;
  display: flex;
  padding: 24px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(35, 130, 199, 0.6);
  text-decoration: none;
}

.tp-pickup_item:hover {
  box-shadow: 0px 0px 12px #2382C7;
}

.tp-pickup_item::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 65px 65px 0;
  border-color: transparent #FFF0DB transparent transparent;
}

.tp-pickup_item::after {
  content: "check!";
  position: absolute;
  top: 12px;
  right: -5px;
  color: #FF7E41;
  font-family: Roboto;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  transform: rotate(45deg);
}

.tp-pickup_item_img {
  max-width: 175px;
}

.tp-pickup_item_img img {
  object-fit: contain;
  width: 175px;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.tp-pickup_item_txt {
  margin-left: 21px;
}

.tp-pickup_item_txt .cate {
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 700;
}

.tp-pickup_item_txt .cate span {
  font-size: 1.2rem;
  font-weight: 400;
}

.tp-pickup_item h3 {
  margin-top: 4px;
  margin-bottom: 0;
  padding: 0;
  border-left: none;
  color: #2382C7;
  font-size: 1.8rem;
}

.tp-pickup_item p {
  margin-top: 4px;
  color: #3D270F;
  font-size: 1.2rem;
}

.tp-pickup_item p.tp-pickup_item_linkBtn {
  position: relative;
  display: block;
  margin-top: 16px;
  padding: 12px 32px;
  color: #FF7E41;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.tp-pickup_item:hover p.tp-pickup_item_linkBtn {
  background-color: #FFF0DB;
}

.tp-pickup_item_linkBtn svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

/* add top インタビュー
----------------------------------------------- */
.contents_box.embed_interview.interview {
  margin: 40px 0 0;
  padding: 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
  border: none;
}

.contents_box.embed_interview.interview h2.ic-mic,
.contents_box.embed_interview.interview h3.ic-mic {
  position: relative;
  padding: 0;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.contents_box.embed_interview.interview h2.ic-mic::before {
  content: " ";
  position: absolute;
  top: 16%;
  left: 37%;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/mic.svg);
  background-repeat: no-repeat;
}

.contents_box.embed_interview.interview h2.ic-mic::after,
.contents_box.embed_interview.interview h3.ic-mic::after {
  content: none;
}

.contents_box.embed_interview.interview h3.ic-mic > svg {
  margin-right: 8px;
}

.contents_box.embed_interview.interview .body {
  padding-top: 16px;
}

.contents_box.embed_interview.interview .body ul li {
  overflow: initial;
  margin: 0;
}

.contents_box.embed_interview.interview .body ul li a {
  display: block;
  min-height: 212px;
  margin: 0;
  padding: 24px;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(35, 130, 199, 0.6);
  text-decoration: none;
}

.contents_box.embed_interview.interview .body ul li a:hover {
  box-shadow: 0px 0px 10px rgba(35, 130, 199, 0.6);
}

.contents_box.embed_interview.interview .body ul li a:hover .title,
.contents_box.embed_interview.interview .body ul li a:hover .text {
  text-decoration: underline;
}

.contents_box.embed_interview.interview .body ul li + li {
  margin-top: 8px;
}

.contents_box.embed_interview.interview .body ul li .img img {
  object-fit: contain;
  width: 200px;
  border: none;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.contents_box.embed_interview.interview .body ul li .title {
  margin: 8px 0 0;
  padding: 0;
  color: #2382C7;
  font-size: 1.6rem;
  border-left: none;
}

.contents_box.embed_interview.interview .body ul li .cate {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 1.2rem;
}

.contents_box.embed_interview.interview .body ul li .text {
  color: #3D270F;
}

.contents_box.embed_interview.interview .body ul li .text,
.contents_box.embed_interview.interview .body ul li .cate,
.contents_box.embed_interview.interview .body ul li .title {
  margin-left: 230px;
}

.contents_box.embed_interview.interview .body > .more {
  margin-top: 24px;
  padding: 0;
  background: initial;
  text-align: center;
}

.contents_box.embed_interview.interview .body > .more a {
  position: relative;
  display: block;
  width: 320px;
  margin: 0 auto;
  padding: 14px 0;
  background-color: #FF7E41;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
  color: #ffffff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.contents_box.embed_interview.interview .body > .more a:hover {
  opacity: .8;
}

.contents_box.embed_interview.interview .body > .more a svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

/* add tp-search
----------------------------------------------- */
.contents_m .contents_box.tp-search {
  margin-top: 40px;
  padding: 16px 32px 32px;
  background-color: #FFFFFF;
  border: 4px solid #FF8951;
  border-radius: 4px;
}

.contents_m .contents_box.tp-search h2.ic-search {
  position: relative;
  padding: 0;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.contents_m .contents_box.tp-search h2.ic-search::before {
  content: " ";
  position: absolute;
  top: 16%;
  left: 40%;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/search.svg);
  background-repeat: no-repeat;
}

.contents_m .contents_box.tp-search h2.ic-search::after {
  content: none;
}

.tp-search_contents {
  margin-top: 24px;
}

.tp-search_contents dt {
  position: relative;
  padding: 6px 12px 6px 36px;
  background-color: #FFF0DB;
  font-size: 1.8rem;
  font-weight: 700;
}

.tp-search_contents dt span {
  color: #FF7E41;
}

.tp-search_contents dt::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 12px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.tp-search_contents dt.ic-job::before {
  background-image: url(../img/icon/job.svg);
}

.tp-search_contents dt.ic-wifi::before {
  background-image: url(../img/icon/wifi.svg);
}

.tp-search_contents dt.ic-feature::before {
  background-image: url(../img/icon/feature.svg);
}

.tp-search_contents dt.ic-key::before {
  background-image: url(../img/icon/key.svg);
}

.tp-search_contents dd {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
}

.tp-search_contents dd label {
  position: relative;
  width: calc(100% / 3);
  margin-bottom: 10px;
  padding-left: 24px;
  color: #3D270F;
  cursor: pointer;
  font-size: 1.6rem;
}

.tp-search_contents dd label input[type=checkbox] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #F2F2F2;
  border-radius: 4px;
}

.tp-search_contents dd label input[type=checkbox]:checked {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.tp-search_contents dd label input[type=checkbox]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  width: 7px;
  height: 14px;
  border-radius: 4px;
  transform: rotate(40deg);
  border-bottom: 3px solid #3D270F;
  border-right: 3px solid #3D270F;
}

.tp-search_contents .button:hover {
  opacity: .8;
}

.tp-search_contents input[type="submit"].btn_search {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 48px;
  padding: 0 16px 0 24px;
  background: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
  font-weight: 700;
}

.tp-search_contents input[name=id],
.tp-search_contents input[name=free],
.tp-search_contents input[name=cuser_name],
.tp-search_contents input[name=label] {
  width: 100%;
  max-width: 100%;
  height: 40px;
  margin-top: 16px;
  padding: 8px 24px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
}

.tp-search_contents input[name=free]::placeholder {
  color: #828282;
}

.tp-search_contents .button {
  position: relative;
  width: 240px;
  height: 48px;
  margin: 24px auto 0;
  padding: 0;
}

.tp-search_contents .button svg {
  position: absolute;
  top: 50%;
  left: 30px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  z-index: 1;
}

/* add 求人一覧系
----------------------------------------------- */
.embed_job.pickup_job,
.embed_job.cuser_job,
.embed_job.alike_job,
.embed_job.wish_job,
.embed_job.limits,
.embed_job.other,
.embed_job.cuser_match,
.embed_user.cuser_match,
.embed_job.embed_area,
.embed_job.area_job {
  margin: 48px 0 0;
  padding: 32px;
  background-color: #FFF0DB;
  border: none;
}

.embed_job.pickup_job h2,
.embed_job.pickup_job h3,
.embed_job.cuser_job h2,
.embed_job.cuser_job h3,
.embed_job.alike_job h2,
.embed_job.alike_job h3,
.embed_job.wish_job h2,
.embed_job.wish_job h3,
.embed_job.limits h2,
.embed_job.limits h3,
.embed_job.other h2,
.embed_job.other h3,
.embed_job.cuser_match h2,
.embed_job.cuser_match h3,
.embed_user.cuser_match h2,
.embed_user.cuser_match h3,
.embed_job.embed_area h2,
.embed_job.embed_area h3,
.embed_job.area_job h2,
.embed_job.area_job h3 {
  position: relative;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.embed_job.pickup_job h3.title,
.embed_job.cuser_job h3.title,
.embed_job.alike_job h3.title,
.embed_job.wish_job h3.title,
.embed_job.limits h3.title,
.embed_job.other h3.title,
.embed_job.cuser_match h3.title,
.embed_user.cuser_match h3.title,
.embed_job.embed_area h3.title,
.embed_job.area_job h3.title {
  display: block;
}

.embed_job.pickup_job h2 svg,
.embed_job.pickup_job h3 svg,
.embed_job.cuser_job h2 svg,
.embed_job.cuser_job h3 svg,
.embed_job.alike_job h2 svg,
.embed_job.alike_job h3 svg,
.embed_job.wish_job h2 svg,
.embed_job.wish_job h3 svg,
.embed_job.limits h2 svg,
.embed_job.limits h3 svg,
.embed_job.other h2 svg,
.embed_job.other h3 svg,
.embed_job.cuser_match h2 svg,
.embed_job.cuser_match h3 svg,
.embed_user.cuser_match h2 svg,
.embed_user.cuser_match h3 svg,
.embed_job.embed_area h2 svg,
.embed_job.embed_area h3 svg,
.embed_job.area_job h2 svg,
.embed_job.area_job h3 svg {
  margin-right: 7px;
}

.embed_job.pickup_job h2.ic-company::before,
.embed_job.pickup_job h3.ic-company::before,
.embed_job.cuser_job h2.ic-company::before,
.embed_job.cuser_job h3.ic-company::before,
.embed_job.alike_job h2.ic-company::before,
.embed_job.alike_job h3.ic-company::before,
.embed_job.wish_job h2.ic-company::before,
.embed_job.wish_job h3.ic-company::before,
.embed_job.limits h2.ic-company::before,
.embed_job.limits h3.ic-company::before,
.embed_job.other h2.ic-company::before,
.embed_job.other h3.ic-company::before,
.embed_job.cuser_match h2.ic-company::before,
.embed_job.cuser_match h3.ic-company::before,
.embed_user.cuser_match h2.ic-company::before,
.embed_user.cuser_match h3.ic-company::before,
.embed_job.embed_area h2.ic-company::before,
.embed_job.embed_area h3.ic-company::before,
.embed_job.area_job h2.ic-company::before,
.embed_job.area_job h3.ic-company::before {
  content: " ";
  position: absolute;
  top: 16%;
  left: 34%;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/company.svg);
  background-repeat: no-repeat;
}

.embed_job.pickup_job h2::after,
.embed_job.pickup_job h3::after,
.embed_job.cuser_job h2::after,
.embed_job.cuser_job h3::after,
.embed_job.alike_job h2::after,
.embed_job.alike_job h3::after,
.embed_job.wish_job h2::after,
.embed_job.wish_job h3::after,
.embed_job.limits h2::after,
.embed_job.limits h3::after,
.embed_job.other h2::after,
.embed_job.other h3::after,
.embed_job.cuser_match h2::after,
.embed_job.cuser_match h3::after,
.embed_user.cuser_match h2::after,
.embed_user.cuser_match h3::after,
.embed_job.embed_area h2::after,
.embed_job.embed_area h3::after,
.embed_job.area_job h2::after,
.embed_job.area_job h3::after {
  content: none;
}

.embed_job.pickup_job .body > .more,
.embed_job.cuser_job .body > .more,
.embed_job.alike_job .body > .more,
.embed_job.wish_job .body > .more,
.embed_job.limits .body > .more,
.embed_job.other .body > .more,
.embed_job.cuser_match .body > .more,
.embed_user.cuser_match .body > .more,
.embed_job.embed_area .body > .more,
.embed_job.area_job .body > .more {
  background: initial;
  padding: 0;
}

.embed_job.pickup_job ul.item_list,
.embed_job.cuser_job ul.item_list,
.embed_job.alike_job ul.item_list,
.embed_job.wish_job ul.item_list,
.embed_job.limits ul.item_list,
.embed_job.other ul.item_list,
.embed_job.embed_area ul.item_list {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 16px;
}

.embed_job.pickup_job ul.item_list li,
.embed_job.cuser_job ul.item_list li,
.embed_job.alike_job ul.item_list li,
.embed_job.wish_job ul.item_list li,
.embed_job.limits ul.item_list li,
.embed_job.other ul.item_list li,
.embed_job.embed_area ul.item_list li {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% / 3 - 6px);
  padding: 0;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(255, 126, 65, 0.6);
}

.embed_job.pickup_job ul.item_list li:hover,
.embed_job.cuser_job ul.item_list li:hover,
.embed_job.alike_job ul.item_list li:hover,
.embed_job.wish_job ul.item_list li:hover,
.embed_job.limits ul.item_list li:hover,
.embed_job.other ul.item_list li:hover,
.embed_job.embed_area ul.item_list li:hover {
  box-shadow: 0 0 12px #FF7E41;
}

.embed_job.pickup_job ul.item_list li:nth-child(n + 4),
.embed_job.cuser_job ul.item_list li:nth-child(n + 4),
.embed_job.alike_job ul.item_list li:nth-child(n + 4),
.embed_job.wish_job ul.item_list li:nth-child(n + 4),
.embed_job.limits ul.item_list li:nth-child(n + 4),
.embed_job.other ul.item_list li:nth-child(n + 4),
.embed_job.embed_area ul.item_list li:nth-child(n + 4) {
  display: none;
}

.embed_job.pickup_job ul.item_list li > a,
.embed_job.cuser_job ul.item_list li > a,
.embed_job.alike_job ul.item_list li > a,
.embed_job.wish_job ul.item_list li > a,
.embed_job.limits ul.item_list li > a,
.embed_job.other ul.item_list li > a,
.embed_job.embed_area ul.item_list li > a {
  display: block;
  padding: 16px;
  text-decoration: none;
}

.embed_job.pickup_job ul.item_list li + li,
.embed_job.cuser_job ul.item_list li + li,
.embed_job.alike_job ul.item_list li + li,
.embed_job.wish_job ul.item_list li + li,
.embed_job.limits ul.item_list li + li,
.embed_job.other ul.item_list li + li,
.embed_job.embed_area ul.item_list li + li {
  margin-left: 16px;
}

.embed_job.pickup_job ul.item_list li img,
.embed_job.cuser_job ul.item_list li img,
.embed_job.alike_job ul.item_list li img,
.embed_job.wish_job ul.item_list li img,
.embed_job.limits ul.item_list li img,
.embed_job.other ul.item_list li img,
.embed_job.embed_area ul.item_list li img {
  object-fit: contain;
  width: 192px;
  height: 96px;
  font-family: "object-fit: contain;";
  /*IE対策*/
}

.embed_job.pickup_job ul.item_list .cate,
.embed_job.cuser_job ul.item_list .cate,
.embed_job.alike_job ul.item_list .cate,
.embed_job.wish_job ul.item_list .cate,
.embed_job.limits ul.item_list .cate,
.embed_job.other ul.item_list .cate,
.embed_job.embed_area ul.item_list .cate {
  margin-top: 8px;
  color: #3D270F;
  font-size: 1.2rem;
}

.embed_job.pickup_job ul.item_list .title,
.embed_job.cuser_job ul.item_list .title,
.embed_job.alike_job ul.item_list .title,
.embed_job.wish_job ul.item_list .title,
.embed_job.limits ul.item_list .title,
.embed_job.other ul.item_list .title,
.embed_job.embed_area ul.item_list .title {
  margin-bottom: 0;
  padding: 0;
  border-left: none;
  color: #2382C7;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: left;
}

.embed_job.pickup_job ul.item_list li > a:hover .title,
.embed_job.cuser_job ul.item_list li > a:hover .title,
.embed_job.alike_job ul.item_list li > a:hover .title,
.embed_job.wish_job ul.item_list li > a:hover .title,
.embed_job.limits ul.item_list li > a:hover .title,
.embed_job.other ul.item_list li > a:hover .title,
.embed_job.embed_area ul.item_list li > a:hover .title {
  text-decoration: underline;
}

.embed_job.pickup_job ul.item_list .title span,
.embed_job.cuser_job ul.item_list .title span,
.embed_job.alike_job ul.item_list .title span,
.embed_job.wish_job ul.item_list .title span,
.embed_job.limits ul.item_list .title span,
.embed_job.other ul.item_list .title span,
.embed_job.embed_area ul.item_list .title span {
  display: none;
}

.embed_job.other ul.item_list {
  flex-wrap: wrap;
}

.embed_job.limits .moreBtn a {
  padding: 14px 14px 14px 0;
}

.embed_job.limits .moreBtn a svg {
  right: 12px;
}

.pickup_job .more,
.alike_job .more,
.cuser_job .more,
.cuser_match .more,
.embed_area .more {
  margin-top: 32px;
}

.pickup_job .more a,
.cuser_job .more a,
.alike_job .more a,
.cuser_match .more a,
.embed_area .more a,
.gift-btn {
  position: relative;
  display: block;
  width: 320px;
  margin: 0 auto;
  padding: 12px 24px 12px 16px;
  background-color: #FF7E41;
  background-image: url(../img/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 16px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #fff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.pickup_job .more a:hover,
.cuser_job .more a:hover,
.alike_job .more a:hover,
.cuser_match .more a:hover,
.embed_area .more a:hover,
.gift-btn:hover {
  opacity: .8;
}

/* カスタム検索フォーム sch-from
----------------------------------------------- */
.contents_m .contents_box.top_search.sch-from {
  margin-top: 24px;
  padding: 16px 32px 32px;
  background-color: #FFFFFF;
  border: 4px solid #FF8951;
  border-radius: 4px;
}

.contents_m .contents_box.top_search.sch-from.mb-48 {
  margin-bottom: 48px;
}

.contents_m .contents_box.top_search.sch-from h3.ic-search {
  position: relative;
  float: none;
  padding: 0;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
  line-height: 1;
}

.contents_m .contents_box.top_search.sch-from h3.ic-search::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 28%;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon/search.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.contents_m .contents_box.top_search.sch-from h3.ic-search::after {
  content: none;
}

.sch-from_contents {
  margin-top: 34px;
}

.sch-from_contents dt {
  position: relative;
  padding: 6px 12px 6px 36px;
  background-color: #FFF0DB;
  font-size: 1.8rem;
  font-weight: 700;
}

.sch-from_contents dt span {
  color: #FF7E41;
}

.sch-from_contents dt::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 12px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.sch-from_contents dt.ic-job::before {
  background-image: url(../img/icon/job.svg);
}

.sch-from_contents dt.ic-wifi::before {
  background-image: url(../img/icon/wifi.svg);
}

.sch-from_contents dt.ic-feature::before {
  background-image: url(../img/icon/feature.svg);
}

.sch-from_contents dt.ic-key::before {
  background-image: url(../img/icon/key.svg);
}

.sch-from_contents dt.ic-company::before {
  background-image: url(../img/icon/company02.svg);
}

.sch-from_contents dt.ic-map::before {
  background-image: url(../img/icon/map.svg);
}

.sch-from_contents dt.ic-money::before {
  background-image: url(../img/icon/money.svg);
}

.sch-from_contents dt.ic-check::before {
  background-image: url(../img/icon/check.svg);
}

.sch-from_contents dd {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
}

.sch-from_contents dd label {
  position: relative;
  width: calc(100% / 3);
  margin-bottom: 10px;
  padding-left: 24px;
  color: #3D270F;
  cursor: pointer;
  font-size: 1.6rem;
}

.sch-from_contents dd label input[type=checkbox] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #F2F2F2;
  border-radius: 4px;
}

.sch-from_contents dd label input[type=checkbox]:checked {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.sch-from_contents dd label input[type=checkbox]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  width: 7px;
  height: 14px;
  border-radius: 4px;
  transform: rotate(40deg);
  border-bottom: 3px solid #3D270F;
  border-right: 3px solid #3D270F;
}

.sch-from_contents .button:hover {
  opacity: .8;
}

.sch-from_contents input[type="submit"].btn_search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 48px;
  padding: 0 16px 0 24px;
  background: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
  font-weight: 700;
}

.sch-from_contents input[type="submit"].btn_search:hover {
  opacity: 1;
}

.sch-from_contents input[name=free],
.sch-from_contents input[name=cuser_name] {
  width: 100%;
  max-width: 100%;
  height: 40px;
  margin-bottom: 16px;
  padding: 8px 24px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
}

.sch-from_contents input[name=free]::placeholder {
  color: #828282;
}

.sch-from_contents .button {
  position: relative;
  width: 240px;
  height: 48px;
  margin: 24px auto 0;
  padding: 0;
}

.sch-from_contents .button svg {
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  z-index: 1;
}

.sch-from_contents .d-flex_col {
  width: calc(100% / 2 - 12px);
}

.sch-from_contents .d-flex_col dd label {
  width: 100%;
}

.sch-from_contents select {
  width: 184px;
  height: 40px;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  margin: 0 16px 16px 0;
  padding: 0 24px 0 10px;
  font-size: 1.6rem;
  color: #828282;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
}

.sch-from_contents .d-flex_col select {
  width: 140px;
}

.sch-from_contents .d-flex_col select:last-of-type {
  margin-right: 4px;
}

.sch-from_contents .japan_select {
  position: relative;
}

.sch-from_contents .money_select {
  position: relative;
}

.sch-from_contents .d-flex_col .sub-txt {
  font-size: 1.6rem;
}

.sch-from_contents .salary_select {
  position: relative;
}

input[name=hope_salary] {
  margin: 0 16px;
}

input[name=salary] {
  margin: 0 16px;
}

input[name=gift_amount] {
  margin-right: 16px;
}

/* カスタム検索一覧
----------------------------------------------- */
.search-sort {
  margin: 48px 0 16px;
}

.search-sort .search_page p {
  line-height: 1;
}

.search-sort dl {
  position: relative;
  display: flex;
  margin-top: 24px;
}

.search-sort dt {
  position: absolute;
  bottom: 100%;
  color: #3D270F;
  font-size: 1.2rem;
}

.search-sort_num {
  width: 120px;
  margin-right: 24px;
  padding-top: 4px;
}

.search-sort_line {
  width: 204px;
  padding-top: 4px;
}

.search-sort a {
  display: inline-block;
  padding: 4px 6px;
  background-color: #F2F2F2;
  border-radius: 4px;
  color: #828282;
  font-size: 1.2rem;
  text-decoration: none;
}

.search-sort a:hover {
  background-color: #FF7E41;
  color: #FFFFFF;
}

.search-sort a.active {
  background-color: #FF7E41;
  color: #FFFFFF;
  pointer-events: none;
  cursor: initial;
}

.search-sort dd + dd {
  margin-left: 4px;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .search-sort dl dd a {
    min-width: 40px;
  }
  /* IE11 */
}

/* 固定ページカスタム
----------------------------------------------- */
.page-contents {
  margin-top: 16px;
  padding-bottom: 30px;
}

.page-contents_heading {
  position: relative;
  display: flex;
  width: 400px;
  margin: auto;
}

.page-contents_heading img {
  position: absolute;
  bottom: 0;
}

.page-contents_heading h4 {
  position: relative;
  width: 100%;
  margin-left: 90px;
  padding: 10px 22px;
  background-color: #FFD0B9;
  border-radius: 6px;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.page-contents_heading h4::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14.5px 15px 14.5px 0;
  border-color: transparent #FFD0B9 transparent transparent;
  transform: translateY(-50%);
}

.page-contents_head h3 {
  margin-bottom: 0;
  padding: 0;
  border-left: none;
  text-align: center;
}

.page-contents_head p {
  margin-top: 32px;
  padding: 0;
  text-align: center;
}

.page-contents_point, .page-contents_step, .page-contents_qa {
  margin-top: 48px;
}

.page-contents_step h4 {
  position: relative;
  background-color: #FFD0B9;
  border-radius: 6px;
}

.page-contents_qa h4 {
  position: relative;
  background-color: #FFD0B9;
  border-radius: 6px;
  color: #3D270F;
  font-size: 2.2rem;
  font-weight: 700;
}

.page-contents_foot {
  margin-top: 48px;
}

.page-contents_foot .page-entryBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 640px;
  height: 64px;
  margin: auto;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  font-size: 2.2rem;
  font-weight: 700;
  text-decoration: none;
}

.page-contents_foot .page-entryBtn:hover {
  opacity: .8;
}

.page-contents_foot .page-entryBtn svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.page-contents_foot .page-registBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 470px;
  height: 64px;
  margin: auto;
  padding-left: 24px;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  font-size: 2.2rem;
  font-weight: 700;
  text-decoration: none;
}

.page-contents_foot .page-registBtn:hover {
  opacity: .8;
}

.page-contents_foot .page-registBtn svg {
  position: absolute;
  top: 50%;
  left: 108px;
  transform: translateY(-50%);
}

.page-pointItems,
.page-stepItems,
.page-qaItems {
  margin-top: 32px;
}

.page-stepItem + .page-stepItem {
  margin-top: 40px;
}

.page-stepItem_head {
  position: relative;
  display: flex;
  height: 80px;
  align-items: center;
  background-color: #FFF0DB;
  font-size: 2.2rem;
  font-weight: 700;
}

.page-stepItem:not(:first-child) .page-stepItem_head::before {
  content: " ";
  position: absolute;
  top: -43%;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 16px;
  border-color: #FFD0B9 transparent transparent transparent;
  transform: translate(-50%, 43%);
}

.page-stepItem_head span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 80px;
  margin-right: 40px;
  background-color: #FFD0B9;
}

.page-stepItem_head .page-registBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 244px;
  height: 40px;
  margin: auto auto auto 40px;
  padding: 0 16px 0 24px;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
}

.page-stepItem_head .page-registBtn:hover {
  opacity: .8;
}

.page-stepItem_head .page-registBtn svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.page-stepItem_txt {
  margin-top: 16px;
}

.page-stepItem_txt strong {
  color: #FF7E41;
}

.page-stepItem_txt span {
  display: block;
  margin-top: 24px;
  font-size: 1.2rem;
}

.page-qaItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-qaItems_row {
  display: flex;
  justify-content: space-between;
}

.page-qaItems_row + .page-qaItems_row {
  margin-top: 40px;
}

.page-qaItems dl {
  width: calc(100% / 2 - 25px);
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
}

.page-qaItems dt {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 106px;
  padding: 0 24px 0 46px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px 4px 0 0;
  font-size: 22px;
  font-weight: 700;
}

.page-qaItems dt::before {
  position: absolute;
  top: 42%;
  left: 16px;
  content: "Q";
  color: #2382C7;
  font-family: Roboto;
  font-size: 3.2rem;
  font-weight: 700;
  transform: translateY(-42%);
}

.page-qaItems dd {
  position: relative;
  width: 100%;
  padding: 24px 24px 24px 46px;
  font-size: 1.8rem;
}

.page-qaItems dd::before {
  position: absolute;
  top: 14px;
  left: 16px;
  content: "A";
  color: #FF7E41;
  font-family: Roboto;
  font-size: 3.2rem;
  font-weight: 700;
}

.page-qaItems dd span {
  display: block;
  margin-top: 24px;
  font-size: 1.2rem;
  line-height: 1.6;
}

.page-pointItems {
  display: flex;
  justify-content: space-between;
}

.page-pointItem {
  width: calc(100% / 3 - 12px);
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
}

.page-pointItem_capHead {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  color: #2382C7;
  font-family: Roboto;
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
}

.page-pointItem_capHead span {
  margin-top: -15px;
  font-size: 5.5rem;
  line-height: 1;
}

.page-pointItem figure {
  margin: 0;
}

.page-pointItem figure img {
  width: 100%;
  margin-top: 20px;
  vertical-align: bottom;
}

.page-pointItem figcaption {
  padding: 0 24px;
  font-size: 1.8rem;
}

.page-pointItem figcaption strong {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 62px;
  margin-bottom: 16px;
  font-size: 2.6rem;
  line-height: 1.2;
}

.saiyo-pointItems {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.saiyo-pointItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% / 2 - 16px);
  height: 260px;
  background-color: #FFF0DB;
  border-radius: 4px;
  line-height: 1.48;
}

.saiyo-pointItem strong {
  font-size: 5.6rem;
  text-align: center;
}

.saiyo-pointItem_txtLg {
  color: #FB5555;
  font-family: Roboto;
  font-size: 13.6rem;
  line-height: 1;
}

.saiyo-pointItem_txtSm {
  font-size: 4.6rem;
}

.saiyo-pointItem_crMain {
  color: #FF7E41;
}

.saiyo-point_txt p {
  margin-top: 32px;
}

.saiyo-point_txt p span {
  color: #FF7E41;
}

.saiyo-point_txt_large {
  font-size: 3.9rem;
  text-align: center;
}

/* 固定ページ凡用
----------------------------------------------- */
.contents_l {
  width: 1032px;
  min-height: calc(100vh - 124px - 92px);
  margin: 0 auto;
  padding: 40px 0 84px;
}

.contents_l h2 {
  margin-top: 24px;
}

.contents_l h3 {
  margin-top: 16px;
}

.page_box {
  background: #fff;
  border: 1px solid rgba(54, 59, 64, 0.4);
  border-radius: 4px;
  padding: 24px;
  margin: 24px 0 0;
}

.page_box dl {
  margin-bottom: 30px;
}

.page_box dt {
  margin: 24px 0 10px;
}

.page_box p {
  margin: 0 0 16px;
}

.page_box ul {
  padding-left: 1em;
}

.page_box ul li {
  margin-bottom: 4px;
  list-style: disc;
}

.maintenance-body {
  background-color: #fffdf4;
}

.maintenance-wrap {
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;
  padding-top: 152px;
}

@media screen and (max-width: 768px) {
  .maintenance-wrap {
    padding: 76px 16px 0;
  }
}

.maintenance-wrap h1 {
  text-align: center;
}

.maintenance-wrap .maintenance-box {
  margin-top: 28px;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, 0.4);
  border-radius: 4px;
}

.maintenance-wrap .maintenance-box p + p {
  margin-top: 24px;
}

/* add 企業
----------------------------------------------- */
.p-16 {
  padding: 16px;
}

.mt-0 {
  margin-top: 0;
}

.contents_m .form_table .mt-0wrap select {
  margin-top: 0;
}

.mt-16 {
  margin-top: 16px;
}

.label-fix {
  top: -18px;
}

.cuser .contents_m .list .title h3 .pickup {
  left: 0;
}

table#business_hours {
  margin-top: 0;
}

.contents_m .button a.button {
  padding: 12px 0;
}

.contents_m .cuser_container .sch-detail .info_table th,
.contents_m .cuser_container .sch-detail .info_table td {
  padding: 16px;
}

.sch-from_contents .d-flex_col select[name=hope_job_category] {
  width: 184px;
}

select[name=resume_id] + a.btn-sub {
  position: relative;
  display: inline;
  width: fit-content;
  margin: 0 0 0 16px;
  padding: 12px 16px 12px;
  background-color: #FFFFFF;
  background-image: none;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  text-align: center;
  text-decoration: none;
}

select[name=resume_id] + a.btn-sub:hover {
  background-color: #FFF0DB;
}

.cuser .contents_box.item_info,
.cuser .contents_box.user_info,
.cuser .contents_box.pay_job {
  background: none;
  border: none;
}

.cuser .contents_box.item_info h2,
.cuser .contents_box.user_info h2,
.cuser .contents_box.pay_job h2 {
  padding: 0;
  border-bottom: none;
  font-size: 2.2rem;
}

.cuser .contents_box.item_info h2::after,
.cuser .contents_box.user_info h2::after,
.cuser .contents_box.pay_job h2::after {
  content: none;
}

.cuser .contents_box.new_nuser {
  margin-top: 40px;
  padding: 24px 24px 27px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border: none;
  border-radius: 4px;
}

.cuser .contents_box.new_nuser h2 {
  position: relative;
  padding: 0;
  border-bottom: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

.cuser .contents_box.new_nuser h2::after {
  content: none;
}

.cuser .contents_box.new_nuser .body {
  padding: 0;
}

.cuser .contents_box.new_nuser ul {
  margin-top: 16px;
}

.cuser .contents_box.new_nuser ul li a {
  position: relative;
  display: block;
  margin-bottom: 4px;
  padding: 12px 48px 12px 16px;
  color: #2382C7;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.1);
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none;
}

.cuser .contents_box.new_nuser ul li a:hover {
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.2), rgba(150, 198, 232, 0.2)), #FFFFFF;
}

.cuser .contents_box.new_nuser ul li a::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../img/icon/arrow/right-circle.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.cuser .contents_box.new_nuser ul li a svg {
  margin-right: 8px;
}

.cuser .contents_box.new_nuser ul li a > span {
  float: none;
}

.cuser .contents_box.new_nuser ul li p {
  color: #2382C7;
  font-weight: 700;
  text-decoration: none;
}

.cuser .contents_box.new_nuser .more {
  margin-top: 24px;
}

.cuser .contents_box.new_nuser .more a {
  position: relative;
  display: block;
  width: 320px;
  margin: 0 auto;
  padding: 14px 10px 14px 0;
  background-color: #ff7e41;
  color: #fff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
}

.cuser .contents_box.new_nuser .more a:hover {
  opacity: .8;
}

.cuser .contents_box.new_nuser .more a svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.contents_m .contents_box.top_search.sch-from._cuser .sch-from_contents .d-flex_col select {
  width: fit-content;
  min-width: 184px;
  margin: 0 16px 0 0;
}

.contents_m .contents_box.top_search.sch-from._cuser .sch-from_contents .d-flex_col select[name=_ageB] {
  margin-left: 16px;
}

.contents_m .contents_box.top_search.sch-from._cuser .sch-from_contents dd {
  align-items: center;
}

.contents_m .contents_box.top_search.sch-from._cuser input[name=_hope_salary] {
  margin: 0 16px;
}

.contents_m .contents_box.top_search.sch-from._cuser input[name=id],
.contents_m .contents_box.top_search.sch-from._cuser input[name=free],
.contents_m .contents_box.top_search.sch-from._cuser input[name=entry_user] {
  width: 100%;
  max-width: 100%;
}

.admin_info {
  margin-top: 24px;
  padding: 0 24px 0;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .cuser .admin_info th {
    min-width: auto;
    width: auto;
  }
  *::-ms-backdrop, .cuser .info_table th {
    min-width: auto;
    width: auto;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .cuser .admin_info td {
    min-width: auto;
    width: auto;
  }
  *::-ms-backdrop, .cuser .info_table td {
    min-width: auto;
    width: auto;
  }
}

.cuser .sch-resList .operation {
  width: 570px;
}

.cuser .sch-resList .operation .copy a,
.cuser .sch-resList .operation .edit a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 6px;
  border: 2px solid #ff7e41;
  color: #ff7e41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.cuser .sch-resList .operation .copy a:hover,
.cuser .sch-resList .operation .edit a:hover {
  background-color: #fff0db;
}

.cuser .sch-resList .operation .more a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  padding: 6px 12px;
  background-color: #ff7e41;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.cuser .sch-resList .operation .more a:hover {
  opacity: .8;
}

.cuser .sch-resList .operation .delete a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.cuser .sch-resList .operation .delete a:hover {
  background-color: #f2f2f2;
}

.contents_m table.interview-table {
  table-layout: fixed;
}

.contents_m table.interview-table th {
  width: 250px;
  padding-right: 54px;
}

table.interview-table textarea {
  width: 100%;
}

.bnr-box {
  margin-bottom: 24px;
}

.bnr-box a:hover {
  opacity: .8;
}

.cuser .contents_m .sch-resList .title {
  padding: 32px 24px 17px 24px;
}

.tp-wrap .contents_m ul.sub_menu {
  margin-top: 0;
}

.message.att {
  margin: 0 24px;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .cuser table.list_table {
    width: 100%;
    table-layout: fixed;
  }
}

.cuser .contents_m .sch-resList .info_table table td {
  width: 28%;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .cuser table.list_table th {
    min-width: auto;
    width: auto;
  }
}

.cuser .contents_s .embed_job.rank .body li {
  line-height: initial;
  padding: 0;
  background: none;
  border-radius: 0;
  box-shadow: none;
}

.cuser .info_table table th {
  padding: 16px;
}

.cuser #business_hours select {
  min-width: initial;
  margin-right: 16px;
}

.label-center label {
  top: -14px;
}
