@charset "UTF-8";

@import "../../_various.scss";

/* ================================================================================ */
/*                                                                                  */
/*      スタイル                                                                    */
/*                                                                                  */
/* ================================================================================ */

/* reset
----------------------------------------------- */

body,
div,
dl,
dt,
dd,
ul,
li,
ol,
li,
h1,
h2,
h3,
h4,
h5,
form,
p,
table,
th,
td,
address {
  margin: 0;
  padding: 0;
}

a img,
img {
  border: 0;
}

ul,
li,
ol,
li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

a {
  text-decoration: underline;
  color: #2382C7;
}

a:hover {
  text-decoration: none;
}

input[type="text"],
input[type="password"],
select,
textarea {
  padding: 10px 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  font-size: 1.6rem;
}

select::-ms-expand {
    display: none;
}

select,
select[name=birth_date_year],
select[name=birth_date_month],
select[name=birth_date_day] {
  display: inline-block;
  height: 40px;
  appearance: none;
  background-color: #ffffff;
  background-image: url(../img/icon/arrow/bottom.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  color: #828282;
  padding: 0 24px 0 10px;
  font-size: 1.6rem;
  box-shadow: 0 0 6px rgba(54, 59, 64, .4);
}

#contents select option {
  color: #3D270F;
}

input[name=add_ha] {
  display: inline-block;
  width: 240px;
  height: 40px;
  margin-left: 16px;
  padding: 6px 16px 6px 24px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/map02.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  &:hover {
    background-color: #FFF0DB;
  }
}

/* clearfix
----------------------------------------------- */

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* ベース
----------------------------------------------- */

html {
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  line-height: 1.48;
  color: #3D270F;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "Roboto", sans-serif;
}

@media all and (-ms-high-contrast: none) {
	body {
		font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "Roboto", sans-serif;
	}
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSans/NotoSans_Regular.woff2") format("woff2"),
    url("../fonts/NotoSans/NotoSans_regular.woff") format("woff"),
    url("../fonts/NotoSans/NotoSans_regular.ttf") format("truetype"),
    url("../fonts/NotoSans/NotoSans_regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: medium;
  font-weight: 500;
  src: url("../fonts/NotoSans/NotoSans_Medium.woff2") format("woff2"),
    url("../fonts/NotoSans/NotoSans_medium.woff") format("woff"),
    url("../fonts/NotoSans/NotoSans_medium.ttf") format("truetype"),
    url("../fonts/NotoSans/NotoSans_medium.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: bold;
  font-weight: 700;
  src: url("../fonts/NotoSans/NotoSans_Bold.woff2") format("woff2"),
    url("../fonts/NotoSans/NotoSans_bold.woff") format("woff"),
    url("../fonts/NotoSans/NotoSans_bold.ttf") format("truetype"),
    url("../fonts/NotoSans/NotoSans_bold.eot") format("embedded-opentype");
}

/* 汎用
----------------------------------------------- */

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.cancel {
  text-decoration: line-through;
}

.att {
  color: #FB5555;
  font-size: 1.2rem;
}

textarea {
  font-family: sans-serif;
  padding: 8px;
  line-height: 1.4;
}

label {
  position: relative;
  display: inline-block;
  padding-left: 24px;
}


input[type=radio] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1px;
  width: 16px;
  height: 16px;
  margin: 0;
  background-color: #F2F2F2;
  border-radius: 50%;
  transform: translateY(-50%);
  &:checked {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  &:checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #3D270F;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}

input[type=checkbox] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #F2F2F2;
  border-radius: 4px;
  &:checked {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  &:checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 5px;
    width: 7px;
    height: 14px;
    border-radius: 4px;
    transform: rotate(40deg);
    border-bottom: 3px solid #3D270F;
    border-right: 3px solid #3D270F;
  }
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.d-inline_block {
  display: inline-block;
}

// margin padding
.mt-0 {
  margin-top: 0;
}

@for $i from 1 through 25 {
  // top
  .mt-#{$i * 4} {
    margin-top: #{$i * 4}px;
  }
  .pt-#{$i * 4} {
    padding-top: #{$i * 4}px;
  }
  @media screen and (min-width: 768px) {
    .pc-mt-#{$i * 4} {
      margin-top: #{$i * 4}px;
    }
    .pc-pt-#{$i * 4} {
      padding-top: #{$i * 4}px;
    }
  }
  @media screen and (max-width: 767px) {
    .sp-mt-#{$i * 4} {
      margin-top: #{$i * 4}px;
    }
    .sp-pt-#{$i * 4} {
      padding-top: #{$i * 4}px;
    }
  }
  // right
  .mr-#{$i * 4} {
    margin-right: #{$i * 4}px;
  }
  .pr-#{$i * 4} {
    padding-right: #{$i * 4}px;
  }
  @media screen and (min-width: 768px) {
    .pc-mr-#{$i * 4} {
      margin-right: #{$i * 4}px;
    }
    .pc-pr-#{$i * 4} {
      padding-right: #{$i * 4}px;
    }
  }
  @media screen and (max-width: 767px) {
    .sp-mr-#{$i * 4} {
      margin-right: #{$i * 4}px;
    }
    .sp-pr-#{$i * 4} {
      padding-right: #{$i * 4}px;
    }
  }
  // bottom
  .mb-#{$i * 4} {
    margin-bottom: #{$i * 4}px;
  }
  .pb-#{$i * 4} {
    padding-bottom: #{$i * 4}px;
  }
  @media screen and (min-width: 768px) {
    .pc-mb-#{$i * 4} {
      margin-bottom: #{$i * 4}px;
    }
    .pc-pb-#{$i * 4} {
      padding-bottom: #{$i * 4}px;
    }
  }
  @media screen and (max-width: 767px) {
    .sp-mb#{$i * 4} {
      margin-bottom: #{$i * 4}px;
    }
    .sp-pb#{$i * 4} {
      padding-bottom: #{$i * 4}px;
    }
  }
  // left
  .ml-#{$i * 4} {
    margin-left: #{$i * 4}px;
  }
  .pl-#{$i * 4} {
    padding-left: #{$i * 4}px;
  }
  @media screen and (min-width: 768px) {
    .pc-ml-#{$i * 4} {
      margin-left: #{$i * 4}px;
    }
    .pc-pl-#{$i * 4} {
      padding-left: #{$i * 4}px;
    }
  }
  @media screen and (max-width: 767px) {
    .sp-ml#{$i * 4} {
      margin-left: #{$i * 4}px;
    }
    .sp-pl#{$i * 4} {
      padding-left: #{$i * 4}px;
    }
  }
}

/* ================================================================================ */
/*                                                                                  */
/*      レイアウト                                                                  */
/*                                                                                  */
/* ================================================================================ */

.head_inline {
  width: 1032px;
}

#menu ul {
  width: 1032px;
}

#contents {
  width: 100%;
  background: #FFFDF4;
  border-top: 1px solid #F2EFEB;
  margin: 0;
}

.contents_inline {
  width: 1032px;
  min-height: calc(100vh - 124px - 92px);
  margin: 0 auto;
  padding: 40px 0 84px;
}

/* 1カラム
----------------------------------------------- */

/* 2カラム
----------------------------------------------- */
.c2 .contents_m {
  float: left;
  width: 768px;
}

.c2 .contents_s {
  float: right;
  width: 240px;
  padding-top: 71px;
}

.tp-wrap .c2 .contents_s {
  padding-top: 0;
}

/* 3カラム
----------------------------------------------- */
.c3 {
  position: relative;
}

.c3 .contents_s2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 234px;
}

.c3 .contents_s {
  position: absolute;
  right: 0;
  top: 0;
  width: 234px;
}

.c3 .contents_m {
  margin: 0 250px;
}

/*----------------------------------------------------------------【汎用デザイン】*/

/* コンテンツ部：メイン
----------------------------------------------- */

.contents_m > .main > h2,
.contents_m > h2 {
  color: #3D270F;
  font-size: 3.2rem;
  // line-height: 47px;
}

.contents_m > .main > h2:first-child,
.contents_m > h2:first-child {
  margin: 0;
}

.contents_m h2 span.id {
  float: right;
  font-weight: normal;
  font-size: 10px;
  padding-top: 3px;
}

.contents_m h3 {
  color: #3d270f;
  font-size: 2.2rem;
  font-weight: 700;
}

.contents_m h3 span.more {
  padding-left: 10px;
  font-size: 12px;
}

.contents_m p {
  line-height: 150%;
}

.contents_m .annotation {
  margin: 0 0 10px 10px;
  font-size: 12px;
}

.contents_m .contents_box {
  background: #fff;
  border: 1px solid #ddd;
  margin: 0 0 20px;
}

.contents_m .contents_box h2 {
  position: relative;
  border: none;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.3;
  border-bottom: 3px solid #eee;
}

.contents_m .contents_box h2::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 50%;
  height: 3px;
  background: #666;
}

.contents_m .contents_box > h3 {
  position: relative;
  border: none;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.3;
  border-bottom: 3px solid #eee;
}

.contents_m .contents_box > h3::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 50%;
  height: 3px;
  background: #666;
}

// .contents_m .contents_box .body {
//   padding: 15px;
// }

// .contents_m .contents_box .body > .more {
//   background: #f5f5f5;
//   margin: 0 -15px -15px;
//   padding: 10px 15px;
//   text-align: right;
// }

// .contents_m .contents_box .body + .more {
//   background: #f5f5f5;
//   padding: 10px 15px;
//   text-align: right;
// }

.contents_m .contents_box .body .message_result_none {
  margin-top: 16px;
  background: transparent;
  text-align: center;
  color: #999;
}

.contents_m .contents_box .body .message_result_none + .more {
  display: none;
}

.th_notice {
  background: #ee3344;
  color: #fff;
}

/* ボタンセット */

.contents_m .button a.button,
.contents_m .button button,
.contents_m .button input[type="button"],
.contents_m .button input[type="submit"] {
  position: relative;
  display: inline-block;
  width: 240px;
  margin: 0;
  padding: 12px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
}

.contents_m .button input[type="button"] {
  padding: 12px 0;
}

.contents_m .button input[type="submit"].btn_send {
  padding: 12px 0;
}

.contents_m .button input[type="submit"].btn_search {
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/search02.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 16px;
  background-size: 16px;
}

.contents_m .button a.button._w320,
.contents_m .button .btn-main._w320,
.contents_m .button .btn_search._w320 {
  width: 320px;
}

.button-svgIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.contents_m .button a.button:hover,
.contents_m .button button:hover,
.contents_m .button input[type="button"]:hover,
.contents_m .button input[type="submit"]:hover {
  opacity: .8;
}

/* ページ送り */
.contents_m .search_page {
  margin: 20px 0;
}

.contents_m .search_page ul li a {
  background: #fff;
  border-radius: 2px;
}

.contents_m .search_page ul li.focus {
  background: #555;
  border: 1px solid #555;
  border-radius: 2px;
}

/* 汎用メッセージ */

.contents_m .message {
  background: #fff;
  border: 1px solid rgba(54, 59, 64, .4);
  border-radius: 4px;
  padding: 24px;
  margin: 24px 0 0;
}

.contents_m ul.registComp-links {
  margin-top: 40px;
  text-align: center;
  li {
    margin: auto;
    padding-left: 0;
    background-image: none;
  }
  li + li {
    margin-top: 16px;
  }
  a:hover {
    opacity: .8;
  }
  .mainBtn {
    position: relative;
    display: block;
    width: 240px;
    margin: 0 auto;
    padding: 10px 10px 10px 24px;
    background-color: #FF7E41;
    border-radius: 6px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 1.6rem;
    text-align: center;
    text-decoration: none;
  }
  .subBtn {
    position: relative;
    display: block;
    width: 240px;
    margin: 0 auto;
    padding: 10px 24px 10px 10px;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    font-size: 1.6rem;
    text-align: center;
    text-decoration: none;
  }
  .mainBtn svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
  .subBtn svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

.contents_m .description {
  margin: 24px 0;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, .4);
  border-radius: 4px;
}

.contents_m .error_message {
  border: 1px solid #ee3344;
  color: #ee3344;
}

.contents_m .main > .error {
  background: #fff;
  border: 1px solid #ee3344;
  border-radius: 2px;
  padding: 20px;
  margin: 10px 0;
  font-weight: normal;
}

.contents_m .attention {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 20px;
  margin: 10px 0;
}

/* 汎用メニュー */

// .contents_m ul.sub_menu {
//   margin: 20px 0;
// }

// .contents_m ul.sub_menu li a {
//   border-radius: 2px;
//   padding: 8px 16px;
//   border: none;
// }

// .contents_mul.sub_menulia:hover {
//   background: #333;
//   opacity: .7;
// }

/* サイドカラム
----------------------------------------------- */

.contents_s .contents_box {
  margin: 0 0 24px;
  padding-bottom: 16px;
  background: #FFF0DB;
  border-radius: 4px;
}

.contents_s .contents_box h2 {
  position: relative;
  padding: 16px 0 0 0 ;
  border: none;
  color: #3D270F;
  font-size: 1.8rem;
  text-align: center;
}

.contents_s .contents_box h2 .more {
  float: right;
  font-size: 12px;
}

.contents_s .contents_box .body {
  padding: 15px;
}

.contents_s .contents_box .body dl dd {
  margin-bottom: 8px;
}

.contents_s .contents_box .body dl dd input[type="text"] {
  width: 200px;
}

.contents_s .contents_box .body p.message,
.contents_s .contents_box .body p.more {
  text-align: center;
}

.contents_s .contents_box .body p.message a {
  display: block;
  padding: 12px 34px 12px 8px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/right-circle.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 16px;
  background-size: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, .4);
  color: #FB8080;
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
  text-align: left;
  &:hover {
    background-color: #FFF2F2;
  }
}

.contents_s .contents_box .body > p.more {
  text-align: right;
}

.contents_s .contents_box ul {
  margin: 0 0 10px;
}

.contents_s .contents_box ul li {
  padding-left: 10px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
  line-height: 150%;
}

.contents_s .contents_box ul li + li {
  margin-top: 5px;
}

.contents_s .contents_box p.button {
  text-align: center;
}

/* サイドカラム2
----------------------------------------------- */
.contents_s2 .contents_box {
  margin-bottom: 15px;
}

.contents_s2 .contents_box h2 {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  background: url(../img/style/h2_bk.gif) repeat-x center;
}

.contents_s2 .contents_box .body {
  padding: 10px;
  border: 1px solid #ccc;
  border-top: none;
}

.contents_s2 .contents_box .body dl dt {
  margin-bottom: 3px;
}

.contents_s2 .contents_box .body dl dd {
  margin-bottom: 8px;
}

.contents_s2 .contents_box .body dl dd input[type="text"] {
  width: 200px;
}

.contents_s2 .contents_box .body p {
  text-align: center;
  margin-top: 10px;
}

.contents_s2 .contents_box ul li {
  padding-left: 10px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
  line-height: 150%;
}

.contents_s2 .contents_box ul li + li {
  margin-top: 5px;
}

/* ================================================================================ */
/*                                                                                  */
/*      モジュール＆ステート                                                        */
/*                                                                                  */
/* ================================================================================ */

#super_user {
  width: 980px;
  margin: 0 auto;
}

#super_user a {
  display: block;
  padding: 5px;
  margin: 0 auto;
  text-align: center;
  width: 100px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  background-color: #555;
}

#super_usera:hover {
  background-color: #333;
}

.breadcrumb {
  margin-bottom: 10px;
}

/* ヘッダー(共通)
----------------------------------------------- */

#head {
  width: 100%;
  background: #fff;
}

.head_inline {
  min-height: 70px;
  margin: 0 auto;
  position: relative;
}

#head #logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#head #logo > a {
  display: block;
}

#head #logo > a > img {
  display: block;
  max-height: 70px;
  width: auto;
}

#head .switch {
  /* サイトのモード切替 */
  position: absolute;
  right: 0;
  top: -10px;
  margin: 0;
  overflow: hidden;
}

#head .switch li {
  float: left;
}

#head .switch li a {
  display: block;
  font-size: 12px;
  border: none;
  background: #eee;
  color: #000;
  margin: 0;
  padding: 6px 48px;
  text-decoration: none;
}

#head .switch li.active a {
  background: #666;
  color: #fff;
}

#head .switch li.hello {
  margin: 10px 10px 0 0;
}

#head .switch li.hello a {
  border: none;
  display: inline;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  background: transparent;
}

#head.switchli.helloa:hover {
  text-decoration: none;
}

#head .head_menu {
  position: absolute;
  right: 0;
  bottom: 5px;
}

#head .head_menu li {
  display: inline-block;
  margin: 0 0 0 15px;
}

#head.head_menulia:hover {
  opacity: .7;
}

#head .head_menu--nobody {
  /* 未ログイン時に追加で付与されるクラス */
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
}

#head .head_menu--nobody li {
  display: inline-block;
  margin-left: 5px;
}

#head .head_menu--nobody li a {
  display: block;
  padding: 7px 16px;
  border: 1px solid #666;
  border-radius: 2px;
  font-size: 12px;
  text-decoration: none;
}

#head .head_menu--nobody li.login a {
  background: #333;
  color: #fff;
  border: 1px solid #333;
}

#head .switch + .head_menu--nobody {
  /* サイトのモード切替、の直後にある、未ログイン時のメニュー */
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(0%);
}

p.hello {
  text-align: right;
}

/* カスタム共通ヘッダー
----------------------------------------------- */
.st-logo {
  max-width: 200px;
  img {
    max-width: 200px;
  }
}

.st-hdItem {
  &_Entry a,
  &_Login a {
    display: block;
    width: 100px;
    height: 56px;
    color: #3D270F;
    text-decoration: none;
  }
  &_Entry a {
    background-color: #FF7E41;
    &:hover {
      opacity: .8;
    }
  }
  &_Login a {
    &:hover {
      background-color: #FFF0DB;
    }
  }
  &_Entry {
    border-left: 1px solid #6C6154;
    border-right: 1px solid #6C6154;
    color: #fff;
  }
  &_Login {
    border-right: 1px solid #6C6154;
    color: #3D270F;
  }
  &_TxtEntry {
    padding-bottom: 2px;
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;
    line-height: 1.5;
  }
  &_TxtEntry .ic {
    display: inline;
    margin-left: 6px;
    padding: 2px 6px;
    background-color: #fff;
    border-radius: 4px;
    color: #FF7E41;
    font-size: 10px;
    line-height: 1;
  }
  &_TxtLogin {
    color: #3D270F;
    font-size: 1.2rem;
    line-height: 1.8;
    font-weight: 700;
    text-align: center;
  }
}

.st-hdIcon {
  position: relative;
  margin-bottom: -4px;
  padding-top: 8px;
  text-align: center;
}

/* グローバルナビゲーション(共通)
----------------------------------------------- */

#menu {
  background: #fff;
}

#menu > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1032px;
  margin: 0 auto;
}

#menu > ul > li > a {
  display: block;
  padding: 0;
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4;
  text-decoration: none;
}

#menu > ul > li > a:hover {
  color: #FF7E41;
}

#menu .addMainMenu {
  position: absolute;
  z-index: 80;
  background: #333;
  text-align: left;
  width: 170px;
}

#menu .addMainMenu ul {
  width: auto;
}

#menu .addMainMenu li {
  width: 100%;
}

#menu .addMainMenu ul li a {
  display: block;
  padding: 15px 25px;
  color: #fff;
  text-decoration: none;
}

#menu.addMainMenuullia:hover {
  background: #666;
}

#menu .parent_menu div {
  display: none;
}

/* フッター
----------------------------------------------- */

#foot {
  margin: 0;
  padding: 45px 0 9px;
  background-color: #fff;
}

#foot {
  position: relative;
  background-image: url("../img/common/footer_bg.png");
  background-repeat: repeat-x;
  background-size: 57px;
}

#foot .foot_inline .foot_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 798px;
  margin: auto;
  padding-top: 15px;
}

#foot #pagetop {
  position: absolute;
  right: 216px;
  bottom: 54px;
}

/* カスタム共通フッター
----------------------------------------------- */
#foot .st-ftMenu a {
  color: #3D270F;
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center;
  text-decoration: none;
  &:hover {
    color: #ff7e41;
  }
}


/* テーブル
----------------------------------------------- */

.contents_m table {
  width: 100%;
  line-height: 150%;
  margin-top: 42px;
  .interview-table {
    table-layout: fixed;
  }
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .contents_m table {
    table-layout: fixed;
  }
}

.contents_m table caption {
  padding-bottom: 16px;
  color: #3D270F;
  font-size: 2.2rem;
  font-weight: 700;
  .sub-caption {
    display: block;
    margin-top: 10px;
    color: #3D270F;
    font-size: 1.2rem;
    font-weight: 400;
  }
  .sub-caption .closed {
    margin-right: 6px;
  }
}

.contents_m table th {
  position: relative;
  width: 233px;
  padding: 16px;
  background-color: #FFF0DB;
  border: 1px solid #E0E0E0;
  color: #3D270F;
  font-size: 16px;
  font-weight: 700;
}

.contents_m table td{
  padding: 16px;
  background: #fff;
  border: 1px solid #e0e0e0;
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .cuser .contents_m table th {
    min-width: auto;
    width: auto;
  }
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .cuser .contents_m table td {
    min-width: auto;
    width: auto;
  }
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .cuser .contents_m table.job-info th {
    max-width: 200px;
    width: 200px;
  }
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .cuser .contents_m table.job-info td {
    min-width: auto;
    width: auto;
  }
}

.cuser .contents_m table.job-info td {
  padding: 16px;
  background: #fff;
  border: 1px solid #e0e0e0;
}

table.inquiry_form td{
  padding: 16px;
  background: #fff;
  border: 1px solid #e0e0e0;
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .contents_m table td {
    max-width: calc(100% - 233px);
  }
}

.contents_m table td.ta_center {
  text-align: center;
}

.contents_m table td.ta_right {
  text-align: right;
}

.contents_m table td div[class^="label"] {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0 0;
}

.contents_m table td div[class^="label"] label {
  margin: 0 0 5px;
}

.contents_m table td .label2 label {
  /*label 2列*/
  width: 50%;
}

.contents_m table td .label3 label {
  /*label 3列*/
  width: 33%;
}

.contents_m table td .label4 label {
  /*label 4列*/
  width: 25%;
}

.contents_m table td .label5 label {
  /*label 5列*/
  width: 20%;
}

.contents_m table td .label6 label {
  /*label 6列*/
  width: 16%;
}

.contents_m table td .label7 label {
  /*label 7列*/
  width: 14%;
}

input[type="checkbox"] {
  // vertical-align: middle;
  margin-right: 5px;
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 2px;
}

/* 検索テーブル（search_table）
----------------------------------------------- */
.contents_m .search_table input[type="text"] {
  max-width: 200px;
}

.contents_m .search_table div[class^="label"] {
  display: flex;
  flex-wrap: wrap;
}

.contents_m .search_table .label3 label {
  width: 50%;
  margin: 0 0 5px;
}

.contents_m .search_table .label4 label {
  width: 50%;
  margin: 0 0 5px;
}

.contents_m .search_table .label5 label {
  width: 33.33%;
  margin: 0 0 5px;
}

/* 検索結果一覧テーブル（list_table）
----------------------------------------------- */
.contents_m .list_table th {
  padding: 8px;
  border-left: 1px dotted #ddd;
  border-right: 1px dotted #ddd;
}

.contents_m .list_table td {
  padding: 16px 8px;
  border-left: 1px dotted #ddd;
  border-right: 1px dotted #ddd;
  > label {
    display: block;
    width: fit-content;
    margin: auto;
  }
}

.contents_m .list_table tr.odd td {
  background-color: #fafafa;
}

/* 操作アイコン */
.contents_m .list_table .operation {
  text-align: center;
  line-height: 1;
}

.contents_m .list_table .operation li {
  display: inline-block;
  line-height: 1;
}

.contents_m .list_table .operation li + li {
  margin-left: 8px;
}

.contents_m .list_table .operation li a {
  display: inline-block;
  width: 80px;
  height: 40px;
  padding: 10px 12px 10px 16px;
  background-color: #FFFFFF;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  text-align: right;
}

/*編集*/
.contents_m .list_table .operation li.edit a {
  border: 2px solid #FF7E41;
  color: #FF7E41;
  background-image: url(../img/icon/entry02.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: top 50% left 12px;
  &:hover {
    background-color: #FFF0DB;
  }
}

/*詳細*/
.contents_m .list_table .operation li.more a {
  border: 2px solid #FF7E41;
  color: #FF7E41;
  background-image: url(../img/icon/note02.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: top 50% left 12px;
  &:hover {
    background-color: #FFF0DB;
  }
}

.contents_m .list_table .operation li.more a.ic-mail {
  background-image: url(../img/icon/mail02.svg);
}

/*削除*/
.contents_m .list_table .operation li.delete a {
  background-image: url(../img/icon/close02.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 16px 16px;
  border: 2px solid rgba(54, 59, 64, .4);
  color: rgba(54, 59, 64, .4);
  &:hover {
    background-color: #F2F2F2;
  }
}

/*メッセージ応募*/
.btn-msgEntry {
  min-width: 80px;
  height: 40px;
  padding: 8px 12px 8px 28px;
  background-color: #FF7E41;
  background-image: url(../img/icon/entry.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 16px 16px;
  border-radius: 6px;
  border: none;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
  &:hover {
    opacity: .8;
  }
}

/*メッセージスカウト辞退*/
.btn-msgScout {
  min-width: 80px;
  height: 40px;
  padding: 6px 12px 6px 28px;
  font-size: 1.6rem;
  font-weight: 700;
  background-image: url(../img/icon/close02.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 16px 16px;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, .4);
  color: rgba(54, 59, 64, .4);
  &:hover {
    background-color: #F2F2F2;
  }
}

.contents_m .all_operation {
  margin: 16px 0;
}

/* 詳細ページテーブル（info_table）
----------------------------------------------- */
.info_table {
  margin-top: 24px;
  padding: 40px 34px;
  table {
    color: #3D270F;
    font-size: 1.6rem;
    font-weight: 400;
  }
  table > div {
    margin-top: 8px;
  }
  table th,
  table td {
    border: 1px solid #E0E0E0;
  }
  table th {
    position: relative;
    width: 150px;
    padding: 8px 8px 8px 38px;
    background-color: #FFF0DB;
    font-weight: 700;
  }
  td {
    padding: 8px 8px 8px 26px ;
  }
}

.info_table .cuser_container {
  table th {
    padding: 16px;
  }
}

/* 入力フォームテーブル（form_table）
----------------------------------------------- */
.contents_m .form_table th .requisite {
  position: absolute;
  top: 50%;
  right: 16px;
  padding: 4px 6px;
  background-color: #FB5555;
  border-radius: 4px;
  color: #ffffff;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1;
  transform: translateY(-50%);
}

.contents_m .form_table td .hint {
  color: #3D270F;
  display: block;
  margin-bottom: 16px;
  font-size: 1.2rem;
  font-weight: 400;
}

.contents_m .form_table .closed {
  margin-left: 8px;
  padding: 4px 6px;
  background: rgba(54, 59, 64, .4);
  border-radius: 4px;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 400;
}

.contents_m .form_table td select + select {
  margin-left: 16px;
}

.contents_m .form_table td.validate {
  input[type=text]:required,
  input[type=text]:invalid,
  input[type=mail]:invalid,
  input[type=password]:invalid,
  select:invalid {
    background-color: #FFF2F2;
    box-shadow: 0px 0px 6px #FB5555;
  }
}

.contents_m .form_table td.validate .error {
  /*個別*/
  color: #FB5555;
  margin-top: 5px;
  font-size: 1.2rem;
}

.contents_m .main > .error {
  /*総括*/
  color: #FB5555;
  font-weight: bold;
  margin-bottom: 10px;
}

.contents_m .form_table input[type="text"]:focus,
.contents_m .form_table textarea:focus,
.contents_m .form_table select:focus {
  border: 1px solid #0099ff;
}

.contents_m .form_table input[type="text"],
.contents_m .form_table input[type="text"]:valid,
.contents_m .form_table textarea,
.contents_m .form_table select,
.contents_m .form_table select[type="text"]:valid {
  padding: 10px 8px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  border: 1px solid #fff;
  font-size: 1.6rem;
}

.contents_m .login_table,
.contents_m .form_table {
  select {
    min-width: 180px;
    height: 40px;
    margin-top: 24px;
    padding: 0 24px 0 10px;
    appearance: none;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(54, 59, 64, .4);
    color: #828282;
    font-size: 1.6rem;
  }
  .select-wrap {
    position: relative;
  }
  input[name=zip1] {
    margin-right: 6px;
  }
  .zip-mark {
    margin-right: 8px;
    font-size: 1.6rem;
  }
  .addressInput01 input {
    max-width: 180px;
  }
  .addressInput02 input {
    max-width: 300px;
  }
  .addressLabel {
    display: block;
    margin-top: 16px;
    color: #3D270F;
    font-size: 1.2rem;
  }
  input[type=password],
  input[type=password]:valid {
    height: 40px;
    max-width: 180px;
    padding: 6px 8px;
    background-color: #fff;
    border: #fff;
    border-radius: 4px;
    font-size: 1.6rem;
  }
  input[type=password]::placeholder {
    color: #828282;
  }
  .zip-inputBtn {
    position: relative;
  }
  .zip-inputBtn input {
    position: relative;
    display: inline-block;
    margin-left: 16px;
    padding: 6px 16px 6px 36px;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    font-size: 1.6rem;
    font-weight: 700;
    &:hover {
      background-color: #FFF0DB;
    }
  }
  .zip-inputBtn::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 31px;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/search.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
    z-index: 1;
  }
  label + label {
    margin-left: 16px;
  }
  input[type="submit"].btn_check {
    width: 240px;
    padding: 12px 24px 12px 16px;
    background-color: #FF7E41;
    background-image: url(../img/icon/arrow/right-circle-sub.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 16px;
    background-size: 16px;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
  }
  input[type="submit"].btn_check._w400 {
    width: 400px;
  }
  input[type="submit"].btn_back {
    margin-bottom: 16px;
    padding: 12px 16px 12px 24px;
    background-color: #FFFFFF;
    background-image: url(../img/icon/arrow/left-circle-back.svg);
    background-repeat: no-repeat;
    background-position: top 50% left 16px;
    background-size: 16px;
    border: 2px solid rgba(54, 59, 64, .4);
    border-radius: 6px;
    color: rgba(54, 59, 64, .4);
    font-size: 1.6rem;
    &:hover {
      background-color: #F2F2F2;
    }
  }
  .button {
    position: relative;
    margin: 40px auto 0;
  }
  .button > input + input {
    margin-left: 16px;
  }
  .button svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.check_table {
  .button {
    position: relative;
    margin: 40px auto 0;
  }
  input[type="submit"].btn_back {
    width: 120px;
    margin-right: 24px;
    background: #FFFFFF;
    border: 2px solid rgba(54, 59, 64, .4);
    box-shadow: none;
    color: rgba(54, 59, 64, .4);
    &:hover {
      background-color: #F2F2F2;
    }
  }
  .btn_back-wrap,
  .btn_regist-wrap {
    position: relative;
  }
  .btn_back-wrap svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    z-index: 1;
  }
  .btn_regist-wrap svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.contents_m .form_table textarea {
  font-family: sans-serif;
  font-size: 1.6rem;
}

.contents_m .form_table td .hint-noneWrap {
  .hint {
    display: none;
  }
  a {
    position: relative;
    display: inline-block;
    width: 160px;
    height: 40px;
    margin-left: 16px;
    padding: 10px 0 10px 20px;
    background-color: #fff;
    border: 2px solid #ff7e41;
    border-radius: 6px;
    color: #ff7e41;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    &:hover {
      background-color: #FFF0DB;
    }
  }
  a::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../img/icon/mail02.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.contents_m .form_table td .hint-noneWrap.wide a{
  width: 200px;
}

// mail受信チェックボタン
input[name=mail] + a,
input[name=notice_mail] + a,
input[name=sub_mail] + a,
input[name=sub_mail2] + a {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 40px;
  margin-left: 16px;
  padding: 10px 0 10px 34px;
  background-color: #fff;
  border: 2px solid #ff7e41;
  border-radius: 6px;
  color: #ff7e41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  &:hover {
    background-color: #FFF0DB;
  }
  &::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../img/icon/mail02.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.hint-wrap .hint {
  margin-top: 16px;
}

.contents_m .form_table .select-wrap_m-16 {
  select,
  input {
    margin: 0 16px;
  }
  select {
    width: 128px;
    min-width: initial;
  }
}

/* 確認フォームテーブル（check_table）
----------------------------------------------- */

/* ログインフォームテーブル（login_table）
----------------------------------------------- */

.login_table {
  input[name=mail],
  input[name=passwd] {
    max-width: 240px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
  }
  input[name=passwd] + label {
    margin-left: 16px;
  }
  .hint {
    display: block;
    margin-top: 8px;
    font-size: 1.2rem;
  }
}

/* ボタン
----------------------------------------------- */

.contents_m .button {
  margin: 40px auto 0;
  text-align: center;
}

.contents_m .button input {
  margin: 0 10px 0 0;
  padding: 3px 10px;
}

.btn-main {
  position: relative;
  display: inline-block;
  width: 240px;
  margin: auto;
  padding: 12px 24px 12px 16px;
  background-color: #FF7E41;
  background-image: url(../img/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    opacity: .8;
  }
  &._note {
    padding: 12px 16px 12px 24px;
    background-image: url(../img/icon/note03.svg);
    background-repeat: no-repeat;
    background-position: center left 16px;
  }
  &.ml-16 {
    margin-left: 16px;
  }
}

.contents_m .button .btn-main._mail {
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/mail03.svg);
  background-position: center left 16px;
}

.contents_m .button .btn-main._mail + .btn-main._mail {
  margin-left: 16px;
}

.contents_m .button .btn-main._note {
  padding: 12px 16px 12px 24px;
  background-image: url(../img/icon/note03.svg);
  background-position: center left 16px;
}

.btn-mainBack {
  position: relative;
  display: inline-block;
  width: 240px;
  margin: auto;
  padding: 12px 16px 12px 24px;
  background-color: #FF7E41;
  background-image: url(../img/icon/arrow/left-circle-white.svg);
  background-repeat: no-repeat;
  background-position: center left 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    opacity: .8;
  }
}

.contents_m .button input[type="button"].btn-sub,
.btn-sub {
  position: relative;
  display: block;
  width: 240px;
  margin: auto;
  padding: 12px 24px 12px 16px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/right-circle-main.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: #FFF0DB;
  }
  &._w320 {
    width: 320px;
    padding: 12px 16px;
    background-image: none;
  }
}

.sch-from_contents .button._wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 320px;
  height: auto;
  margin: 24px auto 0;
  padding: 0;
  &:hover {
    opacity: 1;
  }
}

.btn-main + .btn-sub,
.btn-main + .btn-main,
.btn-sub + .btn-sub {
  margin-top: 16px;
}

#contents .contents_m .form_table input[type="submit"].btn_back {
  box-shadow: none;
}

.button {
  &._right {
    margin: 16px 0 0 auto;
  }
}

textarea[name=hope_work_place_label] + button,
.contents_m .button input[type="button"].btn-border {
  position: relative;
  display: block;
  width: 240px;
  margin: 16px auto 0;
  padding: 10px 16px 10px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
  &._inline {
    display: inline-block;
    margin-left: 16px;
  }
  &._w320 {
    width: 320px;
  }
  &._right {
    margin: 16px 0 0 auto;
  }
  &:hover {
    background-color: #FFF0DB;
  }
}

input[type=button],
button.btn-border, {
  position: relative;
  display: block;
  // width: 240px;
  margin: 16px 0 0;
  padding: 8px 16px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
  &._inline {
    display: inline-block;
    margin-left: 16px;
  }
  &._w320 {
    width: 320px;
  }
  &:hover {
    background-color: #FFF0DB;
  }
}

.btn-unsubscribe,
.contents_m .button input[type="submit"].btn-unsubscribe {
  position: relative;
  display: block;
  width: 240px;
  margin: auto;
  padding: 10px 24px 10px 16px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/right-circle-gray.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  box-shadow: none;
  border: 2px solid rgba(54, 59, 64, .4);
  color: rgba(54, 59, 64, .4);
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: #F2F2F2;
  }
}

/* 画面メッセージ
----------------------------------------------- */
.contents_m .message {
  padding: 24px;
}

.contents_m .message ul {
  margin-top: 20px;
}

.contents_m .message ul li {
  padding-left: 10px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
}

.contents_m .message p {
  margin-bottom: 10px;
}

/* エラーメッセージ
----------------------------------------------- */
.contents_m .error_message {
  margin-top: 28px;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid #FB5555;
  border-radius: 4px;
  color: #FB5555;
  font-size: 1.6rem;
}

.contents_m .error_message ul {
  margin-top: 20px;
}

.contents_m .error_message ul li {
  padding-left: 10px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
}

.contents_m .error_message p {
  margin-bottom: 10px;
}

/* ページャー
----------------------------------------------- */
.contents_m .search_page {
  margin: 16px 0 0;
}

.contents_m .search_page p {
  color: #3D270F;
  font-size: 1.2rem;
  span {
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 700;
  }
}

.contents_m .search_page ul {
  margin: 24px auto 0;
  text-align: center;
}

.contents_m .search_page ul li {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  line-height: 100%;
  &.page {
    font-family: Roboto;
  }
  &.back {
    margin-right: 16px;
  }
  &.next {
    margin-right: 0;
    margin-left: 8px;
  }
  &.back a,
  &.next a {
    padding: 8px 24px 8px 16px;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    &:hover {
      background-color: #FFF0DB;
    }
  }
  &.back a {
    padding: 8px 16px 8px 24px;
  }
  &.back a svg {
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
  }
  &.next a svg {
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);
  }
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .contents_m .search_page ul li  {
    margin-right: 4px;
  } /* IE11 */
}

.contents_m .search_page ul li.focus {
  padding: 8px 11.42px;
  background: #FF7E41;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-family: Roboto;
  font-weight: 700;
}

.contents_m .search_page ul li a {
  display: inline-block;
  padding: 8px 11.42px;;
  background: #F2F2F2;
  border-radius: 4px;
  color: #828282;
  font-weight: 700;
  text-decoration: none;
}

.contents_m.search_pageullia:hover {
  background-color: #eee;
}

/* sub_menu
----------------------------------------------- */

.contents_m ul.sub_menu {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 0;
  &.center {
    text-align: center;
  }
}

.contents_m ul.sub_menu li {
  display: inline-block;
  background: none;
  // margin-left: 7px;
}

.contents_m ul.sub_menu li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 176px;
  height: 40px;
  padding: 2px 0 2px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: #FFF0DB;
  }
  &.ic-job {
    padding: 2px 0 2px 24px;
    background-image: url(../img/icon/job02.svg);
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 16px 16px;
  }
  &.ic-map {
    padding: 2px 0 2px 24px;
    background-image: url(../img/icon/map02.svg);
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 16px 16px;
  }
  &.ic-edit {
    width: 128px;
    padding: 12px 16px 12px 24px;
    background-image: url(../img/icon/entry02.svg);
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 16px 16px;
    font-size: 1.6rem;
  }
  &.ic-remove {
    width: 128px;
    padding: 12px 16px 12px 24px;
    background-image: url(../img/icon/close.svg);
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 16px 16px;
    font-size: 1.6rem;
  }
}

.contents_m ul.sub_menu li a.font-large {
  font-size: 1.6rem;
}

.contents_mul.sub_menuli a:hover {
  background-color: #111;
}

.contents_m ul.sub_menu li.back a {
  // display: inline;
  padding: 12px 16px 12px 24px;
  background-color: #FFFFFF;
  background-image: url(../img/icon/arrow/left-circle-back.svg);
  background-repeat: no-repeat;
  background-position: 8px center;
  background-size: 12px 12px;
  border: 2px solid rgba(54, 59, 64, .4);
  border-radius: 6px;
  color: rgba(54, 59, 64, .4);
  &:hover {
    background-color: #F2F2F2;
  }
}

/* ソーシャルボタン
----------------------------------------------- */

#social {
  margin: 45px 0 20px;
  text-align: right;
  a:hover {
    opacity: .8;
  }
  > div {
    display: inline-block;
  }
  > div + div {
    margin-left: 16px;
  }
}

.sns-hatena {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../img/common/sns_b.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

.sns-twitter {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../img/common/sns_twi.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

.sns-facebook {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../img/common/sns_fb.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

.sns-line {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../img/common/sns_line.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

/* サイトからのお知らせ
----------------------------------------------- */

.contents_m .system_news .date {
  text-align: right;
}

.contents_m .system_news ul {
  padding: 0 10px;
}

.contents_m .system_news ul li {
  margin-bottom: 5px;
  background: url(../img/style/list_icon.gif) no-repeat left 5px;
  padding-left: 10px;
}

/* ニュース
----------------------------------------------- */
.contents_box.news {
  margin-top: 40px;
  padding: 24px 24px 27px;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border: none;
  border-radius: 4px;
  h2 {
    position: relative;
    padding: 0;
    border-bottom: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h2::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 40%;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/news.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  h2::after {
    content: none;
  }
  .body {
    padding: 0;
  }
  ul {
    margin-top: 16px;
  }
  ul li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding: 12px 48px 12px 16px;
    color: #2382C7;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(54, 59, 64, .1);
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      background: linear-gradient(0deg, rgba(150, 198, 232, .2), rgba(150, 198, 232, .2)), #FFFFFF;
    }
  }
  ul li::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../img/icon/arrow/right-circle.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  ul li > span {
    float: none;
  }
  ul li > p {
    margin-left: 24px;
  }
  ul li a {
    color: #2382C7;
    font-weight: 700;
    text-decoration: none;
  }
  .more {
    display: none;
  }
}

.contents_m .news .body ul {
  padding: 0 10px;
}

.contents_m .news .body ul li {
  margin-bottom: 8px;
  zoom: 1;
}

.contents_m.news.bodyulli:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
}

.contents_m .news .body ul li span {
  float: left;
  width: 80px;
}

.contents_m .news .body ul li p {
  margin-left: 36px;
}

/* ニュース本文
----------------------------------------------- */
.contents_m .news_text {
  margin-top: 28px;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, .4);
  border-radius: 4px;
}

.contents_m .news_text p {
  margin-bottom: 16px;
}

/* ページ本文
----------------------------------------------- */
.contents_m .page_text {
  margin-top: 24px;
  padding: 40px;
  background: #fff;
  font-size: 13px;
  line-height: 1.4em;
}

.contents_m .page_text h3 {
  font-size: 16px;
  font-weight: bold;
  border: none;
  padding: .5em 1em;
  margin: 0 -1em 1em;
  background: #555;
  color: #fff;
}

.contents_m .page_text h4 {
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding: .5em 1em;
  margin: 0 0 1em;
  background: #eee;
  color: #333;
}

.contents_m .page_text ul {
  margin: 1em 0 2em 2em;
}

.contents_m .page_text ul li {
  list-style: disc outside;
  margin: 0 0 .5em;
}

.contents_m .page_text ol {
  margin: 1em 0 2em 2em;
}

.contents_m .page_text ol li {
  list-style: desimal outside;
  margin: 0 0 .5em;
}

.contents_m .page_text p {
  margin: 0 0 2em;
}

.contents_m .page_text .info_table {
  padding: 0 20px;
}

.qj-qanda {
  margin: 20px 20px 30px;
}

.qj-qanda dl {
  margin: 0 0 20px;
}

.qj-qanda dl dt {
  position: relative;
  padding: 5px 0 0 40px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.3em;
}

.qj-qanda dl dd {
  position: relative;
  padding: 7px 0 20px 40px;
  margin: 0 0 20px;
  border-bottom: 1px dashed #bbb;
}

.qj-qanda dl dd::after,
.qj-qanda dl dt::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 2px;
}

.qj-qanda dl dt::after {
  content: "Q";
  background-color: #2382c7;
}
.qj-qanda dl dd::after {
  content: "A";
  background-color: #ff7e41;
}

.qj-pageBtn {
  text-align: center;
  margin: 0 0 20px;
}

.qj-pageBtn a {
  display: inline-block;
  padding: 1em 4em;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
  text-decoration: none;
  line-height: 1.3em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .15);
}

.qj-pageBtn a + a {
  margin: 0 0 0 .8em;
}

.qj-pagebtna:hover {
  opacity: .7;
  color: #fff;
}

/* 求人情報(embed)
----------------------------------------------- */

.contents_m .embed_job .body ul {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.contents_m .embed_job .body li {
  margin: 0;
  padding: 5px 10px 10px;
  width: 20%;
  box-sizing: border-box;
}

.contents_m .embed_job .body .img {
  margin: 0 0 5px;
}

.contents_m .embed_job .body .img img {
  object-fit: contain;
  width: 192px;
  height: 96px;
  font-family: "object-fit: contain;";   /*IE対策*/
}

.contents_m .embed_job .body .cate {
  font-size: 10px;
  margin: 0 0 5px;
  display: block;
}

.contents_m .embed_job .body .cate .new {
  color: red;
  text-transform: uppercase;
}

.contents_m .embed_job .body .cate .limits {
  color: red;
}

.contents_m .embed_job .body h4,
.contents_m .embed_job .body h3 {
  font-weight: normal;
  font-size: 12px;
  border: none;
  padding: 0;
  margin: 0 0 5px;
}

.contents_m .embed_job .body h4 span,
.contents_m .embed_job .body h3 span {
  display: block;
  font-size: 10px;
}

.contents_m .embed_job .body h4 span br,
.contents_m .embed_job .body h3 span br {
  display: none;
}

/* おすすめ求人一覧
----------------------------------------------- */

/* 新着求人一覧
----------------------------------------------- */
.contents_box.embed_user {
  margin-top: 40px;
  padding: 24px 28px;
  background-color: #FFF0DB;
  border-radius: 4px;
  border: none;
  h2 {
    position: relative;
    padding: 0;
    border: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h2.ic-company::before {
    content: " ";
    position: absolute;
    top: 16%;
    left: 40%;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/company.svg);
    background-repeat: no-repeat;
  }
  h2::after {
    content: none;
  }
  .body ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
  }
  .body li {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% / 4 - 8px);
    margin: 16px 0 0;
    padding: 0;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(255, 126, 65, .6);
    &:hover {
      box-shadow: 0 0 12px #FF7E41;
    }
  }
  .body li a {
    display: block;
    padding: 16px;
    text-decoration: none;
  }
  .body .img img {
    border: none;
  }
  .cate {
    display: none;
  }
  .title {
    color: #2382C7;
    font-size: 1.2rem;
    line-height: 1.48;
  }
  .title a {
    color: #2382C7;
    text-decoration: none;
  }
  .body li a:hover .title {
    text-decoration: underline;
  }
  .text {
    margin-top: 8px;
    line-height: 1.48;
  }
}

/* 締め切り間近の求人
----------------------------------------------- */

/* この企業の求人
----------------------------------------------- */

/* この求人と似た求人
----------------------------------------------- */
/* この求人に応募した人は
----------------------------------------------- */

/* 県内のおすすめ求人(企業詳細ページ下部)
----------------------------------------------- */

/* 新着の求人企業
----------------------------------------------- */
/* この企業を見た人はこんな企業も見ています
----------------------------------------------- */

// .contents_m .embed_user .body ul {
//   margin: 0;
//   display: flex;
//   flex-wrap: wrap;
// }

// .contents_m .embed_user .body li {
//   margin: 0;
//   padding: 0;
//   width: 166px;
// }

.contents_m .embed_user .body .img img {
  width: 100%;
  height: auto;
}

.contents_m .embed_user .body h3 span {
  display: block;
}

/* 新着の求職者情報
----------------------------------------------- */

.contents_m .new_nuser .body ul {
  margin: 0 0 15px;
}

.contents_m .new_nuser .body ul li + li {
  margin: 10px 0 0;
}

/* メイン：◯◯から探す
----------------------------------------------- */

.contents_m .items_type .body li {
  display: inline-block;
  margin: 5px;
}

/* メイン：勤務形態から探す
----------------------------------------------- */

.contents_m .workstyleType .body ul {
  display: flex;
  flex-wrap: wrap;
}

.contents_m .workstyleType .body ul li {
  width: 20%;
  margin: 5px 0;
}

/* メイン：職種から探す
----------------------------------------------- */

.contents_m .jobType .body ul {
  display: flex;
  flex-wrap: wrap;
}

.contents_m .jobType .body ul li {
  width: 50%;
  margin: 5px 0;
}

/* メイン：特徴から探す
----------------------------------------------- */

.contents_m .featuresType .body ul {
  display: flex;
  flex-wrap: wrap;
}

.contents_m .featuresType .body ul li {
  width: 25%;
  margin: 5px 0;
}

/* メイン：地域から探す
----------------------------------------------- */
.areaType .body .foreign > ul > li,
.areaType .body .prefectures > ul > li {
  display: block;
  margin: 0;
  overflow: hidden;
}

.areaType .body .foreign > ul > li > .area_name,
.areaType .body .prefectures > ul > li > .area_name {
  border: none;
  margin: 0;
  float: left;
  padding: 5px 0;
  width: 110px;
  font-weight: bold;
}

/* メイン：フリーワード検索
----------------------------------------------- */

.contents_m .free .body {
  text-align: center;
}

.contents_m .free .body input[type="text"] {
  padding: 0 10px;
  margin: 0 0 0 10px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
}

.contents_m .free .body .search_button,
.contents_m .free .body input[type="submit"] {
  position: relative;
  width: 240px;
  padding: 10px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.contents_m .free .body .search_button:hover,
.contents_m .free .body input[type="submit"]:hover {
  opacity: .8;
}

.contents_m .free .body label > span {
  display: inline-block;
  height: 35px;
  line-height: 35px;
}

/* 並び替え・表示件数
----------------------------------------------- */

.contents_m .sort {
  margin: 15px 0;
}

.contents_m .sort dt {
  display: inline-block;
  margin: 0;
  padding: 0 5px 0 0;
  color: #999;
}

.contents_m .sort dd {
  display: inline-block;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 0 10px 0 8px;
}

.contents_m .sort dd + dd {
  display: inline-block;
  border-left: 0;
}

.contents_m .sort dl {
  float: left;
}

.contents_m .sort dl + dl {
  float: right;
}

/*----------------------------------------------------------------【求人検索結果】*/

/* 検索フォーム部
----------------------------------------------- */

.contents_m .top_search .ttl {
  position: relative;
}

.contents_m .top_search .ttl h3 {
  position: relative;
  margin: 0 auto;
  padding: 0;
  border: none;
  font-size: 2.2rem;
  text-align: center;
  svg {
    position: relative;
    top: 2px;
    margin-right: 8px;
  }
}

.contents_m .top_search .ttl .sub_menu {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
}

.contents_m .top_search .button {
  background: transparent;
}

.contents_m .top_search .search_table table {
  margin: 0;
}

.contents_m .search_table {
  select {
    display: inline-block;
    height: 40px;
    appearance: none;
    background-color: #ffffff;
    background-image: url(../img/icon/arrow/bottom.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 8px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    padding: 0 24px 0 10px;
    font-size: 1.6rem;
    color: #828282;
    box-shadow: 0 0 6px rgba(54, 59, 64, .4);
  }
  select + select {
    margin-left: 16px;
  }
}

/* 求人情報
----------------------------------------------- */

.job_container {
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 0 0 32px;
}

.job_container .stop {
  color: red;
  padding: 10px 7px;
  border: 1px solid red;
  margin: 0 0 10px;
  line-height: 1.5;
}

.job_container .date {
  text-align: right;
  margin: 0 0 10px;
  color: #999;
  font-size: 12px;
}

.job_container .photo {
  border: 1px solid #ccc;
  padding: 2px;
  float: left;
}

.job_container .photo .frame {
  padding: 5px;
  margin: 0 0 10px 0;
  border: 1px solid #eee;
  background: url(../../img/admin/grad_top_white_50.gif) repeat-x top;
}

.job_container .photo .catchcopy {
  padding: 0 0 5px 0;
  text-align: left;
  line-height: 1.6;
}

.job_container .jobtext .icon {
  overflow: hidden;
  margin: 0 0 15px;
  padding: 0;
}

.job_container .jobtext .icon li {
  float: left;
  margin: 0 5px 5px 0;
  white-space: nowrap;
}

.job_container .jobtext .icon li a {
  display: block;
  font-size: 12px;
  padding: 5px 10px;
  border: 1px solid #999;
  border-radius: 2px;
  text-decoration: none;
}

.job_container .jobtext p {
  line-height: 1.48;
  margin: 24px 0 0;
}

// .job_container .box {
//   margin: 0 0 10px;
//   overflow: hidden;
// }

.job_container .button {
  margin: 16px 0 0;
}

.job_container .button a {
  display: inline-block;
  padding: 8px 0;
  width: 180px;
  margin: 0 2px;
  background-color: #666;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border-radius: 2px;
}

.job_container .button a.not {
  background-color: #aaa;
}

.job_container.buttona:hover {
  background-color: #333;
}

.job_container .item_img {
  margin: 15px 0;
  display: flex;
}

.job_container .item_img li {
  width: 33.33%;
  padding: 10px;
}

.job_container .item_img li + li {
  margin: 0;
}

#contents .job_container .item_img li p.img {
  height: auto;
  margin: 0 0 -24px;
  text-align: center;
}

.job_container .item_img li p.img img {
  width: 100%;
  height: auto;
}

.job_container .item_img li p.img span {
  display: inline-block;
  line-height: 165px;
  color: #999;
}

.job_container .item_img li p.text {
  padding: 5px;
  font-size: 12px;
}

// .job_container table td,
// .job_container table th {
//   padding: 5px 8px;
// }

/* 求人リスト
----------------------------------------------- */

.contents_m .list {
  position: relative;
  background: #fff;
  margin: 0 0 15px 0;
  border: 1px solid #ccc;
  padding: 28px 16px;
}

.contents_m .list.list_pickup {
  position: relative;
}

.contents_m .list.list_pickup::before {
  content: "PICKUP";
  position: absolute;
  top: 0;
  left: 0;
  background: #2382C7;
  border-radius: 4px 0px 0px 0px;
  color: #fff;
  margin-right: 4px;
  padding: 2px 10px;
  font-family: Roboto;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  z-index: 1;
}

.contents_m .list .title {
  line-height: 1.5;
  margin-bottom: 10px;
}

.contents_m .list .title h3 {
  font-size: 14px;
  font-weight: bold;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.contents_m .list .title h3 span.cate {
  color: #666;
  font-weight: normal;
  font-size: 12px;
  display: block;
}

.contents_m .list .title h3 .pickup {
  position: absolute;
  top: 0;
  left: 82px;
  background: #FF7E41;
  color: #fff;
  padding: 2px 10px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  &._right-0 {
    left: 0;
    border-radius: 4px 0 0 0;
  }
}

.contents_m .list .title h3 .pickup.history {
  left: 0;
}

.contents_m .list .title h3 .limitation {
  background-color: #333;
  color: #fff;
  font-size: 10px;
  margin: 3px 0 0 3px;
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  padding: 0 3px;
}

.contents_m .list .title h3 a {
  color: #333;
}

.contents_m .list .title p.gift {
  position: absolute;
  top: 0;
  right: 24px;
  min-width: 96px;
  padding: 20px 16px;
  background-color: #FB5555;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  &::before {
    position: absolute;
    top: 100%;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 49px 0 0;
    border-color: #FB5555 transparent transparent transparent;
  }
  &::after {
    position: absolute;
    top: 100%;
    right: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 49px 22px 0;
    border-color: transparent #FB5555 transparent transparent;
  }
}

.contents_m .list .title p.gift span {
  font-family: Roboto;
  font-size: 1.8rem;
}

.contents_m .list .title p.gift .small,
.contents_m .sch-detail p.gift span.small {
  font-size: 1.2rem;
}

.contents_m .list .job_container {
  border: none;
  padding: 0;
}

.contents_m .list .job_container p.limits {
  text-align: center;
  font-weight: bold;
  color: #ff3333;
  margin-bottom: 5px;
}

.contents_m .list .job_container .jobtext .icon li a {
  font-size: 10px;
  padding: 2px 5px;
}

.contents_m .list .job_container .photo + .jobtext {
  margin: 0 0 0 150px;
}

.contents_m .list .job_container .button {
  margin: 24px 0 0;
}

.contents_m .list .job_container table {
  margin: 0 0 10px;
}

.contents_m .list .job_container .jobtext p {
  margin: 0 0 10px;
}

.contents_m .list .job_container .jobtext .icon {
  margin: 0;
}

/* カスタム求人リスト
----------------------------------------------- */

.contents_m .sch-resList {
  .att {
    padding: 0 24px;
  }
  .list {
    margin: 40px 0 0;
    padding: 0;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  .title {
    position: relative;
    padding: 32px 143px 17px 24px;
    border-radius: 4px 4px 0px 0px;
    display: flex;
  }
  .list.list_pickup .title {
    padding: 32px 143px 17px 24px;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 4px 4px 0px 0px;
  }
  .title h3 a {
    color: #2382C7;
    font-size: 2.2rem;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .title h3 span.cate {
    color: #3D270F;
    font-size: 1.6rem;
  }
  .job_container {
    margin: 0;
    padding: 0 0 24px;
  }
  .job_container .photo {
    float: none;
    width: 600px;
    margin: 16px auto 0;
    border: none;
    text-align: center;
  }
  .job_container .photo img {
    object-fit: contain;
    width: 600px;
    height: 300px;
    font-family: "object-fit: contain;";   /*IE対策*/
  }
  .job_container .photo + .jobtext {
    margin: 24px 0 0;
    padding: 0 24px 0;
    color: #3D270F;
    font-size: 1.6rem;
  }
  .job_container .jobtext .icon li a {
    position: relative;
    padding: 3px 8px 3px 26px;
    color: #2382C7;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 16px 0px 0px 16px;
    border: none;
    font-size: 1.2rem;
  }
  .job_container .jobtext .icon li a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  .info_table {
    margin-top: 24px;
    padding: 0 24px 0;
  }
  .info_table + .info_table {
    margin-top: 0;
    border-top: none;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 7px;
    color: #3D270F;
    font-size: 1.6rem;
    font-weight: 400;
  }
  table > div {
    margin-top: 8px;
  }
  table th,
  table td {
    border: none;
  }
  table th {
    position: relative;
    width: 150px;
    padding: 8px 8px 8px 38px;
    background-color: #FFF0DB;
    font-weight: 400;
  }
  th::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  th.ic-job::before {
    background-image: url(../img/icon/job02.svg);
  }
  th.ic-wifi::before {
    background-image: url(../img/icon/wifi02.svg);
  }
  th.ic-company::before {
    background-image: url(../img/icon/company.svg);
  }
  th.ic-map::before {
    background-image: url(../img/icon/map02.svg);
  }
  td {
    padding-left: 26px;
  }
  .job_container .button {
    background-color: #FFFFFF;
    border-top: 1px solid #E0E0E0;
    padding: 16px 24px 0;
  }
  .btn_clip {
    position: relative;
    width: 240px;
    padding: 10px 16px 10px 24px;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    font-size: 16px;
    text-align: center;
    &:hover {
      background-color: #FFF0DB;
    }
  }
  .btn_clip::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../img/icon/add.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  .btn_clip.not {
    position: relative;
    width: 240px;
    padding: 10px 16px 10px 24px;
    background-color: #FFFFFF;
    background-image: url(../img/icon/close02.svg);
    background-repeat: no-repeat;
    background-position: center left 16px;
    background-size: 16px 16px;
    border-radius: 6px;
    border: 2px solid rgba(54, 59, 64, .4);
    color: rgba(54, 59, 64, .4);
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: #F2F2F2;
    }
  }
  .btn_clip.not::before {
    content: none;
  }
  .btn_more {
    position: relative;
    width: 240px;
    padding: 12px 24px 12px 16px;
    background-color: #FF7E41;
    background-image: url(../img/icon/arrow/right-circle-sub.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 16px;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    &:hover {
      opacity: .8;
    }
  }
  .btn_more svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

/* 求人の詳細
----------------------------------------------- */

.contents_m .job_detail {
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px;
}

.contents_m .job_detail .job_container {
  border: none;
  padding: 0;
}

.contents_m .job_detail h2.job_h2 span.cate {
  display: block;
  font-weight: normal;
  font-size: 12px;
  margin: 0 0 5px;
}

.contents_m .job_detail h2.job_h2 span.pickup {
  position: absolute;
  top: 0;
  left: 0;
  background: #FF7E41;
  border-radius: 4px 0 0 0;
  color: #fff;
  padding: 2px 10px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.contents_m .job_detail h2.job_h2 span.gift {
  background: #eee;
  border-radius: 2px;
  color: #333;
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
}

.contents_m .job_detail h2.job_h2 span.gift span {
  font-weight: bold;
  padding-left: 3px;
}

/* カスタム求人の詳細
----------------------------------------------- */

.contents_m .sch-detail {
  position: relative;
  margin: 40px 0 0;
  padding: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  .job_h2 {
    position: relative;
    padding: 28px 132px 0 24px;
    border-radius: 4px 4px 0px 0px;
    color: #3D270F;
    font-size: 2.2rem;
  }
  p.gift {
    position: absolute;
    top: 0;
    right: 24px;
    min-width: 96px;
    padding: 20px 14px;
    background-color: #FB5555;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  p.gift::before {
    position: absolute;
    top: 100%;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 49px 0 0;
    border-color: #FB5555 transparent transparent transparent;
  }
  p.gift::after {
    position: absolute;
    top: 100%;
    right: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 49px 22px 0;
    border-color: transparent #FB5555 transparent transparent;
  }
  p.gift span {
    font-family: Roboto;
    font-size: 1.8rem;
  }
  .job_container .date {
    margin-top: 16px;
    padding: 0 24px;
    color: #3D270F;
  }
  h2.job_h2 span.cate {
    color: #3D270F;
    font-size: 1.6rem;
  }
  .jobtext {
    width: 600px;
    margin: 24px auto 0;
    padding: 0;
  }
  .jobtext .photo {
    float: none;
    width: 600px;
    margin: 16px auto 0;
    padding: 0;
    border: none;
    text-align: center;
  }
  .jobtext .photo .text {
    text-align: left;
  }
  .jobtext .photo img {
    object-fit: contain;
    width: 600px;
    height: 300px;
    font-family: "object-fit: contain;";   /*IE対策*/
  }
  .job_container .item_img {
    justify-content: space-between;
    margin: 24px 0 0;
  }
  .job_container .item_img li {
    width: calc(100% /2 - 13px);
    padding: 0;
    border: none;
  }
  .job_container .jobtext p {
    margin: 24px 0 0;
    color: #3D270F;
    font-size: 1.6rem;
  }
  .job_container .jobtext .icon {
    margin-top: 16px;
  }
  .job_container .jobtext .icon li a {
    position: relative;
    padding: 3px 8px 3px 26px;
    color: #2382C7;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 16px 0px 0px 16px;
    border: none;
    font-size: 1.2rem;
  }
  .job_container .jobtext .icon li a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  .info_table {
    margin-top: 24px;
    padding: 0 34px 40px;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
  }
  .info_table.bb-none {
    border-bottom: none;
  }
  .info_table + .info_table {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
  table {
    color: #3D270F;
    font-size: 1.6rem;
    font-weight: 400;
  }
  table caption {
    position: relative;
    padding: 0 0 16px 28px;
    background-color: #ffffff;
    color: #3D270F;
    font-size: 2.2rem;
    font-weight: 700;
  }
  table caption svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  table > div {
    margin-top: 8px;
  }
  table th,
  table td {
    border: 1px solid #E0E0E0;
  }
  table th {
    position: relative;
    width: 150px;
    padding: 16px;
    // padding: 8px 8px 8px 38px;
    background-color: #FFF0DB;
    font-weight: 700;
  }
  th::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  th.ic-job::before {
    background-image: url(../img/icon/job02.svg);
  }
  th.ic-wifi::before {
    background-image: url(../img/icon/wifi02.svg);
  }
  th.ic-company::before {
    background-image: url(../img/icon/company.svg);
  }
  th.ic-map::before {
    background-image: url(../img/icon/map02.svg);
  }
  td {
    padding-left: 26px;
  }
  td.ic-phone {
    font-family: Roboto;
    font-size: 2.2rem;
    font-weight: 700;
  }
  td.ic-phone svg {
    margin-right: 4px;
  }
  .job_container .button {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 600px;
    margin: 24px auto 0;
    padding: 0;
    background-color: #FFFFFF;
  }
  .btn_clip,
  .btn_inquiry,
  .btn_inquiry.not {
    position: relative;
    width: calc(100% / 2 - 8px);
    padding: 10px 0 10px 10px;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    &:hover {
      background-color: #FFF0DB;
    }
  }
  .btn_clip::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../img/icon/add.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  .btn_clip.not {
    position: relative;
    width: calc(100% / 2 - 8px);
    padding: 10px 0 10px 10px;
    background-color: #FFFFFF;
    background-image: url(../img/icon/close02.svg);
    background-repeat: no-repeat;
    background-position: center left 16px;
    background-size: 16px 16px;
    border-radius: 6px;
    border: 2px solid rgba(54, 59, 64, .4);
    color: rgba(54, 59, 64, .4);
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: #F2F2F2;
    }
  }
  .btn_clip.not::before {
    content: none;
  }
  .btn_inquiry::before,
  .btn_inquiry.not::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../img/icon/mail02.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  .button .btn_entry {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 16px;
    padding: 10px 10px 10px 0;
    background-color: #FF7E41;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    &:hover {
      opacity: .8;
    }
  }
  .button .btn_entry::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    right: 16px;
    background-image: url(../img/sp/icon/arrow/right-circle-sub.svg);
    transform: translateY(-50%);
  }
}

.contents_m .sch-detail._pb32 {
  padding-bottom: 32px;
}

.contents_m .contents_box.sch-detail_interview {
  margin: 40px 0 0;
  padding: 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 4px;
  border: none;
  h3 {
    padding-left: 28px;
  }
  .ic-mic {
    position: relative;
    padding: 0;
    border: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  .ic-mic::before {
    content: " ";
    position: absolute;
    top: 16%;
    left: 29%;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/mic.svg);
    background-repeat: no-repeat;
  }
  .ic-mic::after {
    content: none;
  }
  .body {
    margin-top: 16px;
  }
  .body ul li {
    overflow: initial;
    min-height: 212px;
    margin: 0;
    // background-color: #FFFFFF;
    // border-radius: 6px;
    // box-shadow: 0px 0px 10px rgba(35, 130, 199, .6);
  }
  .body ul li + li {
    margin-top: 8px;
  }
  .body ul li .img a img {
    object-fit: contain;
    width: 200px;
    border: none;
    font-family: "object-fit: contain;";   /*IE対策*/
  }
  .body ul li .title {
    margin: 8px 0 0;
    padding: 0;
    color: #2382C7;
    font-size: 1.6rem;
    border-left: none;
  }
  .body ul li .title a {
    color: #2382C7;
    font-size: 1.6rem;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  .body ul li .cate {
    margin: 0;
    color: #000000;
    font-size: 1.2rem;
  }
  .body ul li .text,
  .body ul li .cate,
  .body ul li .title {
    margin-left: 230px;
  }
}

/* 求職者の検索
----------------------------------------------- */
.resume_more {
  text-align: right;
  word-break: keep-all;
}

.resume_more li a {
  padding: 8px 16px;
  background: #FF7E41;
  border-radius: 4px;
  color: #FFFFFF;
  text-decoration: none;
  &:hover {
    opacity: .8;
  }
}

/* 求人情報の管理
----------------------------------------------- */
.contents_m .list ul.operation {
  text-align: right;
  margin: 0 0 10px;
  padding: 10px 0 0;
}

.contents_m .list ul.operation li {
  display: inline-block;
  background: none;
  margin-left: 7px;
}

.contents_m .list ul.operation li a {
  display: inline-block;
  background-color: #333;
  border-radius: 2px;
  color: #fff;
  text-decoration: none;
  font-weight: normal;
  padding: 8px 16px;
}

.contents_m.listul.operationlia:hover {
  background-color: #111;
}

.contents_m .list ul.operation li.back a {
  background: url(../img/style/back.gif) no-repeat 10px 13px #fff;
  color: #333;
  padding: 7px 16px 7px 25px;
  border: 1px solid #999;
}

.contents_m.listul.operationli.back a:hover {
  background-color: #fafafa;
}

/*----------------------------------------------------------------【企業詳細】*/

.cuser_container {
  margin-bottom: 30px;
}

.cuser_container .box {
  margin-bottom: 10px;
}

.cuser_container .box .photo {
  border: 1px solid #ccc;
  padding: 2px;
  float: left;
}

.cuser_container .box .photo span {
  line-height: 100px;
  width: 133px;
  text-align: center;
  display: block;
  background-color: #fafafa;
}

.cuser_container .box .jobtext {
  line-height: 1.7;
  margin-left: 150px;
}

/*----------------------------------------------------------------【メッセージボックス】*/

/* メッセージ履歴
----------------------------------------------- */

#contents #itemsMessageList {
  margin-bottom: 10px;
}

#contents #itemsMessageList li {
  margin-top: -1px;
  word-break: break-all;
  word-wrap: break-word;
}

#contents #itemsMessageList li p.sub {
  padding: 7px 10px;
  font-weight: bold;
  background-color: #f5f5f5;
}

#contents #itemsMessageList li span.date {
  float: right;
  color: #666;
}

// メッセージ履歴詳細
#contents span.mailtype,
#contents #user_info span.mailtype {
  position: relative;
  padding: 3px 8px 3px 26px;
  color: #2382C7;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 16px 0px 0px 16px;
  border: none;
  font-size: 1.2rem;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

#contents #itemsMessageList li p.title {
  border-bottom: 1px dotted #ccc;
  padding: 7px 10px;
  font-weight: bold;
}

#contents #itemsMessageList li p.description {
  border: none;
  padding: 10px;
}

#contents #itemsMessageList tr.message td {
  padding: 15px 20px;
  line-height: 1.8;
}

/*----------------------------------------------------------------【サイドカラム】*/

/* サイド：お知らせ
----------------------------------------------- */

.contents_s .contents_box.system_news {
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 4px;
  .ic-news::before {
    content: " ";
    position: absolute;
    top: 18px;
    left: 23px;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/news.svg);
    background-repeat: no-repeat;
  }
}

/* サイド：ログインフォーム
----------------------------------------------- */
.contents_s .login {
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #fff;
  border-radius: 4px;
}

.contents_s .login .body dl + p {
  text-align: center;
}

.contents_s .login .body dl .pass input[type="password"],
.contents_s .login .body dl .mail input[type="text"] {
  width: 100%;
  padding: 8px 7px;
  background-color: #fff;
  border: #fff;
  border-radius: 4px;
  font-size: 1.6rem;
  &::placeholder {
    color: #828282;
  }
}

.contents_s .login .body dl .pass > label {
  display: block;
  margin: 18px 0 10px;
  padding-left: 24px;
}

.contents_s .login .body input[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 40px;
  margin: 0 auto 10px;
  background: #2382C7;
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(35, 130, 199, .6);
  cursor: pointer;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  &:hover {
    opacity: .8;
  }
}

/* サイド：ログインフォーム
----------------------------------------------- */

.contents_s .side-Box01 {
  h2 {
    padding: 16px 0 16px 20px;
    border: none;
    color: #3D270F;
    font-size: 1.8rem;
    text-align: center;
  }
  h2.ic-login::before {
    content: " ";
    position: absolute;
    top: 20px;
    left: 74px;
    width: 16px;
    height: 20px;
    background-image: url(../img/icon/login02.svg);
    background-repeat: no-repeat;
  }
  h2::after {
    content: none;
  }
  .body {
    padding: 0 16px 0;
  }
  .body label {
    padding-left: 0;
    color: #3D270F;
    font-size: 1.2rem;
    line-height: 2;
  }
  .body a {
    color: #2382C7;
    font-size: 1.2rem;
  }
}

.contents_s .side-Box02 {
  h2 {
    padding: 16px 0 0 20px;
    border: none;
    color: #3D270F;
    font-size: 1.8rem;
    text-align: center;
  }
  h2.ic-note::before {
    content: " ";
    position: absolute;
    top: 18px;
    left: 48px;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/note.svg);
    background-repeat: no-repeat;
  }
  h2.ic-rank::before {
    content: " ";
    position: absolute;
    top: 20px;
    left: 22px;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/rank.svg);
    background-repeat: no-repeat;
  }
  h2::after {
    content: none;
  }
  .body {
    padding: 16px 16px 19px;
  }
  .body label {
    color: #3D270F;
    font-size: 1.2rem;
  }
  .body a {
    color: #2382C7;
    font-size: 1.2rem;
  }
}

.side-bnrArea {
  a {
    display: inline-flex;
    margin-bottom: 24px;
    &:hover {
      opacity: .8;
    }
  }
}

/* サイド：ページリスト
----------------------------------------------- */
.contents_s .page {
  padding: 16px;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 4px;
  h2 {
    padding: 0 0 16px 23px;
  }
  h2::before {
    content: " ";
    position: absolute;
    top: 3px;
    left: 40px;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/news.svg);
    background-repeat: no-repeat;
  }
  .body {
    padding: 0;
  }
  ul {
    margin: 0;
  }
  ul li {
    padding-left: 0;
    background: none;
    line-height: 1;
  }
  li a {
    position: relative;
    display: block;
    padding: 12px 32px 12px 8px;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(54, 59, 64, .4);
    font-size: 1.2rem;
    line-height: 1.4;
    text-decoration: none;
    &:hover {
      background: linear-gradient(0deg, rgba(150, 198, 232, .2), rgba(150, 198, 232, .2)), #FFFFFF;
    }
  }
  li a::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 16px;
    height: 16px;
    background-image: url(../img/icon/arrow/right-circle.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  li a + li a {
    margin-top: 4px;
  }
}


/* サイド：求人情報の埋め込み
----------------------------------------------- */
.contents_s .embed_job h2 {
  position: relative;
  height: 40px;
}

.contents_s .embed_job h2 a {
  color: #3D270F;
  text-decoration: none;
}

.contents_s .embed_job .body {
  padding: 10px 15px;
}

.contents_s .embed_job .body li {
  line-height: 1;
  padding: 0;
  background: transparent;
}

// .contents_s .embed_job .body li + li {
//   border-top: 1px solid #ddd;
//   padding: 10px 0 0;
//   margin: 10px 0 0;
// }

.contents_s .embed_job .body li a {
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
}

.contents_s .embed_job .body li a .img {
  width: 30%;
  padding: 0;
  position: relative;
}

.contents_s .embed_job .body li a .img > img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px;
}

.contents_s .embed_job .body li a .cate {
  width: 100%;
  padding: 0;
  margin: 0 0 8px;
  font-size: 10px;
  color: #3D270F;
}

.contents_s .embed_job .body li a .cate span {
  margin: 0 5px 0 0;
}

.contents_s .embed_job .body li a .title span {
  display: block;
}

.contents_s .embed_job .body li a .title .title_cname {
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #3D270F;
  margin: 0 0 5px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.contents_s .embed_job .body li a .title .title_name {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.8;
}

.rank_number {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 10px;
  color: #fff;
  background: rgba(255, 155, 0, .8);
  border: 1px solid #fff;
  padding: 5px;
}

/* サイド：最近見た求人,検討中リスト,アクセスランキング
----------------------------------------------- */

.contents_s .embed_job.history .body,
.contents_s .embed_job.clip .body,
.contents_s .embed_job.rank .body {
  margin-top: 10px;
  padding: 0 16px 0;
  li {
    line-height: 1;
    padding: 0;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  li + li {
    margin: 16px 0 0;
  }
  li a {
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    text-decoration: none;
    &:hover {
      box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    }
  }
  li a .img {
    width: 100%;
    padding: 0;
  }
  li a .img > img {
    object-fit: contain;
    width: 100%;
    height: 87.5px;
    font-family: "object-fit: contain;";   /*IE対策*/
  }
  li a .cate {
    width: 100%;
    padding: 0;
    margin: 8px 0 0;
    font-size: 1.2rem;
    color: #3D270F;
    text-decoration: none;
  }
  li a .cate span {
    margin: 0 8px 0 0;
  }
  li a .title span {
    display: block;
  }
  li a .title .title_cname {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1;
    color: #3D270F;
    margin: 8px 0 0;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  li a .title .title_name {
    margin: 8px 0 0;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.48;
  }
  li a:hover .title_name {
      text-decoration: underline;
    }
}


/* サイド：契約中のサービス
----------------------------------------------- */
.contents_s .service p.serviceButton {
  text-align: center;
  margin-top: 10px;
}

.contents_s .service p.serviceButton a {
  background: #333;
  color: #fff;
  text-decoration: none;
  padding: 5px 15px;
  font-weight: bold;
}

.contents_s.servicep.servicebuttona:hover {
  background: #111;
}

/* サイド：契約中のサービス
----------------------------------------------- */
.contents_s .recommend ul {
  border-bottom: 1px dotted #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/* 履歴書：希望勤務地(検索用)
----------------------------------------------- */

.addHopeList {
  width: 300px;
  position: relative;
  margin: 16px 0;
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  color: #828282;
}
.ha_del {
  position: absolute;
  top: 50%;
  right: 8px;
  padding: 2px 12px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 6px;
  // background-image: url(../img/icon/close02.svg);
  // background-repeat: no-repeat;
  // background-position: 12px center;
  // background-size: 16px 16px;
  border: 2px solid rgba(54, 59, 64, .4);
  color: rgba(54, 59, 64, .4);
  font-size: 1.2rem;
  font-weight: 700;
  transform: translateY(-50%);
  &:hover {
    background-color: #F2F2F2;
  }
}

/* 地図から探す
----------------------------------------------- */

#foundCounter {
  float: right;
  margin: 5px;
}
#foundCounter span.count {
  font-size: 1.6rem;
  font-weight: 700;
}
#map_canvas {
  height: 500px;
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}
.infoWnd {
  height: 120px;
  line-height: 1.5;
  font-size: 1.2rem;
}
.infoWnd a {
  font-size: 1.2rem;
}

#pointlist ul.item_list {
  justify-content: space-between;
  &::after {
    content: "";
    width: 224px;
  }
  li {
    width: 224px;
    margin-bottom: 16px;
    padding: 16px;
  }
  li + li {
    margin-left: 0;
  }
  li:nth-child(n + 4) {
    display: block;
  }
}

// #pointlist ul li {
// }

// 地図を見るリンク
.googlemap {
  position: relative;
  margin-left: 16px;
  padding-left: 24px;
  background-image: url(../img/icon/map.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 16px 16px;
}

#mapForm {
  select[name=adds],
  select[name=add_sub] {
    width: 140px;
  }
  .map-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 40px;
    padding: 0 16px 0 24px;
    background-color: #FF7E41;
    background-image: url(../img/icon/map03.svg);
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 16px 16px;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 1.6rem;
    font-weight: 700;
    &:hover {
      opacity: .8;
    }
  }
  .toggleBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 40px;
    margin: 0 auto;
    padding: 0 16px 0 24px;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    font-size: 1.6rem;
    font-weight: 700;
    &:hover {
      background-color: #FFF0DB;
    }
  }
  .toggleBtn svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
}

/* ================================================================================ */
/*                                                                                  */
/*      デザインテーマ                                                              */
/*                                                                                  */
/* ================================================================================ */

/* グリッド
----------------------------------------------- */
.cmslGrid {
  display: flex;
  flex-wrap: wrap;
}

.cmslGrid__col {
  width: 100%;
  box-sizing: border-box;
}

.cmslGrid__col--1 {
  width: 8.33333333333%;
}
.cmslGrid__col--2 {
  width: 16.6666666667%;
}
.cmslGrid__col--3 {
  width: 25%;
}
.cmslGrid__col--4 {
  width: 33.3333333333%;
}
.cmslGrid__col--5 {
  width: 41.6666666667%;
}
.cmslGrid__col--6 {
  width: 50%;
}
.cmslGrid__col--7 {
  width: 58.3333333333%;
}
.cmslGrid__col--8 {
  width: 66.6666666667%;
}
.cmslGrid__col--9 {
  width: 75%;
}
.cmslGrid__col--10 {
  width: 83.3333333333%;
}
.cmslGrid__col--11 {
  width: 91.6666666667%;
}
.cmslGrid__col--12 {
  width: 100%;
}

.cmslGrid--separate {
  margin-left: -10px;
  margin-right: -10px;
}

.cmslGrid--separate .cmslGrid__col {
  padding: 0 10px;
}

/* ブロック
----------------------------------------------- */
.cmslBlock {
  display: flex;
  flex-wrap: wrap;
}

.cmslBlock__ {
  width: 100%;
  box-sizing: border-box;
}

.cmslBlock--2 .cmslBlock__ {
  width: 50%;
}
.cmslBlock--3 .cmslBlock__ {
  width: 33.3333333333%;
}
.cmslBlock--4 .cmslBlock__ {
  width: 25%;
}
.cmslBlock--5 .cmslBlock__ {
  width: 20%;
}

.cmslBlock--separate {
  margin-left: -10px;
  margin-right: -10px;
}

.cmslBlock--separate .cmslBlock__ {
  padding: 0 10px;
}

/* エリアリスト
----------------------------------------------- */
.areaList {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.3em;
}

.areaList__area {
  width: 20%;
  padding: 0;
  margin: 0 0 10px;
  text-align: center;
  background: rgba(0, 0, 0, .05);
  font-size: 6px;
}

.areaList__prefecture {
  width: 80%;
  padding: 0 5px;
  box-sizing: border-box;
}

.areaList__prefecture ul {
  list-style: none;
  font-size: 0;
}

.areaList__prefecture ul li {
  display: inline-block;
  font-size: 6px;
}

.areaList__prefecture ul li a {
  display: inline-block;
  padding: 0 6px;
}

/* リスト
----------------------------------------------- */
.commonList {
  padding: 0 0 0 20px;
  margin: 0 0 20px;
}

.commonList .commonList {
  padding: 0 0 0 20px;
  margin: 0;
}

.commonList li {
  line-height: 1.6em;
  margin: 0 0 .4em;
  padding: 0 0 0 5px;
}

.commonList--circle li {
  list-style: circle;
}

/*----------------------------------------------------------------簡単検索*/

/* 簡単検索(グリッド)
----------------------------------------------- */
.formGrid {
  display: flex;
  flex-wrap: wrap;
}

.formGrid__col {
  width: 100%;
  box-sizing: border-box;
}

.formGrid__col--05 {
  width: 4.16666666666%;
}

.formGrid__col--1 {
  width: 8.33333333333%;
}
.formGrid__col--2 {
  width: 16.6666666667%;
}
.formGrid__col--3 {
  width: 25%;
}
.formGrid__col--4 {
  width: 33.3333333333%;
}
.formGrid__col--5 {
  width: 41.6666666667%;
}
.formGrid__col--6 {
  width: 50%;
}
.formGrid__col--7 {
  width: 58.3333333333%;
}
.formGrid__col--8 {
  width: 66.6666666667%;
}
.formGrid__col--9 {
  width: 75%;
}
.formGrid__col--10 {
  width: 83.3333333333%;
}
.formGrid__col--11 {
  width: 91.6666666667%;
}
.formGrid__col--12 {
  width: 100%;
}

.formGrid__col--free {
  width: auto;
  flex-grow: 1;
}

.formGrid--separate {
  margin-left: -10px;
  margin-right: -10px;
}

.formGrid--separate .formGrid__col {
  padding: 0 10px;
}

.formGrid--mt {
  margin-top: 15px;
}

/* 簡単検索
----------------------------------------------- */
.easySearchForm {
  background-color: #e5e5e5;
  background-image: url(../img/top/kv_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 240px;
}

.easySearchForm_heading {
  padding-top: 24px;
  font-size: 2.4rem;
  line-height: 1.8;
  text-align: center;
  strong {
    color: #FF7E41;
  }
  span {
    font-size: 1.8rem;
  }
}

.tp-search_form {
  width: 768px;
}

.easySearchForm__ {
  padding: 20px 0;
  margin: 0 auto;
  width: 1032px;
  box-sizing: border-box;
  form {
    width: 768px;
  }
}

.easySearchForm__crossIcon {
  text-align: center;
}

.easySearchForm__crossIcon span {
  color: #000000;
  font-size: 1.6rem;
}

.easySearchForm__submit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 29px auto 0;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  svg {
    content: " ";
    position: absolute;
    top: 50%;
    left: 109px;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
  }
}

.easySearchForm__link__list {
  font-size: 0;
}

.easySearchForm__link__list li {
  display: inline-block;
  font-size: 14px;
  margin: 0 1em 1em 0;
}

.easySearchForm__link__list li a {
  color: #333;
}

.easySearchForm__title {
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 10px;
  color: #333;
}

/* stylishSelect */

.cmn-stylishSelect {
  position: relative;
  display: inline-block;
  min-width: 100px;
}

.cmn-stylishSelect .fa {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.cmn-stylishSelect select {
  width: 100%;
  height: 40px;
  appearance: none;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 1.6rem;
  box-shadow: 0 0 6px rgba(54, 59, 64, .4);
}

.cmn-stylishSelect--w100 {
  width: 100%;
}

/* stylishBtn */
.bg-wrapMain {
  background-color: #FF7E41;
  &:hover {
    opacity: .8;
  }
}

.cmn-stylishBtn {
  appearance: none;
  display: block;
  padding: 0 .3em;
  width: 400px;
  height: 48px;
  background-color: #FF7E41;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

// add
.formGrid-Col_01 {
  width: 240px;
  select {
    padding: 0 32px;
  }
  .ic-before {
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
  }
}

.formGrid-Col_02 {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stylishText */
.cmn-stylishText {
  height: 40px;
  width: 100%;
  border: 1px solid #333;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
}

.cmn-stylishTextWithBtn {
  display: flex;
}

.cmn-stylishTextWithBtn > .cmn-stylishText {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  flex-grow: 1;
}

.cmn-stylishTextWithBtn > .cmn-stylishBtn {
  width: 80px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* qj sample */
.qj-sampleBanner {
  margin: 0 0 10px;
}

.qj-topimage {
  width: 100%;
  min-width: 1032px;
  height: 300px;
  background-image: url("../img/qj_topimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  margin: -20px auto 0;
}

.qj-topimage__ {
  width: 1032px;
  height: 300px;
  margin: 0 auto;
  position: relative;
}

.qj-topimage__text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.qj-topimage + .easySearchForm {
  margin: 0 0 15px;
}

/* ヘッダー(共通)
----------------------------------------------- */
.qj-head {
  width: 1032px;
  margin: 0 auto;
  min-height: 70px;
  display: flex;
  flex-wrap: nowrap;
}

.qj-head__set {
  display: flex;
  align-items: center;
}

.qj-head__set__logo {
  margin: 0;
  // height: 70px;
  display: flex;
  align-items: center;
}

.qj-head__set__logo a img {
  display: block;
  max-height: 70px;
  width: auto;
}

.qj-head__set__logoa:hover {
  opacity: .7;
}

.qj-head__set__switch {
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #ddd;
}

.qj-head__set__switch__btn {
  float: left;
}

.qj-head__set__switch__btn + .qj-head__set__switch__btn {
  border-left: 1px solid #ddd;
}

.qj-head__set__switch__btn a {
  display: inline-block;
  padding: 6px 12px;
  font-size: 12px;
  text-decoration: none;
  background: #ddd;
  color: #333;
}

.qj-head__set__switch__btna:hover {
  background: #fff;
}

.qj-head__set__switch__btn--fresh.active a {
  background: #fff;
}

.qj-head__set__switch__btn--mid.active a {
  background: #fff;
}

.qj-head__panel {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.qj-head__panel__menu {
  padding-bottom: 10px;
}

.qj-head__panel__free {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 13px 10px 0;
}

.qj-head__panel__free__hello {
  color: #3D270F;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: right;
}

.qj-head__panel__free__hello strong {
  margin: 0 3px 0 0;
}

.qj-head__panel__free__link {
  width: 100%;
  list-style: none;
  text-align: right;
  margin: 8px 0 0;
  font-size: 0;
}

.qj-head__panel__free__link__item {
  font-size: 1.2rem;
  display: inline-block;
  margin: 0 0 0 10px;
}

/* メニューパネル
----------------------------------------------- */

.qjMyMenuList {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
}

.qjMyMenuList__item {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 60px;
  border-left: 1px solid #6C6154;
  &:last-of-type {
    border-right: 1px solid #6C6154;
  }
}

.qjMyMenuList__item > a {
  display: block;
  width: 100%;
  color: #3D270F;
  text-decoration: none;
  &:hover {
    background-color: #FFF0DB;
  }
}

.qjMyMenuList__item__icon {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
  margin: 0 auto;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.qjMyMenuList__item__icon .fa {
  line-height: 30px;
  font-size: 20px;
  color: #666;
}

.qjMyMenuList__item__icon__cnt {
  position: absolute;
  top: 4px;
  right: 26px;
  width: 16px;
  height: 16px;
  background-color: #FF7E41;
  color: #ffffff;
  font-family: Roboto Condensed;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 50%;
  padding: 1px;
}

.qjMyMenuList__item__name > span {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2em;
}

/* add トップ search Tab
----------------------------------------------- */

.tp-search_TabBtn {
  display: flex;
  align-items: center;
  .tab-recommend,
  .tab-new {
    position: relative;
    width: 384px;
    height: 64px;
    padding: 18px 0;
    background-color: #F2F2F2;
    border-radius: 4px 4px 0 0;
    color: #828282;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
  }
  .tab-recommend.isActive,
  .tab-new.isActive {
    background-color: #FF7E41;
    color: #FFFFFF;
  }
  .tab-recommend svg,
  .tab-new svg {
    position: absolute;
    top: 48%;
    transform: translateY(-48%);
    fill: #828282;
  }
  .tab-recommend svg {
    left: 108px;
  }
  .tab-new svg {
    left: 128px;
  }
  .tab-recommend.isActive svg,
  .tab-new.isActive svg {
    fill: #FFFFFF;
  }
}

.tp-search_TabContent {
  padding: 32px;
  background-color: #FFD0B9;
}

.tp-search_TabContent > div {
  display: none;
}

.tp-search_TabContent > div.isActive {
  display: block;
}

.tp-search_Tab-contents-recommend .item_list,
.tp-search_Tab-contents-new .item_list {
  display: flex;
  li {
    display: flex;
    align-items: stretch;
    width: calc(100% / 3 - 6px);
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(255, 126, 65, .6);
    &:hover {
      box-shadow: 0px 0px 12px #FF7E41;
    }
  }
  li a {
    display: block;
    padding: 16px;
    text-decoration: none;
    &:nth-child(n + 4) {
      display: none;
    }
  }
  li + li {
    margin-left: 16px;
  }
  li img {
    object-fit: contain;
    width: 192px;
    height: 96px;
    font-family: "object-fit: contain;";   /*IE対策*/
  }
  .cate {
    margin-top: 8px;
    color: #3D270F;
    font-size: 1.2rem;
  }
  .title {
    margin-bottom: 0;
    padding: 0;
    border-left: none;
    color: #2382C7;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
  }
  li a:hover .title {
    text-decoration: underline;
  }
  .title span {
    display: none;
  }
}

.moreBtn {
  margin-top: 32px;
}

.moreBtn a {
  position: relative;
  display: block;
  width: 320px;
  margin: 0 auto;
  padding: 14px 0;
  background-color: #FF7E41;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  color: #fff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    opacity: .8;
  }
  svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

/* add トップ tp-special
----------------------------------------------- */

.tp-special {
  position: relative;
  display: flex;
  margin-top: 72px;
  padding: 24px 24px 0 0;
  height: 218px;
  background-color: #96C6E8;
  .tp-special_heading {
    position: absolute;
    top: -34px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 159px;
    height: 79px;
    background-image: url(../img/top/special_bg.svg);
    background-repeat: no-repeat;
  }
  .tp-special_heading h2 {
    margin-top: -18px;
    color: #ffffff;
    font-size: 1.6rem;
    text-align: center;
  }
  .tp-special_heading h2 span {
    font-size: 2.4rem;
    font-family: "Roboto", sans-serif;
  }
  > img {
    margin-top: -24px;
    margin-right: -34px;
  }
  .tp-special_item {
    position: relative;
  }
  .tp-special_item + .tp-special_item {
    margin-top: 4px;
  }
  .tp-special_item a {
    display: flex;
    align-items: center;
    width: 580px;
    padding: 12px 48px 12px 16px;
    color: #2382C7;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(54, 59, 64, .1);
    font-size: 1.6rem;
    font-weight: 700;
    text-decoration: none;
    &:hover {
      background: linear-gradient(0deg, rgba(150, 198, 232, .2), rgba(150, 198, 232, .2)), #FFFFFF;
    }
  }
  .tp-special_item svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

/* add tp-read
----------------------------------------------- */

.tp-read {
  margin-top: 49px;
  &_heading {
    position: relative;
    display: flex;
  }
  &_heading > img {
    position: absolute;
    bottom: 0;
  }
  &_heading h2 {
    position: relative;
    width: 100%;
    margin-left: 90px;
    padding: 10px 22px;
    background-color: #FFD0B9;
    border-radius: 6px;
    color: #3D270F;
    font-size: 2.2rem;
  }
  &_heading h2::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14.5px 15px 14.5px 0;
    border-color: transparent #FFD0B9 transparent transparent;
    transform: translateY(-50%);
  }
  &_txt {
    margin-top: 24px;
  }
  &_txt p {
    color: #3D270F;
    font-size: 1.6rem;
  }
  &_txt p + p {
    margin-top: 24px;
  }
}

/* add トップ pickup
----------------------------------------------- */

.tp-pickup {
  margin-top: 40px;
  background-color: #96C6E8;
  border-radius: 4px;
  border-top: 8px solid #FF7E41;
  h2 {
    position: relative;
    padding: 12px 0;
    background: #2382C7;
    color: #ffffff;
    text-align: center;
    line-height: 1;
  }
  h2::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 231px;
    height: 89px;
    background-image: url(../img/top/pickup_img.png);
    background-repeat: no-repeat;
    background-size: 231px 89px;
  }
  h2 span {
    font-size: 3rem;
  }
}

.tp-pickup_items {
  padding: 24px;
  li + li {
    margin-top: 16px;
  }
}

.tp-pickup_item {
  position: relative;
  display: flex;
  padding: 24px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(35, 130, 199, .6);
  text-decoration: none;
  &:hover {
    box-shadow: 0px 0px 12px #2382C7;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 65px 65px 0;
    border-color: transparent #FFF0DB transparent transparent;
    // border-top-right-radius: 4px;
  }
  &::after {
    content: "check!";
    position: absolute;
    top: 12px;
    right: -5px;
    color: #FF7E41;
    font-family: Roboto;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    transform: rotate(45deg);
  }
  &_img {
    max-width: 175px;
  }
  &_img img {
    object-fit: contain;
    width: 175px;
    font-family: "object-fit: contain;";   /*IE対策*/
  }
  &_txt {
    margin-left: 21px;
  }
  &_txt .cate {
    color: #3D270F;
    font-size: 1.6rem;
    font-weight: 700;
  }
  &_txt .cate span {
    font-size: 1.2rem;
    font-weight: 400;
  }
  h3 {
    margin-top: 4px;
    margin-bottom: 0;
    padding: 0;
    border-left: none;
    color: #2382C7;
    font-size: 1.8rem;
  }
  p {
    margin-top: 4px;
    color: #3D270F;
    font-size: 1.2rem;
  }
  p.tp-pickup_item_linkBtn {
    position: relative;
    display: block;
    margin-top: 16px;
    padding: 12px 32px;
    color: #FF7E41;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
  }
  &:hover p.tp-pickup_item_linkBtn {
    background-color: #FFF0DB;
  }
  &_linkBtn svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

/* add top インタビュー
----------------------------------------------- */

.contents_box.embed_interview.interview {
  margin: 40px 0 0;
  padding: 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 4px;
  border: none;
  h2.ic-mic,
  h3.ic-mic {
    position: relative;
    padding: 0;
    border: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h2.ic-mic::before {
    content: " ";
    position: absolute;
    top: 16%;
    left: 37%;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/mic.svg);
    background-repeat: no-repeat;
  }
  h2.ic-mic::after,
  h3.ic-mic::after {
    content: none;
  }
  h3.ic-mic > svg {
    margin-right: 8px;
  }
  .body {
    padding-top: 16px;
  }
  .body ul li {
    overflow: initial;
    margin: 0;
  }
  .body ul li a {
    display: block;
    min-height: 212px;
    margin: 0;
    padding: 24px;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(35, 130, 199, .6);
    text-decoration: none;
    &:hover {
      box-shadow: 0px 0px 10px rgba(35, 130, 199, .6);
    }
  }
  .body ul li a:hover .title,
  .body ul li a:hover .text {
    text-decoration: underline;
  }
  .body ul li + li {
    margin-top: 8px;
  }
  .body ul li .img img {
    object-fit: contain;
    width: 200px;
    border: none;
    font-family: "object-fit: contain;";   /*IE対策*/
  }
  .body ul li .title {
    margin: 8px 0 0;
    padding: 0;
    color: #2382C7;
    font-size: 1.6rem;
    border-left: none;
  }
  .body ul li .cate {
    display: block;
    margin: 0;
    color: #000000;
    font-size: 1.2rem;
  }
  .body ul li .text {
    color: #3D270F;
  }
  .body ul li .text,
  .body ul li .cate,
  .body ul li .title {
    margin-left: 230px;
  }
  .body > .more {
    margin-top: 24px;
    padding: 0;
    background: initial;
    text-align: center;
  }
  .body > .more a {
    position: relative;
    display: block;
    width: 320px;
    margin: 0 auto;
    padding: 14px 0;
    background-color: #FF7E41;
    box-shadow: 0 0 6px rgba(54, 59, 64, .4);
    color: #ffffff;
    border-radius: 6px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    &:hover {
      opacity: .8;
    }
  }
  .body > .more a svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

/* add tp-search
----------------------------------------------- */

.contents_m .contents_box.tp-search {
  margin-top: 40px;
  padding: 16px 32px 32px;
  background-color: #FFFFFF;
  border: 4px solid #FF8951;
  border-radius: 4px;
  h2.ic-search {
    position: relative;
    padding: 0;
    border: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h2.ic-search::before {
    content: " ";
    position: absolute;
    top: 16%;
    left: 40%;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/search.svg);
    background-repeat: no-repeat;
  }
  h2.ic-search::after {
    content: none;
  }
}

.tp-search_contents {
  margin-top: 24px;
  dt {
    position: relative;
    padding: 6px 12px 6px 36px;
    background-color: #FFF0DB;
    font-size: 1.8rem;
    font-weight: 700;
  }
  dt span {
    color: #FF7E41;
  }
  dt::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  dt.ic-job::before {
    background-image: url(../img/icon/job.svg);
  }
  dt.ic-wifi::before {
    background-image: url(../img/icon/wifi.svg);
  }
  dt.ic-feature::before {
    background-image: url(../img/icon/feature.svg);
  }
  dt.ic-key::before {
    background-image: url(../img/icon/key.svg);
  }
  dd {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
  }
  dd label {
    position: relative;
    width: calc(100% / 3);
    margin-bottom: 10px;
    padding-left: 24px;
    color: #3D270F;
    cursor: pointer;
    font-size: 1.6rem;
  }
  dd label input[type=checkbox] {
    appearance: none;
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: #F2F2F2;
    border-radius: 4px;
  }
  dd label input[type=checkbox]:checked {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  dd label input[type=checkbox]:checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 5px;
    width: 7px;
    height: 14px;
    border-radius: 4px;
    transform: rotate(40deg);
    border-bottom: 3px solid #3D270F;
    border-right: 3px solid #3D270F;
  }
  .button {
    &:hover {
      opacity: .8;
    }
  }
  input[type="submit"].btn_search {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 48px;
    padding: 0 16px 0 24px;
    background: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    font-size: 1.6rem;
    font-weight: 700;
  }
  input[name=id],
  input[name=free],
  input[name=cuser_name],
  input[name=label] {
    width: 100%;
    max-width: 100%;
    height: 40px;
    margin-top: 16px;
    padding: 8px 24px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    font-size: 1.6rem;
  }
  input[name=free]::placeholder {
    color: #828282;
  }
  .button {
    position: relative;
    width: 240px;
    height: 48px;
    margin: 24px auto 0;
    padding: 0;
  }
  .button svg {
    position: absolute;
    top: 50%;
    left: 30px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    z-index: 1;
  }
}

/* add 求人一覧系
----------------------------------------------- */

.embed_job.pickup_job,
.embed_job.cuser_job,
.embed_job.alike_job,
.embed_job.wish_job,
.embed_job.limits,
.embed_job.other,
.embed_job.cuser_match,
.embed_user.cuser_match,
.embed_job.embed_area,
.embed_job.area_job {
  margin: 48px 0 0;
  padding: 32px;
  background-color: #FFF0DB;
  border: none;
  h2,
  h3 {
    position: relative;
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h3.title {
    display: block;
  }
  h2 svg,
  h3 svg {
    margin-right: 7px;
  }
  h2.ic-company::before,
  h3.ic-company::before {
    content: " ";
    position: absolute;
    top: 16%;
    left: 34%;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/company.svg);
    background-repeat: no-repeat;
  }
  h2::after,
  h3::after {
    content: none;
  }
  .body > .more {
    background: initial;
    padding: 0;
  }
}

.embed_job.pickup_job ul.item_list,
.embed_job.cuser_job ul.item_list,
.embed_job.alike_job ul.item_list,
.embed_job.wish_job ul.item_list,
.embed_job.limits ul.item_list,
.embed_job.other ul.item_list,
.embed_job.embed_area ul.item_list {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 16px;
  li {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% / 3 - 6px);
    padding: 0;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(255, 126, 65, .6);
    &:hover {
      box-shadow: 0 0 12px #FF7E41;
    }
    &:nth-child(n + 4) {
      display: none;
    }
  }
  li > a {
    display: block;
    padding: 16px;
    text-decoration: none;
  }
  li + li {
    margin-left: 16px;
  }
  li img {
    object-fit: contain;
    width: 192px;
    height: 96px;
    font-family: "object-fit: contain;";   /*IE対策*/
  }
  .cate {
    margin-top: 8px;
    color: #3D270F;
    font-size: 1.2rem;
  }
  .title {
    margin-bottom: 0;
    padding: 0;
    border-left: none;
    color: #2382C7;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    text-align: left;
  }
  li > a:hover .title {
    text-decoration: underline;
  }
  .title span {
    display: none;
  }
}

.embed_job.other ul.item_list {
  flex-wrap: wrap;
}

.embed_job.limits {
  .moreBtn a {
    padding: 14px 14px 14px 0;
  }
  .moreBtn a svg {
    right: 12px;
  }
}

.pickup_job .more,
.alike_job .more,
.cuser_job .more,
.cuser_match .more,
.embed_area .more {
  margin-top: 32px;
}

.pickup_job .more a,
.cuser_job .more a,
.alike_job .more a,
.cuser_match .more a,
.embed_area .more a,
.gift-btn {
  position: relative;
  display: block;
  width: 320px;
  margin: 0 auto;
  padding: 12px 24px 12px 16px;
  background-color: #FF7E41;
  background-image: url(../img/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 16px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  color: #fff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  &:hover {
    opacity: .8;
  }
  // svg {
  //   position: absolute;
  //   top: 50%;
  //   right: 16px;
  //   transform: translateY(-50%);
  // }
}

/* カスタム検索フォーム sch-from
----------------------------------------------- */

.contents_m .contents_box.top_search.sch-from {
  margin-top: 24px;
  padding: 16px 32px 32px;
  background-color: #FFFFFF;
  border: 4px solid #FF8951;
  border-radius: 4px;
  &.mb-48 {
    margin-bottom: 48px;
  }
  h3.ic-search {
    position: relative;
    float: none;
    padding: 0;
    border: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
    line-height: 1;
  }
  h3.ic-search::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 28%;
    width: 20px;
    height: 20px;
    background-image: url(../img/icon/search.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  h3.ic-search::after {
    content: none;
  }
}

.sch-from_contents {
  margin-top: 34px;
  dt {
    position: relative;
    padding: 6px 12px 6px 36px;
    background-color: #FFF0DB;
    font-size: 1.8rem;
    font-weight: 700;
  }
  dt span {
    color: #FF7E41;
  }
  dt::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  dt.ic-job::before {
    background-image: url(../img/icon/job.svg);
  }
  dt.ic-wifi::before {
    background-image: url(../img/icon/wifi.svg);
  }
  dt.ic-feature::before {
    background-image: url(../img/icon/feature.svg);
  }
  dt.ic-key::before {
    background-image: url(../img/icon/key.svg);
  }
  dt.ic-company::before {
    background-image: url(../img/icon/company02.svg);
  }
  dt.ic-map::before {
    background-image: url(../img/icon/map.svg);
  }
  dt.ic-money::before {
    background-image: url(../img/icon/money.svg);
  }
  dt.ic-check::before {
    background-image: url(../img/icon/check.svg);
  }
  dd {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
  }
  dd label {
    position: relative;
    width: calc(100% / 3);
    margin-bottom: 10px;
    padding-left: 24px;
    color: #3D270F;
    cursor: pointer;
    font-size: 1.6rem;
  }
  dd label input[type=checkbox] {
    appearance: none;
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: #F2F2F2;
    border-radius: 4px;
  }
  dd label input[type=checkbox]:checked {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  dd label input[type=checkbox]:checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 5px;
    width: 7px;
    height: 14px;
    border-radius: 4px;
    transform: rotate(40deg);
    border-bottom: 3px solid #3D270F;
    border-right: 3px solid #3D270F;
  }
  .button {
    &:hover {
      opacity: .8;
    }
  }
  input[type="submit"].btn_search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 48px;
    padding: 0 16px 0 24px;
    background: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    font-size: 1.6rem;
    font-weight: 700;
    &:hover {
      opacity: 1;
    }
  }
  input[name=free],
  input[name=cuser_name] {
    width: 100%;
    max-width: 100%;
    height: 40px;
    margin-bottom: 16px;
    padding: 8px 24px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    font-size: 1.6rem;
  }
  input[name=free]::placeholder {
    color: #828282;
  }
  .button {
    position: relative;
    width: 240px;
    height: 48px;
    margin: 24px auto 0;
    padding: 0;
  }
  .button svg {
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    z-index: 1;
  }
  .d-flex_col {
    width: calc(100% / 2 - 12px);
  }
  .d-flex_col dd label {
    width: 100%;
  }
  select {
    width: 184px;
    height: 40px;
    appearance: none;
    background-color: #ffffff;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    margin: 0 16px 16px 0;
    padding: 0 24px 0 10px;
    font-size: 1.6rem;
    color: #828282;
    box-shadow: 0 0 6px rgba(54, 59, 64, .4);
  }
  .d-flex_col select {
    width: 140px;
    &:last-of-type {
      margin-right: 4px;
    }
  }
  .japan_select {
    position: relative;
  }
  .money_select {
    position: relative;
  }
  .d-flex_col .sub-txt {
    font-size: 1.6rem;
  }
  .salary_select {
    position: relative;
  }
}

input[name=hope_salary] {
  margin: 0 16px;
}

input[name=salary] {
  margin: 0 16px;
}

input[name=gift_amount] {
  margin-right: 16px;
}

/* カスタム検索一覧
----------------------------------------------- */

.search-sort {
  margin: 48px 0 16px;
  .search_page p {
    line-height: 1;
  }
  dl {
    position: relative;
    display: flex;
    margin-top: 24px;
  }
  dt {
    position: absolute;
    bottom: 100%;
    color: #3D270F;
    font-size: 1.2rem;
  }
  &_num {
    width: 120px;
    margin-right: 24px;
    padding-top: 4px;
  }
  &_line {
    width: 204px;
    padding-top: 4px;
  }
  a {
    display: inline-block;
    padding: 4px 6px;
    background-color: #F2F2F2;
    border-radius: 4px;
    color: #828282;
    font-size: 1.2rem;
    text-decoration: none;

    &:hover {
      background-color: #FF7E41;
      color: #FFFFFF;
    }
  }
  a.active {
    background-color: #FF7E41;
    color: #FFFFFF;
    pointer-events: none;
    cursor: initial;
  }
  dd + dd {
    margin-left: 4px;
  }
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .search-sort dl dd a  {
    min-width: 40px;
  } /* IE11 */
}

/* 固定ページカスタム
----------------------------------------------- */
.page-contents {
  margin-top: 16px;
  padding-bottom: 30px;
  &_heading {
  position: relative;
  display: flex;
  width: 400px;
  margin: auto;
  }
  &_heading img {
    position: absolute;
    bottom: 0;
  }
  &_heading h4 {
    position: relative;
    width: 100%;
    margin-left: 90px;
    padding: 10px 22px;
    background-color: #FFD0B9;
    border-radius: 6px;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  &_heading h4::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14.5px 15px 14.5px 0;
    border-color: transparent #FFD0B9 transparent transparent;
    transform: translateY(-50%);
  }
  &_head h3 {
    margin-bottom: 0;
    padding: 0;
    border-left: none;
    text-align: center;
  }
  &_head p {
    margin-top: 32px;
    padding: 0;
    text-align: center;
  }
  &_point,
  &_step,
  &_qa {
    margin-top: 48px;
  }
  &_step h4 {
    position: relative;
    background-color: #FFD0B9;
    border-radius: 6px;
  }
  &_qa h4 {
    position: relative;
    background-color: #FFD0B9;
    border-radius: 6px;
    color: #3D270F;
    font-size: 2.2rem;
    font-weight: 700;
  }
  &_foot {
    margin-top: 48px;
  }
  &_foot .page-entryBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 640px;
    height: 64px;
    margin: auto;
    background-color: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    color: #FFFFFF;
    font-size: 2.2rem;
    font-weight: 700;
    text-decoration: none;
  }
  &_foot .page-entryBtn:hover {
    opacity: .8;
  }
  &_foot .page-entryBtn svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  &_foot .page-registBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 470px;
    height: 64px;
    margin: auto;
    padding-left: 24px;
    background-color: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    color: #FFFFFF;
    font-size: 2.2rem;
    font-weight: 700;
    text-decoration: none;
  }
  &_foot .page-registBtn:hover {
    opacity: .8;
  }
  &_foot .page-registBtn svg {
    position: absolute;
    top: 50%;
    left: 108px;
    transform: translateY(-50%);
  }
}

.page-pointItems,
.page-stepItems,
.page-qaItems {
  margin-top: 32px;
}

.page-stepItem {
  & + & {
    margin-top: 40px;
  }
  &_head {
    position: relative;
    display: flex;
    height: 80px;
    align-items: center;
    background-color: #FFF0DB;
    font-size: 2.2rem;
    font-weight: 700;
  }
  &:not(:first-child) &_head::before {
    content: " ";
    position: absolute;
    top: -43%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #FFD0B9 transparent transparent transparent;
    transform: translate(-50%, 43%);
  }
  &_head span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 80px;
    margin-right: 40px;
    background-color: #FFD0B9;
  }
  &_head .page-registBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 244px;
    height: 40px;
    margin: auto auto auto 40px;
    padding: 0 16px 0 24px;
    background-color: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    color: #FFFFFF;
    font-size: 1.6rem;
    font-weight: 700;
    text-decoration: none;
  }
  &_head .page-registBtn:hover {
    opacity: .8;
  }
  &_head .page-registBtn svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
  &_txt {
    margin-top: 16px;
  }
  &_txt strong {
    color: #FF7E41;
  }
  &_txt span {
    display: block;
    margin-top: 24px;
    font-size: 1.2rem;
  }
}

.page-qaItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &_row {
    display: flex;
    justify-content: space-between;
  }
  &_row + &_row {
    margin-top: 40px;
  }
  dl {
    width: calc(100% / 2 - 25px);
    background-color: #ffffff;
    border-radius: 0 0 4px 4px ;
  }
  dt {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 106px;
    padding: 0 24px 0 46px;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 4px 4px 0 0;
    font-size: 22px;
    font-weight: 700;
  }
  dt::before {
    position: absolute;
    top: 42%;
    left: 16px;
    content: "Q";
    color: #2382C7;
    font-family: Roboto;
    font-size: 3.2rem;
    font-weight: 700;
    transform: translateY(-42%);
  }
  dd {
    position: relative;
    width: 100%;
    padding: 24px 24px 24px 46px;
    font-size: 1.8rem;
  }
  dd::before {
    position: absolute;
    top: 14px;
    left: 16px;
    content: "A";
    color: #FF7E41;
    font-family: Roboto;
    font-size: 3.2rem;
    font-weight: 700;
  }
  dd span {
    display: block;
    margin-top: 24px;
    font-size: 1.2rem;
    line-height: 1.6;
  }
}

.page-pointItems {
  display: flex;
  justify-content: space-between;
}

.page-pointItem {
  width: calc(100% / 3 - 12px);
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 4px;
  &_capHead {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    color: #2382C7;
    font-family: Roboto;
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
  }
  &_capHead span {
    margin-top: -15px;
    font-size: 5.5rem;
    line-height: 1;
  }
  figure {
    margin: 0;
  }
  figure img {
    width: 100%;
    margin-top: 20px;
    vertical-align: bottom;
  }
  figcaption {
    padding: 0 24px;
    font-size: 1.8rem;
  }
  figcaption strong {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 62px;
    margin-bottom: 16px;
    font-size: 2.6rem;
    line-height: 1.2;
  }
}
.saiyo-pointItems {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.saiyo-pointItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% / 2 - 16px);
  height: 260px;
  background-color: #FFF0DB;
  border-radius: 4px;
  line-height: 1.48;
  strong {
    font-size: 5.6rem;
    text-align: center;
  }
  &_txtLg {
    color: #FB5555;
    font-family: Roboto;
    font-size: 13.6rem;
    line-height: 1;
  }
  &_txtSm {
    font-size: 4.6rem;
  }
  &_crMain {
    color: #FF7E41;
  }
}

.saiyo-point_txt {
  p {
    margin-top: 32px;
  }
  p span {
    color: #FF7E41;
  }
  &_large {
    font-size: 3.9rem;
    text-align: center;
  }
}

/* 固定ページ凡用
----------------------------------------------- */
.contents_l {
  width: 1032px;
  min-height: calc(100vh - 124px - 92px);
  margin: 0 auto;
  padding: 40px 0 84px;
  h2 {
    margin-top: 24px;
  }
  h3 {
    margin-top: 16px;
  }
}

.page_box {
  background: #fff;
  border: 1px solid rgba(54, 59, 64, .4);
  border-radius: 4px;
  padding: 24px;
  margin: 24px 0 0;
  dl {
    margin-bottom: 30px;
  }
  dt {
    margin: 24px 0 10px;
  }
  p {
    margin: 0 0 16px;
  }
  ul {
    padding-left: 1em;
  }
  ul li {
    margin-bottom: 4px;
    list-style: disc;
  }
}

// メンテナンス画面(PC/SP)
.maintenance-body {
  background-color: #fffdf4;
}

.maintenance-wrap {
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;
  padding-top: 152px;
  @media screen and (max-width: 768px) {
    padding: 76px 16px 0;
  }
  h1 {
    text-align: center;
  }
  .maintenance-box {
    margin-top: 28px;
    padding: 24px;
    background-color: #FFFFFF;
    border: 1px solid rgba(54, 59, 64, .4);
    border-radius: 4px;
  }
  .maintenance-box p + p {
    margin-top: 24px;
  }
}

/* add 企業
----------------------------------------------- */
.p-16 {
  padding: 16px;
}

.mt-0 {
  margin-top: 0;
}

.contents_m .form_table .mt-0wrap select {
  margin-top: 0;
}

.mt-16 {
  margin-top: 16px;
}

.label-fix {
  top: -18px;
}

.cuser .contents_m .list .title h3 .pickup {
  left: 0;
}

table#business_hours {
  margin-top: 0;
}

.contents_m .button a.button {
  padding: 12px 0;
}

.contents_m .cuser_container .sch-detail .info_table th,
.contents_m .cuser_container .sch-detail .info_table td {
  padding: 16px;
}

.sch-from_contents .d-flex_col select[name=hope_job_category] {
  width: 184px;
}

select[name=resume_id] + a.btn-sub {
  position: relative;
  display: inline;
  width: fit-content;
  margin: 0 0 0 16px;
  padding: 12px 16px 12px;
  background-color: #FFFFFF;
  background-image: none;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.6rem;
  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: #FFF0DB;
  }
}

.cuser .contents_box.item_info,
.cuser .contents_box.user_info,
.cuser .contents_box.pay_job {
  background: none;
  border: none;
  h2 {
    padding: 0;
    border-bottom: none;
    font-size: 2.2rem;
  }
  h2::after {
    content: none;
  }
}

.cuser .contents_box.new_nuser {
  margin-top: 40px;
  padding: 24px 24px 27px;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border: none;
  border-radius: 4px;
  h2 {
    position: relative;
    padding: 0;
    border-bottom: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h2::after {
    content: none;
  }
  .body {
    padding: 0;
  }
  ul {
    margin-top: 16px;
  }
  ul li a {
    position: relative;
    display: block;
    margin-bottom: 4px;
    padding: 12px 48px 12px 16px;
    color: #2382C7;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(54, 59, 64, .1);
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      background: linear-gradient(0deg, rgba(150, 198, 232, .2), rgba(150, 198, 232, .2)), #FFFFFF;
    }
  }
  ul li a::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../img/icon/arrow/right-circle.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  ul li a svg {
    margin-right: 8px;
  }
  ul li a > span {
    float: none;
  }
  ul li p {
    color: #2382C7;
    font-weight: 700;
    text-decoration: none;
  }
  .more {
    margin-top: 24px;
  }
  .more a {
    position: relative;
    display: block;
    width: 320px;
    margin: 0 auto;
    padding: 14px 10px 14px 0;
    background-color: #ff7e41;
    color: #fff;
    border-radius: 6px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 0 6px rgba(54, 59, 64, .4);
    &:hover {
      opacity: .8;
    }
  }
  .more a svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

.contents_m .contents_box.top_search.sch-from._cuser {
  .sch-from_contents .d-flex_col select {
    width: fit-content;
    min-width: 184px;
    margin: 0 16px 0 0;
  }
  .sch-from_contents .d-flex_col select[name=_ageB] {
    margin-left: 16px;
  }
  .sch-from_contents dd {
    align-items: center;
  }
  input[name=_hope_salary] {
    margin: 0 16px;
  }
  input[name=id],
  input[name=free],
  input[name=entry_user] {
    width: 100%;
    max-width: 100%;
  }
}

.admin_info {
  margin-top: 24px;
  padding: 0 24px 0;
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .cuser .admin_info th {
    min-width: auto;
    width: auto;
  }
  *::-ms-backdrop, .cuser .info_table th {
    min-width: auto;
    width: auto;
  }
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .cuser .admin_info td {
    min-width: auto;
    width: auto;
  }
  *::-ms-backdrop, .cuser .info_table td {
    min-width: auto;
    width: auto;
  }
}

.cuser .sch-resList {
  .operation {
    width: 570px;
  }
  .operation .copy a,
  .operation .edit a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    padding: 6px 12px;
    background-color: #fff;
    border-radius: 6px;
    border: 2px solid #ff7e41;
    color: #ff7e41;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    &:hover {
      background-color: #fff0db;
    }
  }
  .operation .more a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    padding: 6px 12px;
    background-color: #ff7e41;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    &:hover {
      opacity: .8;
    }
  }
  .operation .delete a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    padding: 6px 12px;
    background-color: #fff;
    border-radius: 6px;
    border: 2px solid rgba(54, 59, 64, .4);
    color: rgba(54, 59, 64, .4);
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.contents_m table.interview-table {
  table-layout: fixed;
  th {
    width: 250px;
    padding-right: 54px;
  }
}

table.interview-table textarea{
  width: 100%;
}

.bnr-box {
  margin-bottom: 24px;
  a:hover {
    opacity: .8;
  }
}

.cuser .contents_m .sch-resList .title {
  padding: 32px 24px 17px 24px;
}

.tp-wrap .contents_m ul.sub_menu {
  margin-top: 0;
}

.message.att {
  margin: 0 24px;
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .cuser table.list_table {
    width: 100%;
    table-layout: fixed;
  }
}

.cuser .contents_m .sch-resList .info_table table td {
  width: 28%;
}

@media all and (-ms-high-contrast: none){
  *::-ms-backdrop, .cuser table.list_table th {
    min-width: auto;
    width: auto;
  }
}

.cuser .contents_s .embed_job.rank .body li {
  line-height: initial;
  padding: 0;
  background: none;
  border-radius: 0;
  box-shadow: none;
}

.cuser .info_table table th {
  padding: 16px;
}

.cuser #business_hours select {
  min-width: initial;
  margin-right: 16px;
}

.label-center label {
  top: -14px;
}
