/** フォント関連 **/
$fontFamily: "Noto Sans JP", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "Roboto", sans-serif;
/* 日本語のみ */
$fontFamilyJA: "Noto Sans JP", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ";
/* 英語のみ */
$fontFamilyEN: "Roboto", sans-serif;
$fontSize: 1.6rem;
$lineHeight: 1.48;
/* 使用ウェイト
Roboto-Bold,
Roboto Condensed,
Noto -regular,bold,
*/

/** 色設定 **/
$mainColor: #FF7E41;
$mainColor_pale: #FFD0B9;
$AccentColor: #2382C7;
$AccentColor_pale: #96C6E8;
$textColor: #3D270F;
$linkColor: $AccentColor;
$backgroundColor: #FFFDF4;

$gray_deep: #828282;
$gray_middle: #E0E0E0;
$gray_pale: #F2F2F2;

$white: #fff;
$black: #000;

/** 透明度の設定 **/
$opacity: .8;
/** トランジションに関しての設定 **/
/* 秒、イージングの設定 */
/* ※ イージング早見表 https://easings.net/ja */
$transition: .3s;
